// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { IOrderItem, IProduct } from "interfaces";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

type ProductCounterBarProps = {
  items?: IOrderItem[];
  products?: IProduct[];
  fixed?: boolean;
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const ProductCounterBar = ({
  items = [],
  products = [],
  fixed = false,
  ...props
}: ProductCounterBarProps) => {
  const theme: Theme = useTheme();
  const ref = useRef(null);
  const [containerStyle, setContainerStyle] = useState<React.CSSProperties>(
    fixed ? { position: "fixed", left: 0, bottom: 0, width: "100%" } : {}
  );

  const hidden = !products?.length || !items?.length;
  const totals: { [p: string]: number } = Object.fromEntries(
    products.map((p) => [p.id, 0])
  );
  // const containerStyle: React.CSSProperties = fixed ? {position: 'fixed', left:0, bottom:0, width:'100%'} : {}

  items.forEach((item) => {
    totals[item.product_id] = totals[item.product_id] + item.quantity;
  });

  const columns = products
    .filter((p) => totals[p.id] > 0)
    .map((p) => {
      return {
        field: p.id,
        headerName: p.abbreviation || p.code,
        longName: p.name,
        width: 150,
      };
    });

  const resizeContainer = () => {
    if (ref.current) {
      const containerNode = ref.current.parentNode?.parentNode as HTMLElement;
      const boundingRect = containerNode.getBoundingClientRect();
      const newStyle = { ...containerStyle, left: boundingRect.left };
      setContainerStyle(newStyle);

      const resizeObserver = new ResizeObserver(() => {
        const boundingRect = containerNode.getBoundingClientRect();
        const newStyle = {
          ...containerStyle,
          left: boundingRect.left,
          width: `calc(100% - ${
            boundingRect.left + (window.innerWidth - boundingRect.right)
          }px)`,
        };
        setContainerStyle(newStyle);
      });
      resizeObserver.observe(containerNode);
      return () => resizeObserver.disconnect();
    }
  };

  useEffect(() => {
    resizeContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current, fixed]);

  return (
    <Card
      elevation={0}
      sx={{ borderRadius: 0 }}
      className={hidden ? "hidden" : ""}
      style={containerStyle}
      ref={ref}
    >
      <CardHeader
        title="Totale prodotti negli ordini selezionati"
        titleTypographyProps={{
          variant: "h6",
          color: theme.palette.primary.contrastText,
        }}
        sx={{
          padding: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
        }}
      />
      <CardContent sx={{ padding: 0 }}>
        <Box>
          <Table size="small">
            <StyledTableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    key={`products-total-header-${col.field}`}
                    component="th"
                  >
                    <Typography
                      color={theme.palette.primary.contrastText}
                      variant="body1"
                    >
                      <strong>{col.headerName}</strong>
                      <br />
                      <small>{col.longName}</small>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={`products-total-value-${col.field}`}>
                    <Typography
                      color={theme.palette.primary.dark}
                      variant="body1"
                    >
                      <strong>{totals[col.field]}</strong>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCounterBar;
