import {SyntheticEvent, useEffect, useMemo, useRef, useState} from "react";
import {
    Autocomplete, AutocompleteInputChangeReason, Grid,
    TextField,
    Typography
} from "@mui/material";
import {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";

import _ from "lodash-es";
import {IAddress} from "../../interfaces";
import {LatLng} from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import {ContactMail, LocationOn} from "@mui/icons-material";
import {useAutocomplete} from "@refinedev/mui";

interface CustomerAddressPickerProps {
    label: string;
    value?: IAddress;
    isLoading?: boolean
    customerId?: string;
    preloadedValues?: IAddress[]
    onChange: (contact?: IAddress | null, isNew?: boolean) => void;
}

interface AddressModalProps {
    open: boolean
    defaultValue: IAddress
    handleDialogClose: () => void
    onSubmit: (data: IAddress) => void
}

const formatAddress = (addr: IAddress) => `${addr.street}, ${addr.province}, ${addr.city}`

function convertToIAddress(googleMapsResult: google.maps.GeocoderResult, latLng?: LatLng): IAddress {
    const address: IAddress = {
        id: '', // You need to set the appropriate value for id
        street: '',
        zipcode: '',
        city: '',
        province: '',
        country: '',
        customer_id: '',
        lat: latLng?.lat.toString() || '',
        lng: latLng?.lng.toString() || '',
        place_id: googleMapsResult.place_id,
    };
    let streetNumber = ''
    let streetName = ''

    googleMapsResult.address_components.forEach(component => {
        if (component.types.includes('street_number')) {
            streetNumber = component.short_name
        } else if (component.types.includes('route')) {
            streetName = component.short_name;
        } else if (component.types.includes('locality')) {
            address.city = component.long_name;
        } /*else if (component.types.includes('administrative_area_level_3')) {
            address.province = component.long_name;
        } */ else if (component.types.includes('administrative_area_level_1')) {
            address.extra = component.short_name;
        } else if (component.types.includes('administrative_area_level_2')) {
            address.province = component.short_name;
        } else if (component.types.includes('country')) {
            address.country = component.long_name;
        } else if (component.types.includes('postal_code')) {
            address.zipcode = component.short_name;
        }
    });

    address.street = `${streetName}, ${streetNumber}`
    // You need to set appropriate values for the remaining fields (id, customer_id, lat, lng)

    return address;
}

export const CustomerAddressPicker: React.FC<CustomerAddressPickerProps> = ({
                                                                                label,
                                                                                value,
                                                                                isLoading = false,
                                                                                onChange,
                                                                                customerId,
                                                                                preloadedValues = []
                                                                            }) => {
    const [geocoderOptions, setGeocoderOptions] = useState<IAddress[]>([])
    const [geocoderLoading, setGeocoderLoading] = useState(false)
    const autocompleteService = useRef<any>();

    const {autocompleteProps: {options, ...autocompleteProps}, onSearch} = useAutocomplete<IAddress>({
        resource: 'addresses',
        defaultValue: value?.id || value?.place_id,
        sorters: [
            {
                field: "city",
                order: "asc",
            },
            {
                field: "street",
                order: "asc",
            },
        ],
        queryOptions: {
            enabled: !isLoading && !geocoderLoading,
        },
        filters: [{
            field: "customer_id",
            operator: "eq",
            value: customerId
        }],
        pagination: {
            pageSize: 30,
            mode: "server",
        },
        onSearch: (searchText) => {
            return [{
                field: "search",
                operator: "contains",
                value: searchText,
            }];
        }
    });

    const fetch = useMemo(
        () =>
            _.throttle((request, callback) => {
                autocompleteService?.current?.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    const emptyAddress: IAddress = {
        city: "",
        country: "",
        customer_id: "",
        extra: "",
        id: "",
        lat: "",
        lng: "",
        place_id: "",
        province: "",
        street: "",
        zipcode: ""
    }

    useEffect(() => {
        // onSearch(value?.name || '')
    }, [value]);
    useEffect(() => {
        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
    }, []);
    const inputChangeHandler = (e: SyntheticEvent<Element, Event>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
        if (options.length <= 1) {
            fetch({input: newInputValue}, (results: google.maps.places.AutocompletePrediction[]) => {
                setGeocoderOptions(results.map((r): IAddress => ({
                    city: "",
                    country: "",
                    customer_id: "",
                    extra: "",
                    id: "",
                    lat: "",
                    lng: "",
                    place_id: r.place_id,
                    province: "",
                    street: r.description,
                    zipcode: ""
                })))
                autocompleteProps.onInputChange(e, newInputValue, reason)
                // setGeocoderOptions(results.map(r => ({address: r.description})) || []);
            });

        } else {
            autocompleteProps.onInputChange(e, newInputValue, reason)
        }
    }


    return (
        <>
            <Autocomplete {...autocompleteProps}
                          options={options.length <= 1 ? [...options, ...geocoderOptions] : options}
                          style={{marginTop: 16, marginBottom: 8}}
                          renderInput={(params) => {
                              return <TextField {...params} label={label} variant="outlined" fullWidth/>
                          }}
                          getOptionLabel={(option: any) => {
                              if (!option) return "";
                              return typeof option === "string" ? option : (option.city ? `${option.street}, ${option.city}` : '')
                          }}
                          disabled={geocoderLoading}
                          isOptionEqualToValue={(option, value) => {
                              if (!value?.id) {
                                  return option.city?.toLowerCase() === value.city?.toLowerCase() && option.street?.toLowerCase() === value.street?.toLowerCase()
                              }
                              return option.id === value.id
                          }}
                          onChange={(_, newValue) => {
                              if (typeof newValue === 'string') {
                                  //TODO: notify error
                              } else if (newValue && (!newValue.id || !newValue.city)) {
                                  setGeocoderLoading(true)
                                  geocodeByAddress(newValue.street).then(async (results) => {
                                      if (results.length) {
                                          const latLng = await getLatLng(results[0]);
                                          const newAddress = convertToIAddress(results[0], latLng);
                                          onChange(newAddress)
                                          setGeocoderLoading(false)
                                      }
                                  });

                              } else {
                                  onChange(newValue, false)
                              }
                              //handleSelected(newValue);
                          }}
                          value={value || null}
                          renderOption={(props, option) => {
                              const key = `${option.id || props.id}-${option.place_id}`
                              return (
                                  <li {...props} key={key}>
                                      <Grid container display="flex" alignItems="center">
                                          <Grid mr={1} item>
                                              {option.city ? <ContactMail/> : <LocationOn/>}
                                          </Grid>
                                          <Grid item xs>
                                              <Typography variant="body2" color="textPrimary">
                                                  {option.street}
                                              </Typography>
                                              <Typography variant="body2" color="textSecondary">
                                                  {option.city ? `${option.city} (${option.province})` : 'Aggiungi indirizzo'}
                                              </Typography>
                                          </Grid>
                                      </Grid>
                                  </li>
                              );
                          }}
                          onInputChange={(e, newInputValue, reason) => {
                              inputChangeHandler(e, newInputValue, reason)
                          }}
            />
        </>
    );
};
