import { CrudFilters, CrudFilter } from "@refinedev/core";
import { CrudOperators } from "@refinedev/core/CrudOperators";
// import { mapOperator } from "./mapOperator";

export const generateFilter = (filters?: CrudFilters) => {
  let queryFilters: any = {};

  if (filters) {
    filters.forEach((filter: CrudFilter) => {
      if (filter.operator === "or" || filter.operator === "and") {
        throw new Error(
          `[@refinedev/simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`
        );
      }
      // console.log('queryFilters', filter)

      if ("field" in filter) {
        const { field, value } = filter;
        //TODO: filter operators not working on backend
        //const fieldKey = operator === 'eq' ? field : `${field}_${operator}`
        switch (filter.operator as CrudOperators) {
          case 'isNullOrNot':
            if (value === 'null') {
              queryFilters = Object.assign({ ...queryFilters, [`${field}_nil`]: true });
            } else if (value === 'notNull') {
              queryFilters = Object.assign({ ...queryFilters, [`${field}_nnil`]: true });
            }
            break
          case 'gt':
          case 'gte':
          case 'lte':
          case 'lt':
          case 'ne':
            queryFilters = Object.assign({ ...queryFilters, [`${field}_${filter.operator}`]: value });
            break
          default:
            queryFilters = Object.assign({ ...queryFilters, [field]: value });
        }


        // if (field === "q") {
        //   queryFilters[field] = value;
        //   return;
        // }

        // const mappedOperator = mapOperator(operator);
        // queryFilters[`${field}${mappedOperator}`] = value;

      }

    });
  }

  return {
    filter: JSON.stringify(queryFilters),
  };
};
