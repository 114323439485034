import React from "react";
import { Tabs, useMediaQuery, useTheme } from "@mui/material";
import { TabsProps } from "@mui/material/Tabs/Tabs";

export interface ResponsiveTabsProps {}

const ResponsiveTabs: React.FC<Pick<TabsProps, "value" | "onChange" | "children"|"indicatorColor">> = ({
  value,
  onChange,
  children,
                                                                                        indicatorColor="primary"
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Tabs
      scrollButtons={isMobile ? "auto" : undefined}
      variant={isMobile ? "scrollable" : "fullWidth"}
      allowScrollButtonsMobile={isMobile}
      centered={!isMobile}
      value={value}
      onChange={onChange}
      indicatorColor={indicatorColor}
      color="secondary">
      {children}
    </Tabs>
  );
};

export default ResponsiveTabs;
