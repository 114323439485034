import { HttpError, useMany } from "@refinedev/core";
import { SaveButton, useAutocomplete } from "@refinedev/mui";

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IDiscountRule, IProduct } from "interfaces";
import { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridApi,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid";
import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { checkIfValidUUID } from "helpers/utils";

interface SelectProductComponentProps {
  params: GridRenderCellParams;
  autocompleteProps: any;
}

const SelectProductComponent = ({
  params,
  autocompleteProps,
}: SelectProductComponentProps) => {
  const { id, value, api, field } = params;

  const handleChange = (event: any, newValue: IProduct) => {
    api.setEditCellValue({ id, field, value: newValue?.id }, event);
  };

  return (
    <Autocomplete
      {...autocompleteProps}
      value={
        autocompleteProps?.options?.find((item: any) => item.id === value) ??
        null
      }
      onChange={handleChange}
      getOptionLabel={(item: any) => item.name}
      isOptionEqualToValue={(option: any, value: any) =>
        value === undefined ||
        option?.id?.toString() === (value?.id ?? value)?.toString()
      }
      fullWidth
      placeholder="Select Product"
      renderInput={(params) => (
        <TextField {...params} margin="normal" variant="outlined" required />
      )}
    />
  );
};

export const EditDiscountRuleModal: React.FC<
  UseModalFormReturnType<IDiscountRule, HttpError, IDiscountRule>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish, id },
  modal: { visible, close, title },
  register,
  handleSubmit,
  formState: { errors },
}) => {
  const currentData = queryResult?.data?.data;
  const [targetCustomers, setTargetCustomers] = useState<any>([]);
  const [discounts, setDiscounts] = useState<any>([]);
  const [deletedDiscounts, setDeletedDiscounts] = useState<any>([]);

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [newProduct, setNewProduct] = useState<any>(null);
  const [newPercentage, setNewPercentage] = useState<any>(null);

  const onSubmit = async (data: any) => {
    onFinish({
      ...currentData,
      ...data,
      current_customer: customersData?.data,
      new_customer: targetCustomers,
      discounts: discounts,
      deleted_discounts: deletedDiscounts,
    });
  };

  const { data: discountItemsData, isLoading: discountItemsIsLoading } =
    useMany({
      resource: "discount_items",
      ids: currentData?.id ? [currentData?.id] : [],
      metaData: {
        filterField: "discount_rule_id",
      },
      queryOptions: {
        enabled: !!currentData?.id,
      },
    });

  useEffect(() => {
    if (!discountItemsIsLoading && discountItemsData?.data) {
      setDiscounts(discountItemsData?.data);
    }
  }, [discountItemsIsLoading, discountItemsData?.data]);

  const cleanInsert = () => {
    setIsCreating(false);
    setNewProduct(null);
    setNewPercentage(null);
  };

  useEffect(() => {
    cleanInsert();
  }, [discounts]);

  const { autocompleteProps: productsAutocompleProps } =
    useAutocomplete<IProduct>({
      resource: "products",
    });

  const { data: discountsData, isLoading: discountsIsLoading } = useMany({
    resource: "discounts",
    ids: currentData?.id ? [currentData?.id] : [],
    metaData: {
      filterField: "discount_rule_id",
    },
    queryOptions: {
      enabled: !!currentData?.id,
    },
  });

  const { data: customersData, isLoading: customersIsLoading } = useMany({
    resource: "customers",
    ids: discountsData?.data
      ? discountsData?.data.map((item: any) => item?.customer_id)
      : [],
    queryOptions: {
      enabled: !discountsIsLoading && !!discountsData,
    },
  });

  const { autocompleteProps: autocompletePropsCustomers } =
    useAutocomplete<any>({
      resource: "customers",
      sorters: [
        {
          field: "company_name",
          order: "asc",
        },
      ],
      queryOptions: {
        enabled: !!currentData?.id,
      },
      pagination: {
        pageSize: 30,
        mode: "server",
      },
      onSearch: (searchText) => {
        return [
          {
            field: "company_name",
            operator: "contains",
            value: searchText,
          },
        ];
      },
    });

  useEffect(() => {
    if (!customersIsLoading && !!customersData?.data && visible) {
      setTargetCustomers(customersData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersIsLoading, customersData, visible]);

  interface RowMenuProps {
    api: GridApi;
    id: GridRowId;
  }

  function RowMenuCell(props: RowMenuProps) {
    const { api, id } = props;
    const isInEditMode = api.getRowMode(id) === "edit";

    const handleEditClick = (event: any) => {
      event.stopPropagation();
      api.setRowMode(id, "edit");
    };

    const handleSaveClick = (event: any) => {
      event.stopPropagation();
      api.commitRowChange(id);
      api.setRowMode(id, "view");

      const row = api.getRow(id);
      const index = discounts.findIndex((item: any) => item.id === id);
      const newDiscounts = [...discounts];
      if (!checkIfValidUUID(row?.id)) {
        row.action = "update";
      }
      newDiscounts[index] = row;
      setDiscounts(newDiscounts);
    };

    const handleDeleteClick = (event: any) => {
      event.stopPropagation();
      let newDeletedDiscounts = [...deletedDiscounts];
      if (!checkIfValidUUID(id)) {
        newDeletedDiscounts.push(id);
        setDeletedDiscounts(newDeletedDiscounts);
      }
      const newDiscounts = discounts.filter((item: any) => item.id !== id);
      setDiscounts(newDiscounts);
    };

    const handleCancelClick = (event: any) => {
      event.stopPropagation();
      api.setRowMode(id, "view");

      const row = api.getRow(id);
      if (row!.isNew) {
        api.updateRows([{ id, _action: "delete" }]);
      }
    };

    if (isInEditMode) {
      return (
        <div>
          <IconButton
            color="primary"
            size="small"
            aria-label="save"
            onClick={handleSaveClick}
          >
            <Save fontSize="small" />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            aria-label="cancel"
            onClick={handleCancelClick}
          >
            <Cancel fontSize="small" />
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={handleEditClick}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={handleDeleteClick}
        >
          <Delete fontSize="small" />
        </IconButton>
      </div>
    );
  }

  const columns = useMemo<GridColumns<any>>(
    () => [
      {
        field: "product_id",
        headerName: "Target Products",
        flex: 2,
        editable: true,
        valueGetter(row) {
          return row.row.product_id;
        },
        renderCell: function render({ value }) {
          if (productsAutocompleProps?.loading) return <>Loading...</>;
          return productsAutocompleProps?.options?.find(
            (item) => item.id === value
          )?.name;
        },
        renderEditCell: (params) => {
          return (
            <SelectProductComponent
              params={params}
              autocompleteProps={productsAutocompleProps}
            />
          );
        },
      },
      {
        field: "discount_percentage",
        headerName: "Discount Percentage",
        type: "number",
        flex: 1,
        editable: true,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: RowMenuCell,
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
        filterable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsAutocompleProps?.loading, discounts]
  );

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            margin="normal"
            fullWidth
            label="Name"
            autoFocus
            defaultValue={" "}
          />
          <div
            style={{
              display: "flex",
              margin: "10px 0",
            }}
          >
            {!isCreating ? (
              <Grid container>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsCreating(true);
                    }}
                  >
                    Add Discounts
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item md={5}>
                  <Autocomplete
                    {...productsAutocompleProps}
                    value={newProduct ?? null}
                    onChange={(_: any, newValue: any) => {
                      setNewProduct(newValue);
                    }}
                    getOptionLabel={(item: any) => item.name}
                    isOptionEqualToValue={(option: any, value: any) =>
                      value === undefined ||
                      option?.id?.toString() ===
                        (value?.id ?? value)?.toString()
                    }
                    fullWidth
                    placeholder="Select Product"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label="Select Product"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item md={5}>
                  <TextField
                    value={newPercentage ?? 0}
                    onChange={(e) => {
                      setNewPercentage(e.target.value);
                    }}
                    margin="normal"
                    fullWidth
                    label="Discount Percentage"
                    required
                    type="number"
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    color="primary"
                    size="small"
                    aria-label="save"
                    onClick={() => {
                      const currentDiscounts = [...discounts];
                      currentDiscounts.push({
                        id: uuidv4(),
                        product_id: newProduct.id,
                        discount_percentage: +newPercentage,
                        discount_rule_id: id,
                        action: "create",
                      });
                      setDiscounts(currentDiscounts);
                    }}
                  >
                    <Save fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  md={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    color="inherit"
                    size="small"
                    aria-label="cancel"
                    onClick={cleanInsert}
                  >
                    <Cancel fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </div>
          <DataGrid
            columns={columns}
            rows={discounts}
            autoHeight
            hideFooter
            editMode="row"
            getRowId={(row) => row.id}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeader": {
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                maxHeight: "168px !important",
              },
            }}
          />
          <Autocomplete
            {...autocompletePropsCustomers}
            value={targetCustomers}
            onChange={(_, newValue) => {
              setTargetCustomers(newValue);
            }}
            getOptionLabel={(item) => item.company_name}
            isOptionEqualToValue={(option, value) =>
              value === undefined ||
              option?.id?.toString() === (value?.id ?? value)?.toString()
            }
            placeholder="Select Target Customer"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Target Customers"
                margin="normal"
                variant="outlined"
                required
              />
            )}
            multiple
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  label={option.company_name}
                  {...getTagProps({ index })}
                  sx={{ bgcolor: "primary.main", color: "white" }}
                />
              ));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
