export const paymentStautsOptions = [
  {
    "value": "Payed in cash",
    "default": true,
    "description": "Payed in cash",
    "translationKey": "payments.status.cash"
  },
  {
    "value": "Contabile",
    "description": "Contabile",
    "translationKey": "payments.status.contabile"
  },
  {
    "value": "Pos",
    "description": "Payed by card",
    "translationKey": "payments.status.pos"
  },
  {
    "value": "Link",
    "description": "Already paid online",
    "translationKey": "payments.status.link"
  },
  {
    "value": "Parziale",
    "description": "Partial payment (Specify amount)",
    "translationKey": "payments.status.parziale",
    "hidden": true
  },
  {
    "value": "Insoluto",
    "description": "Unpaid (Add to future orders)",
    "alert": true,
    "translationKey": "payments.status.insoluto"
  },
  {
    "value": "assegno",
    "description": "Cheque",
    "translationKey": "payments.status.assegno"
  },
  {
    "value": "Conto 1",
    "description": "Account 1",
    "translationKey": "payments.status.conto1"
  },
  {
    "value": "Conto 2",
    "description": "Account 2",
    "translationKey": "payments.status.conto2"
  }
]

export const statusOptions = [
    {value: "confirmed", label: "Confermato"},
    {value: "draft", label: "Bozza"},
    {value: "pending", label: "In attesa"},
    {value: "cancelled", label: "Annullato"},
    {value: "delivered", label: "Consegnato"},
];