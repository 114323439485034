import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { LicenseInfo } from '@mui/x-license-pro';

let muiLicenseSet = false
if (process.env.REACT_APP_MUI_X_LICENSE_KEY && !muiLicenseSet) {
  muiLicenseSet = true
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY.trim());
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <React.Suspense fallback="loading">
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
