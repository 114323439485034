import React, {useState} from "react";
import {useDataGrid, List} from "@refinedev/mui";
import {
    DataGrid,
    GridColumns,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {CrudFilter, useMany} from "@refinedev/core";
import CustomToolbar from "../../components/list/CustomToolbar";
import {SearchField} from "../../components/SearchField";

export const AddressList = () => {

    const [searchQuery, setSearchQuery] = useState("");
    const filters: CrudFilter[] = []
    if (searchQuery) {
        filters.push({
            field: "q",
            operator: "eq",
            value: searchQuery,
        });
    }

    const {dataGridProps} = useDataGrid({
        sorters: {
            initial: [
                {
                    field: "id",
                    order: "asc",
                },
            ],
        },
        filters: {
            permanent: filters,
        },
    });

    const {data: customerData, isLoading: customerIsLoading} = useMany({
        resource: "customers",
        ids:
            dataGridProps?.rows?.map((item: any) => {
                return item?.customer_id;
            }) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const filterOperators = getGridStringOperators().filter(
        (operator) => operator.value === "contains"
    );

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "id",
                headerName: "Id",
                filterOperators: filterOperators,
                minWidth: 120,
            },
            {
                field: "customer_id",
                headerName: "Customer",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "customer_name",
                headerName: "Customer Name",
                filterable: false,
                sortable: false,
                valueGetter: ({row}) => {
                    const value = row?.customer_id;
                    return value;
                },
                minWidth: 150,
                renderCell: function render({value}) {
                    return customerIsLoading ? (
                        <>Loading...</>
                    ) : (
                        customerData?.data?.find((item) => item.id === value)?.company_name
                    );
                },
            },
            {
                field: "street",
                headerName: "Street",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "zipcode",
                headerName: "Zipcode",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "city",
                headerName: "city",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "province",
                headerName: "Province",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "country",
                headerName: "Country",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "extra",
                headerName: "Extra",
                filterOperators: filterOperators,
            },
            {
                field: "place_id",
                headerName: "Place",
                filterOperators: filterOperators,
            },
            {
                field: "lat",
                headerName: "Lat",
                filterOperators: filterOperators,
            },
            {
                field: "lng",
                headerName: "Lng",
                filterOperators: filterOperators,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customerData?.data, customerIsLoading]
    );

    return (
        <List headerButtons={() => <><SearchField value={searchQuery} onChange={(value) => setSearchQuery(value)}/></>}>
            <DataGrid
                {...dataGridProps}
                components={{Toolbar: CustomToolbar}}
                columns={columns}
                autoHeight
                getRowHeight={() => "auto"}
            />
        </List>
    );
};
