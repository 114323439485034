import { HttpError, useList } from "@refinedev/core";
import { IUser } from "interfaces";
import { useEffect, useState } from "react";

interface IUseGetDriversProps {
    enabled?: boolean;
}

export const useGetDrivers = ({ enabled }: IUseGetDriversProps) => {
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const {
        data: responseData,
        isLoading: responseIsLoading,
        isError: responseIsError,
    } = useList<IUser, HttpError>({
        resource: "users",
        filters: [
            {
                field: "driver_role_nnil",
                operator: "eq",
                value: true,
            },
        ],
        queryOptions: {
            enabled,
        },
    });

    useEffect(() => {
        if (responseData?.data && !responseIsLoading) {
            setData(responseData.data);
            setIsLoading(responseIsLoading);
            setIsError(responseIsError);
        }
    }, [responseData, responseIsLoading, responseIsError]);

    return {
        data,
        isLoading,
        isError,
    };
};