import React from "react";
import { useGetRole } from "hooks/getRoles/useGetRole";
import { DriverDashboard } from "./driver";

export const DashboardPage: React.FC = () => {
  const role = useGetRole();

  const returnDashboard = React.useCallback(() => {
    switch (role) {
      case "driver":
        return <DriverDashboard />;
      default:
        return <h1>Dashboard</h1>;
    }
  }, [role]);

  return returnDashboard();
};
