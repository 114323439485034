import { HttpError, Option, useTranslate } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { ITelegram } from "interfaces";
import { EditFieldManager } from "../../../components/edit/fields";

export const EditTelegramModal: React.FC<
  UseModalFormReturnType<ITelegram, HttpError, ITelegram>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
      height: data.height ? data.height : 0,
      length: data.length ? data.length : 0,
      step: data.step ? data.step : 0,
      shop_order: data.shop_order ? data.shop_order : null,
    });
  };

  const record = queryResult?.data?.data;
  const fieldManager = new EditFieldManager(control, register, record, errors);

  const translate = useTranslate();

  const triggerOptions: Option[] = [
    {
      value: "admin_deliver_order_urgent",
      label: translate(
        "telegrams.triggers.admin_deliver_order_urgent",
        "Admin Deliver Order Urgent"
      ),
    },
    {
      value: "customer_create_order",
      label: translate(
        "telegrams.triggers.customer_create_order",
        "Customer Create Order"
      ),
    },
    {
      value: "admin_confirm_order_urgent",
      label: translate(
        "telegrams.triggers.admin_confirm_order_urgent",
        "Admin Confirm Order Urgent"
      ),
    },
    {
      value: "admin_create_order",
      label: translate(
        "telegrams.triggers.admin_create_order",
        "Admin Create Order"
      ),
    },
    {
      value: "customer_cancel_order_urgent",
      label: translate(
        "telegrams.triggers.customer_cancel_order_urgent",
        "Customer Cancel Order Urgent"
      ),
    },
    {
      value: "admin_create_order_urgent",
      label: translate(
        "telegrams.triggers.admin_create_order_urgent",
        "Admin Create Order Urgent"
      ),
    },
    {
      value: "admin_update_order",
      label: translate(
        "telegrams.triggers.admin_update_order",
        "Admin Update Order"
      ),
    },
    {
      value: "admin_cancel_order",
      label: translate(
        "telegrams.triggers.admin_cancel_order",
        "Admin Cancel Order"
      ),
    },
    {
      value: "admin_cancel_order_urgent",
      label: translate(
        "telegrams.triggers.admin_cancel_order_urgent",
        "Admin Cancel Order Urgent"
      ),
    },
    {
      value: "customer_confirm_order_urgent",
      label: translate(
        "telegrams.triggers.customer_confirm_order_urgent",
        "Customer Confirm Order Urgent"
      ),
    },
    {
      value: "admin_deliver_order",
      label: translate(
        "telegrams.triggers.admin_deliver_order",
        "Admin Deliver Order"
      ),
    },
    {
      value: "admin_update_order_urgent",
      label: translate(
        "telegrams.triggers.admin_update_order_urgent",
        "Admin Update Order Urgent"
      ),
    },
    {
      value: "customer_confirm_order",
      label: translate(
        "telegrams.triggers.customer_confirm_order",
        "Customer Confirm Order"
      ),
    },
    {
      value: "customer_cancel_order",
      label: translate(
        "telegrams.triggers.customer_cancel_order",
        "Customer Cancel Order"
      ),
    },
    {
      value: "customer_create_order_urgent",
      label: translate(
        "telegrams.triggers.customer_create_order_urgent",
        "Customer Create Order Urgent"
      ),
    },
  ];

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {fieldManager.textField("id", translate("telegrams.id", "Id"), {
            disabled: true,
          })}
          {fieldManager.textField("name", translate("telegrams.name", "Name"))}
          {fieldManager.selectField(
            "trigger",
            translate("telegrams.trigger", "Trigger"),
            triggerOptions
          )}
          {fieldManager.textField(
            "recipient",
            translate("telegrams.recipient", "Recipient")
          )}
          {fieldManager.textField(
            "message",
            translate("telegrams.message", "Message"),
            { multiline: true, maxRows: 8 }
          )}
          {fieldManager.switchField(
            "is_active",
            translate("telegrams.is_active", "Is Active")
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
