import React from "react";
import {
  useDataGrid,
  List,
  // EditButton,
  DeleteButton,
  DateField,
} from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { useModalForm } from "@refinedev/react-hook-form";
import { IExpense, IUser } from "interfaces";
import { HttpError, useGetIdentity, useMany } from "@refinedev/core";
import {
  Button,
  // Link
} from "@mui/material";
// import { EditExpenseModal } from "./components/editModal";
import { CreateExpenseModal } from "./components/createModal";
import { AddBoxOutlined } from "@mui/icons-material";
import { useGetRole } from "hooks/getRoles/useGetRole";

export const ExpenseList = () => {
  const role = useGetRole();
  const { data: user } = useGetIdentity<IUser>();

  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
    filters: {
      permanent: [
        {
          field: "driver_id",
          operator: "eq",
          value: role === "driver" ? user?.id : null,
        },
      ],
    },
  });

  // const editModalFormProps = useModalForm<IExpense, HttpError, IExpense>({
  //   refineCoreProps: {
  //     action: "edit",
  //     meta: { method: "put" },
  //     onMutationSuccess: () => {
  //       closeEditModal();
  //     },
  //   },
  //   modalProps: {
  //     autoResetForm: true,
  //   },
  // });

  // const {
  //   modal: { show: showEditModal, close: closeEditModal },
  // } = editModalFormProps;

  const createModalFormProps = useModalForm<IExpense, HttpError, IExpense>({
    refineCoreProps: {
      action: "create",
      onMutationSuccess: () => {
        closeCreateModal();
        resetCreate();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showCreateModal, close: closeCreateModal },
    reset: resetCreate,
  } = createModalFormProps;

  const { data: usersData, isLoading: usersIsLoading } = useMany({
    resource: "users",
    ids:
      dataGridProps?.rows?.map((item: any) => {
        return item?.driver_id;
      }) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const filterOperatorsExpense = getGridSingleSelectOperators().filter(
    (operator) => operator.value === "is"
  );

  const columns = React.useMemo<GridColumns<IExpense>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        flex: 1,
        // renderCell(params) {
        //   return (
        //     <Link
        //       onClick={() => showEditModal(params.id)}
        //       style={{ cursor: "pointer" }}
        //     >
        //       {params.id}
        //     </Link>
        //   );
        // },
      },
      {
        field: "expense",
        headerName: "Expense",
        filterOperators: filterOperatorsExpense,
        flex: 1,
        type: "singleSelect",
        valueOptions: [
          {value: "rifornimento", label: "Rifornimento"},
          {value: "pedaggi", label: "Pedaggi"},
          {value: "cibo", label: "Cibo"},
          {value: "compensi", label: "Compensi"},
          {value: "altro", label: "Altro"},
        ],
        renderCell: (({value, row}) => value === 'altro' ? row.expense_description : value)
      },
      {
        field: "amount",
        headerName: "Amount",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "driver",
        headerName: "Driver",
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => {
          const value = row?.driver_id;
          return value;
        },
        minWidth: 150,
        renderCell: function render({ value }) {
          if (usersIsLoading) return <>Loading...</>;
          const user = usersData?.data?.find((item) => item.id === value);
          return <>{user?.name ? user?.name : user?.username}</>;
        },
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 150,
        renderCell: function render({ value }) {
          return <DateField value={value} format={"DD/MM/YYYY HH:mm"} />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              {/* <EditButton hideText onClick={() => showEditModal(row.id)} /> */}
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersIsLoading, usersData?.data]
  );

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <Button
                variant="contained"
                onClick={() => showCreateModal()}
                startIcon={<AddBoxOutlined />}
              >
                Create
              </Button>
            </>
          );
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
      </List>
      {/* <EditExpenseModal {...editModalFormProps} /> */}
      <CreateExpenseModal {...createModalFormProps} />
    </>
  );
};
