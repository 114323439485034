import { useState } from "react";
import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IUser } from "interfaces";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const PasswordUserModal: React.FC<
  UseModalFormReturnType<IUser, HttpError, IUser>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  handleSubmit,
  formState: { errors },
}) => {
  const [showPassword, setShowPassword] = useState(true);
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;
    onFinish({
      ...currentData,
      password: data.password,
    });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("password")}
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  {showPassword ? (
                    <Visibility
                      className="cursor_pointer"
                      onClick={togglePassword}
                    />
                  ) : (
                    <VisibilityOff onClick={togglePassword} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
