import { Refine, Authenticated } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
} from "@refinedev/mui";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import routerBindings, {
  NavigateToResource,
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { HeaderPage } from "./components/header";
import { Login } from "pages/login";
// import { Register } from "pages/register";
// import { ForgotPassword } from "pages/forgotPassword";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";

import { DashboardPage } from "pages/dashboard";
import { dataProvider } from "./rest-data-provider";
import { ProductList } from "pages/products";
import { AddressList } from "pages/addresses";
import { ContactList } from "pages/contacts";
// import { MuiInferencer } from "@refinedev/inferencer/mui";
import { CustomerList } from "pages/customers";
import {
  AccessTimeOutlined,
  AccountBalanceOutlined,
  AcUnit,
  ApartmentOutlined,
  AssignmentInd,
  AttachMoney,
  Contacts,
  Dashboard,
  ImportContacts,
  Key,
  LocalOffer,
  LocalShippingOutlined,
  MoneyOffCsredOutlined,
  Person,
  Send,
  Settings,
  Route as RouteIcon,
} from "@mui/icons-material";
import { DisconuntRulesList } from "pages/discount_rules";
import { TelegramList } from "pages/telegrams";
import { TimeslotList } from "pages/timeslots";
import { TruckList } from "pages/trucks";
import { WarehouseList } from "pages/warehouses";
import { ExpenseList } from "pages/expenses";
import { ReportList } from "pages/reports";
import { ShipoxTokenList } from "pages/shipox_tokens";
import { UserList } from "pages/users";
import { DescriptionList } from "pages/settings";
import { EditOrderPage, OrderList, ShowOrderPage } from "pages/orders";
import { RoutesPage } from "pages/routes";
import { newEnforcer } from "casbin";
import { model, adapter } from "./accessControl";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { setDefaultOptions } from 'date-fns';
import { enGB, it } from 'date-fns/locale';






function App() {
  setDefaultOptions({ locale: it });
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };


  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Refine
                auditLogProvider={{
                  get: async ({ resource, meta }) => {
                    const { data } = await dataProvider(
                      API_URL + `/api`,
                      axiosInstance
                    ).getList({
                      resource: "events",
                      filters: [
                        {
                          field: "resource",
                          operator: "eq",
                          value: resource,
                        },
                        {
                          field: "meta.id",
                          operator: "eq",
                          value: meta?.id,
                        },
                      ],
                    });

                    return data;
                  },
                  create: (params) => {
                    const data = dataProvider(
                      API_URL + `/api`,
                      axiosInstance
                    ).create({
                      resource: "events",
                      variables: params,
                    });
                    data.then((res) => {
                      return res
                    });
                    return data;
                  },
                  update: async ({ id, name }) => {
                    const { data } = await dataProvider(
                      API_URL + `/api`,
                      axiosInstance
                    ).update({
                      resource: "events",
                      id,
                      variables: { name },
                    });

                    return data;
                  },
                }}
                dataProvider={dataProvider(API_URL + `/api`, axiosInstance)}
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                options={{
                  syncWithLocation: false,
                  disableTelemetry: true,
                }}
                accessControlProvider={{
                  can: async ({ action, params, resource }) => {
                    const enforcer = await newEnforcer(model, adapter);
                    const userRole = await authProvider.getUserIdentity?.();
                    if (
                      action === "delete" ||
                      action === "edit" ||
                      action === "show" ||
                      action === "sync"
                    ) {
                      return Promise.resolve({
                        can: await enforcer.enforce(
                          userRole,
                          `${resource}/${params?.id}`,
                          action
                        ),
                      });
                    }
                    if (action === "field") {
                      return Promise.resolve({
                        can: await enforcer.enforce(
                          userRole,
                          `${resource}/${params?.field}`,
                          action
                        ),
                      });
                    }
                    return {
                      can: await enforcer.enforce(userRole, resource, action),
                    };
                  },
                }}
                resources={[
                  {
                    name: "dashboard",
                    list: "/",
                    meta: {
                      icon: <Dashboard />,
                    },
                  },
                  {
                    name: "orders",
                    list: "/orders",
                    edit: "/orders/edit/:id",
                    show: "/orders/show/:id",
                    meta: {
                      icon: <AttachMoney />,
                      label: "Orders NTW",
                    },
                  },
                  {
                    name: "routes",
                    list: "/routes",
                    meta: {
                      icon: <RouteIcon />,
                    },
                  },
                  {
                    name: "customers",
                    list: "/customers",
                    meta: {
                      icon: <Person />,
                    },
                  },
                  {
                    name: "addresses",
                    list: "/addresses",
                    meta: {
                      icon: <ImportContacts />,
                    },
                    // show: "/addresses/show/:id",
                    // create: "/products/create",
                    // edit: "/addresses/edit/:id",
                  },
                  {
                    name: "contacts",
                    list: "/contacts",
                    meta: {
                      icon: <Contacts />,
                    },
                  },
                  {
                    name: "products",
                    list: "/products",
                    meta: {
                      icon: <AcUnit />,
                    },
                  },
                  {
                    name: "discount_rules",
                    list: "/discount_rules",
                    meta: {
                      icon: <LocalOffer />,
                    },
                  },
                  {
                    name: "telegrams",
                    list: "/telegrams",
                    meta: {
                      icon: <Send />,
                    },
                  },
                  {
                    name: "timeslots",
                    list: "/timeslots",
                    meta: {
                      icon: <AccessTimeOutlined />,
                    },
                  },
                  {
                    name: "trucks",
                    list: "/trucks",
                    meta: {
                      icon: <LocalShippingOutlined />,
                    },
                  },
                  {
                    name: "warehouses",
                    list: "/warehouses",
                    meta: {
                      icon: <ApartmentOutlined />,
                    },
                  },
                  {
                    name: "expenses",
                    list: "/expenses",
                    meta: {
                      icon: <MoneyOffCsredOutlined />,
                    },
                  },
                  {
                    name: "reports",
                    list: "/reports/balance",
                    meta: {
                      icon: <AccountBalanceOutlined />,
                    },
                  },
                  {
                    name: "shipox_tokens",
                    list: "/shipox_tokens",
                    meta: {
                      icon: <Key />,
                    },
                  },
                  {
                    name: "users",
                    list: "/users",
                    meta: {
                      icon: <AssignmentInd />,
                    },
                  },
                  {
                    name: "settings",
                    list: "/settings",
                    meta: {
                      icon: <Settings />,
                    },
                  },
                ]}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated key="auth"
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <ThemedLayoutV2 Header={HeaderPage}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route index element={<DashboardPage />} />
                    <Route path="orders">
                      <Route index element={<OrderList />} />
                      <Route path="show/:id" element={<ShowOrderPage />} />
                      <Route path="edit/:id" element={<EditOrderPage />} />
                    </Route>
                    <Route path="routes">
                      <Route index element={<RoutesPage />} />
                    </Route>
                    <Route path="customers">
                      <Route index element={<CustomerList />} />
                    </Route>
                    <Route path="addresses">
                      <Route index element={<AddressList />} />
                    </Route>
                    <Route path="contacts">
                      <Route index element={<ContactList />} />
                    </Route>
                    <Route path="products">
                      <Route index element={<ProductList />} />
                      {/* <Route path="show/:id" element={<MuiInferencer />} />
                    <Route path="edit/:id" element={<MuiInferencer />} /> */}
                      {/* <Route path="create" element={<MuiInferencer />} /> */}
                    </Route>
                    <Route path="discount_rules">
                      <Route index element={<DisconuntRulesList />} />
                    </Route>
                    <Route path="telegrams">
                      <Route index element={<TelegramList />} />
                    </Route>
                    <Route path="timeslots">
                      <Route index element={<TimeslotList />} />
                    </Route>
                    <Route path="trucks">
                      <Route index element={<TruckList />} />
                    </Route>
                    <Route path="warehouses">
                      <Route index element={<WarehouseList />} />
                    </Route>
                    <Route path="expenses">
                      <Route index element={<ExpenseList />} />
                    </Route>
                    <Route path="reports/balance">
                      <Route index element={<ReportList />} />
                    </Route>
                    <Route path="shipox_tokens">
                      <Route index element={<ShipoxTokenList />} />
                    </Route>
                    <Route path="users">
                      <Route index element={<UserList />} />
                    </Route>
                    <Route path="settings">
                      <Route index element={<DescriptionList />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <Authenticated key="auth" fallback={<Outlet />}>
                        <NavigateToResource resource="products" />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated key="auth">
                        <ThemedLayoutV2>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
              </Refine>
            </LocalizationProvider>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
