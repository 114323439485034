import {InputAdornment, InputLabel, IconButton, FilledInput, Typography, Grid} from "@mui/material";
import { FormControl } from "@mui/material";
import {Search} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import useDebounce from "../hooks/useDebounce";

export interface DisplayNumberProps {
  label: string
  value?: number
  highlight?: boolean
}

export const DisplayNumber = ({label, value=0, highlight= false}: DisplayNumberProps) => {
    let euro = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
    });

  return (
      <Grid item xs={12} display="flex" alignItems="center">
          <Typography
              variant="subtitle1"
              display="inline"
              fontWeight={highlight ? 800: undefined}
              style={{
                  marginRight: 10,
                  flexGrow: 1
              }}
          >
              {label}:
          </Typography>
          <Typography
              variant="subtitle2"
              display="inline"
              fontWeight={highlight ? 800: undefined}
          >{`${euro.format(value)}`}</Typography>
      </Grid>
  );
};
