import React, {useState} from "react";
import { useDataGrid, List, EditButton, DeleteButton } from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
} from "@mui/x-data-grid";
import {CrudFilter, HttpError, useMany} from "@refinedev/core";
import { useModalForm } from "@refinedev/react-hook-form";
import { IContact } from "interfaces";
import { Link } from "@mui/material";
import { EditContactModal } from "./components/editModal";
import CustomToolbar from "../../components/list/CustomToolbar";
import {SearchField} from "../../components/SearchField";

export const ContactList = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const filters: CrudFilter[] = []
  if (searchQuery) {
    filters.push({
      field: "q",
      operator: "eq",
      value: searchQuery,
    });
  }

  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
    filters: {
      permanent: filters,
    },
  });

  const { data: customerData, isLoading: customerIsLoading } = useMany({
    resource: "customers",
    ids:
      dataGridProps?.rows?.map((item: any) => {
        return item?.customer_id;
      }) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const editModalFormProps = useModalForm<IContact, HttpError, IContact>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "customer_id",
        headerName: "Customer",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "customer_name",
        headerName: "Customer Name",
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => {
          const value = row?.customer_id;
          return value;
        },
        minWidth: 300,
        renderCell: function render({ value }) {
          return customerIsLoading ? (
            <>Loading...</>
          ) : (
            customerData?.data?.find((item) => item.id === value)?.company_name
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "email",
        headerName: "Email",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "telephone",
        headerName: "Telephone",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "electronic_post",
        headerName: "Electronic Post",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "fax",
        headerName: "Fax",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
        filterable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerData?.data, customerIsLoading]
  );

  return (
    <>
      <List headerButtons={() => <><SearchField value={searchQuery} onChange={(value) => setSearchQuery(value)}/></>}>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          components={{Toolbar: CustomToolbar}}
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
        />
      </List>
      <EditContactModal {...editModalFormProps} />
    </>
  );
};
