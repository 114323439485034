import { HttpError, useList } from "@refinedev/core";
import { IWarehouse } from "interfaces";
import { useEffect, useState } from "react";

interface IUseGetWarehousesProps {
    enabled?: boolean;
}

export const useGetWarehouses = ({ enabled }: IUseGetWarehousesProps) => {
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const {
        data: responseData,
        isLoading: responseIsLoading,
        isError: responseIsError,
    } = useList<IWarehouse, HttpError>({
        resource: "warehouses",
        queryOptions: {
            enabled,
        },
    });

    useEffect(() => {
        if (responseData?.data && !responseIsLoading) {
            const newData = responseData.data.map((warehouse) => ({
                id: warehouse.id,
                name: warehouse.name,
                typology: warehouse.typology,
                hour_start: warehouse.hour_start,
                hour_end: warehouse.hour_end,
                address: {
                    address: warehouse.address,
                    lat: warehouse.latitude,
                    lng: warehouse.longitude,
                }
            }));

            setData(newData);
            setIsLoading(responseIsLoading);
            setIsError(responseIsError);
        }
    }, [responseData, responseIsLoading, responseIsError]);

    return {
        data,
        isLoading,
        isError,
    };
};