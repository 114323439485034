import {BaseRecord, CrudFilters} from "@refinedev/core";
import {useAutocomplete} from "@refinedev/mui";
import {Control, Controller, Path, PathValue} from "react-hook-form";
import {Autocomplete, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";

export interface ControlledAutocompleteProps<
    T extends BaseRecord,
    U extends BaseRecord
> {
    control: Control<T, {}>;
    enabled?: boolean;
    resource: string;
    sortField: string;
    filters: CrudFilters;
    name: Path<T>;
    label: string;
    searchField: string;
    matchField?: string;
    getOptionLabel: (val?: U) => string;
    serializer: (val: U | null) => string | null;
    deserializer: (val: any) => PathValue<T, Path<T>>;
    isOptionEqualToValue?: (option: U, value: U) => boolean;
    onChange?: (e: React.SyntheticEvent<Element, Event>, value: U | null) => void;
    defaultValue?: PathValue<T, Path<T>>
}

export const ControlledAutocomplete = <
    T extends BaseRecord,
    U extends BaseRecord
>({
      control,
      enabled,
      resource,
      sortField,
      filters,
      name,
      label,
      searchField,
      matchField = "id",
      getOptionLabel,
      serializer,
      deserializer,
      isOptionEqualToValue,
      onChange,
      defaultValue,
  }: ControlledAutocompleteProps<T, U>) => {

    const [lastSelection, setLastSelection] = useState<U | null>()

    const {autocompleteProps, queryResult, defaultValueQueryResult} = useAutocomplete<U>({
        resource: resource,
        defaultValue: defaultValue,
        sorters: [
            {
                field: sortField,
                order: "asc",
            },
        ],
        queryOptions: {
            enabled: enabled,
        },
        filters: filters,
        pagination: {
            pageSize: 30,
            mode: "server",
        },
        onSearch: (searchText) => {
            return [
                {
                    field: searchField,
                    operator: "contains",
                    value: searchText,
                },
            ];
        },
    });

    return (
        <Controller
            control={control}
            name={name}
            rules={{required: "Campo obbligatorio."}}
            defaultValue={null as any}
            render={({field}) => {
                field.value = deserializer(field.value); //typeof field.value === 'string' ? deserializer(field.value) : field.value
                return (
                    <Autocomplete
                        {...autocompleteProps}
                        {...(field as Omit<typeof field, "value">)}
                        loading={queryResult.isLoading || defaultValueQueryResult.isLoading}
                        onChange={(e, value) => {
                            setLastSelection(value)
                            field.onChange(serializer(value));
                            if (onChange) {
                                onChange(e, value);
                            }
                        }}
                        getOptionLabel={(item) => {
                            const option = autocompleteProps?.options?.find((p) =>
                                p ? p[matchField] === item[matchField] : false
                            );
                            return option ? getOptionLabel(option) : getOptionLabel(lastSelection || undefined); //option ? `${option.street}, ${option.province}, ${option.city}` : ''
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return isOptionEqualToValue
                                ? isOptionEqualToValue(option, value)
                                : value === undefined ||
                                option[matchField] === value[matchField];
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                        )}
                    />
                );
            }}
        />
    );
};
