// @ts-nocheck

import React from "react";
import {IOrderItem, IProduct} from "interfaces";
import {Avatar, Box, Chip, Theme} from "@mui/material";
import {withStyles} from "@mui/styles";

const ProductChip = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
  },
  label: {
    borderRadius: "20em",
    border: "5px solid #e0e0e0",
    textTransform: "capitalize",
    fontWeight: 600,
    color: "purple",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media (pointer: fine)": {
      "&:hover": {
        backgroundColor: "#ff99ff",
      },
    },
  },
}))(Chip);

type ArticleChipProps = {
  items?: IOrderItem[]
  products?: IProduct[]
}
const ArticleChip = ({items, products, ...props}: ArticleChipProps) => {
  if (!items || !products) {
    return <span>loading...</span>;
  }
  return (
    <Box>
      {items.map((item: IOrderItem) => {
        const product = products.find(p => p.id === item.product_id)
        /*if (item === "SC-R" || item.code === "S-CD") {
          return null;
        }*/
        return (
          <ProductChip
            avatar={
              <Avatar
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000000",
                  fontWeight: 600,
                  padding: 1,
                  fontSize: item.quantity.toString().length > 2 ? '0.6rem' : '0.75rem'
                }}
              >
                {item.quantity}x
              </Avatar>
            }
            key={`order-item-chip-${item.id}`}
            label={product?.abbreviation || product?.code}
            // onClick={() => history.push(`products/${item.product_id}`)}
          />
        );
      })}
    </Box>
  );
};

export default ArticleChip;
