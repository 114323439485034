import React from "react";
import {
  useMediaQuery,
  Theme,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { Show, ShowProps } from "@refinedev/mui";
import { MoreVert } from "@mui/icons-material";

interface ResponsiveShowProps extends Omit<ShowProps, "headerButtons"> {
  customHeaderButtons?: React.ReactElement[];
}

export const ResponsiveShow: React.FC<ResponsiveShowProps> = ({
  customHeaderButtons,
  ...props
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return isMobile ? (
    <Show
      {...props}
      headerProps={{ sx: { width: "100%" } }}
      wrapperProps={{ raised: false, variant: "elevation", elevation: 0 }}
      headerButtons={({ defaultButtons }) => {
        defaultButtons = defaultButtons as React.ReactElement;
        const buttons =
          defaultButtons?.props && defaultButtons?.props.children
            ? defaultButtons.props.children.map(
                (child: any, i: number) => child
              )
            : [];

        if (customHeaderButtons?.length) {
          buttons.push(...customHeaderButtons);
        }

        return (
          <>
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu
              id="show-mobile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {buttons
                .filter((child: any) => !!child)
                .map((child: any, i: number) => (
                  <MenuItem key={`mobile-menu-${i}`}>{child}</MenuItem>
                ))}
            </Menu>
          </>
        );
      }}
    />
  ) : (
    <Show
      wrapperProps={{ raised: false, variant: "elevation", elevation: 0 }}
      {...props}
    />
  );
};

export default ResponsiveShow;
