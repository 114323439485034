import {
  FormControl,
  FormControlLabel, FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  StandardTextFieldProps,
  Switch,
  TextareaAutosize,
  TextareaAutosizeProps,
  TextField,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  Path,
  PathValue, RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import { format, parseISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { BaseRecord, CrudFilters, Option } from "@refinedev/core";
import { ControlledAutocomplete } from "./controlledAutocomplete";
import {FieldError} from "react-hook-form/dist/types/errors";

const getErrorText = (err: FieldError, args?: any) => {
  switch (err.type) {
    case "min":
      return `Inserire un valore superiore a ${args?.min}`
    case "max":
      return `Inserire un valore minore o uguale a ${args?.max}`
    default:
      return "Campo obbligatorio"
  }
}
export class EditFieldManager<T extends BaseRecord> {
  private readonly control: Control<T>;
  private readonly register: UseFormRegister<T>;
  private readonly record?: T;
  private readonly changes: Partial<T>;
  private errors?: FieldErrors<T>;
  private onChange?: (value: Partial<T>) => void;

  constructor(
    control: any,
    register: any,
    record?: T,
    errors?: FieldErrors<T>,
    onChange?: (value: Partial<T>) => void
  ) {
    this.register = register;
    this.control = control;
    this.record = record;
    this.errors = errors;
    this.onChange = onChange;
    this.changes = {};
  }


  hasError = (field: keyof T) => this.errors ? !!(this.errors as any)[field] : false;
  getError = (field: keyof T) => this.errors && this.errors[field] ? !!(this.errors as any)[field].type : null;
  textField = (
    name: Path<T>,
    label: string,
    props?: Omit<
      StandardTextFieldProps,
      "defaultValue" | "onChange" | "error" | "helperText" | "label"
    >
  ) => {
    const registerOptions: RegisterOptions = { }
    if (props?.required) {
      registerOptions['required'] = "This field is required"
    }
    return (
      <TextField
        sx={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
        {...props}
        {...this.register(name, registerOptions)}
        defaultValue={this.record ? this.record[name] : ""}
        error={this.hasError(name)}
        helperText={this.errors && this.errors[name] ? getErrorText(this.errors[name] as FieldError) : ''}
        InputLabelProps={{ shrink: true }}
        label={label}
      />
    );
  };

  textArea = (
    name: Path<T>,
    label: string,
    props?: Omit<
      TextareaAutosizeProps,
      "defaultValue" | "onChange" | "error" | "helperText" | "label"
    >
  ) => {
    //error={this.hasError(name)}
    //helperText={(this.errors as any)?.internal_note?.message}
    //InputLabelProps={{shrink: true}}
    //label={label}

    return (
      <FormControl
        sx={{
          marginTop: "8px",
          marginBottom: "8px",
          minWidth: "120px",
        }}
      >
        <InputLabel>{label}</InputLabel>
        <TextareaAutosize
          minRows={3}
          {...props}
          {...this.register(name, {
            // required: "This field is required",
          })}
          defaultValue={this.record ? this.record[name] : ""}
        />
      </FormControl>
    );
  };

  numberField = (name: Path<T>, label: string, options: {defaultValue?: number, min?: number, max?: number, required?: boolean} = {}) => {
    const {defaultValue, min=0, max, required= false} = options
    const registerOptions: RegisterOptions = {
      valueAsNumber: true,
      min: min
    }
    if (max) {
      registerOptions['max'] = max
    }
    if (required) {
      registerOptions['required'] = "This field is required"
    }


    return (
      <TextField
        sx={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
        {...this.register(name, registerOptions)}
        defaultValue={this.record ? this.record[name]||defaultValue||0 : defaultValue||0}
        error={this.hasError(name)}
        InputProps={{ inputProps: { min: min, max: max } }}
        helperText={this.errors && this.errors[name] ? getErrorText(this.errors[name] as FieldError, {min, max}) : ''}
        InputLabelProps={{ shrink: true }}
        label={label}
      />
    );
  };
  currencyField = (name: Path<T>, label: string) => {
    return (
      <TextField
        sx={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
        {...this.register(name, { required: "This field is required" })}
        defaultValue={this.record ? this.record[name] || "" : ""}
        error={this.hasError(name)}
        helperText={this.errors && this.errors[name] ? getErrorText(this.errors[name] as FieldError) : ''}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={label}
        InputProps={{
          //pattern: '[0-9]*',
          inputMode: "numeric",
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
      />
    );
  };
  percentageField = (name: Path<T>, label: string) => {
    return (
      <TextField
        sx={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
        {...this.register(name, { required: "This field is required", min: 0, max: 100 })}
        defaultValue={this.record ? this.record[name] || "" : ""}
        error={this.hasError(name)}
        helperText={this.errors && this.errors[name] ? getErrorText(this.errors[name] as FieldError, {min:0, max:100}) : ''}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={label}
        InputProps={{
          //pattern: '[0-9]*',
          inputProps: { min: 0, max: 100 },
          inputMode: "numeric",
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    );
  };
  selectField = (name: Path<T>, label: string, selectOptions: Option[], options: {defaultValue?: number|string, required?: boolean} = {}) => {

    const {defaultValue, required=false} = options
    const registerOptions: RegisterOptions = {}
      if (required) {
          registerOptions.required = "Campo obbligatorio."
      }
    const getErrorText = (err: FieldError) => {
      return "Campo obbligatorio"
    }

    return (
      <Controller
        control={this.control}
        name={name}
        rules={registerOptions}
        defaultValue={defaultValue || " " as any}
        render={({ field, ...args }) => {
          const onChange = field.onChange;
          field.onChange = (event) => {
            this.changes[name] = event.target?.value;
            onChange(event);
            const mergedObj: Partial<T> = {
              ...(this.record || {}),
              ...this.changes,
            };
            this.onChange && this.onChange(mergedObj);
          };

          return (
            <>
              <InputLabel>{label}</InputLabel>
              <Select
                {...field}
                error={this.hasError(name)}
                fullWidth
                label={label}
                autoFocus
                defaultValue={" "}
              >
                {selectOptions.map((o: Option, i) => (
                  <MenuItem value={o.value} key={`option-${name}-${o.value}`}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
              {this.hasError(name) && <FormHelperText>{this.errors && this.errors[name] ? getErrorText(this.errors[name] as FieldError) : ''}</FormHelperText>}
            </>
          );
        }}
      />
    );
  };

  autocompleteField = <U extends BaseRecord>(
    name: Path<T>,
    label: string,
    resource: string,
    idField: Path<U>,
    nameField: Path<U>,
    filters?: CrudFilters,
    sortField: string = "name",
    searchField: string = "name",
    defaultValue?: PathValue<T, Path<T>>,
    options: any = {} //TODO
  ) => {
    // enabled={!!record?.id}
    return (
      <ControlledAutocomplete<T, U>
        filters={filters || []}
        resource={resource}
        sortField={sortField}
        searchField={searchField}
        control={this.control}
        label={label}
        name={name}
        getOptionLabel={(option?: U) => (option ? option[nameField] : "")}
        serializer={(value) => (value ? value[idField] : null)}
        deserializer={(value) =>
          typeof value !== "object" ? { id: value } : value
        }
        defaultValue={defaultValue}
      />
    );
  };

  switchField = (name: Path<T>, label: string, defaultValue?: boolean) => {
    //keyof PickByValue<T, boolean>
    return (
      <Controller
        control={this.control}
        name={name}
        defaultValue={
            (typeof defaultValue !== 'undefined' ? defaultValue : (this.record ? this.record[name] : false)) as PathValue<T, Path<T>>
        }
        render={({ field }) => (
          <FormControlLabel
            label={label}
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                }}
              />
            }
          />
        )}
      />
    );
  };

  dateField = (name: Path<T>, label: string, defaultValue?: string) => {
    //rules={{ required: "Campo obbligatorio." }}
    return (
      <Controller
        control={this.control}
        name={name}
        defaultValue={null as any}
        render={({ field }) => {
          let parsedDate = null;
          if (typeof field.value === "string") {
            parsedDate = parseISO(field.value);
          } else if (typeof  defaultValue === "string") {
            parsedDate = parseISO(defaultValue);
          }
          return (
            <DatePicker
              label={label}
              sx={{
                my: 1,
                marginTop: '16px'
            }}
              format="dd-MM-yyyy"
              {...field}
              value={parsedDate || ""}
              onChange={(value) => {
                if (!value) {
                  field.onChange(value);
                  return;
                }
                switch (typeof value) {
                  case "object":
                    field.onChange(format(value, "yyyy-MM-dd"));
                    break;
                  default:
                    field.onChange(value);
                }
              }}
            />
          );
        }}
      />
    );
  };

  conditionalField = (
    condition: (value: Partial<T>) => boolean,
    component: JSX.Element
  ) => {
    const mergedObj: Partial<T> = { ...(this.record || {}), ...this.changes };
    if (condition(mergedObj)) {
      return component;
    }
    return <></>;
  };
}
