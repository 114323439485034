import { AuthBindings, HttpError } from "@refinedev/core";
import { AuthHelper } from "helpers/auth";
import axios from "axios";
import jwt_decode from "jwt-decode";

import { TOKEN_KEY, API_URL } from "./constants";
import {IdentityResponse} from "@refinedev/core/dist/contexts/auth/types";



export const axiosInstance = axios.create({
  withCredentials: true,
});

let refreshing = false;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 422 && !error.config?.url.includes("/auth/refresh") && !refreshing) {
      refreshing = true;
      return new Promise((resolve, reject) => {
        refreshToken()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            refreshing = false;
          });
      });
    }

    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

const backendAuthHelper = AuthHelper(API_URL + "/api");

const refreshToken = async () => {

  delete axiosInstance.defaults.headers.common["Authorization"];

  const { data } = await axiosInstance.get(
    `${API_URL}/api/auth/refresh`, {
    transformRequest: [
      (data, headers: any) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
  }
  );

  localStorage.setItem(TOKEN_KEY, data.access_token);

  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${data.access_token}`;

  return data;
}

interface IAuthProvider extends AuthBindings {
  getUserIdentity?: ((params?: any) => Promise<IdentityResponse>) | undefined;
}

export const authProvider: IAuthProvider = {
  login: async ({ username, password, remember=false }) => {
    const { data, status } = await backendAuthHelper.login(username, password, "admin", remember);

    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.access_token);

      // set header axios instance
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.access_token}`;

      return {
        success: true,
        redirectTo: "/",
      };
    }
    return {
      success: false,
      error: new Error("Invalid username or password"),
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const decoded: any = jwt_decode(token);
      const user = JSON.parse(decoded.sub);
      return {
        id: user.id,
        name: user.username,
      };
    }
    return null;
  },
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const decoded: any = jwt_decode(token);
      if (decoded.permissions.includes("admin")) {
        return "admin";
      } else if (decoded.permissions.includes("driver")) {
        return "driver";
      } else if (decoded.permissions.includes("staff")) {
        return "staff";
      } else {
        return "customer";
      }
    }
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
