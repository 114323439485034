import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import _ from "lodash-es";
import {
  calculateDistanceMatrix,
  getRoutes,
  minutesToTime,
  timeToMinutes,
} from "helpers/maps";
import {DataGrid} from "@mui/x-data-grid";
import {useDataGrid} from "@refinedev/mui";
import {IOrderRoutes, IUser} from "interfaces";
import "./styles.css";
import {getPoints} from "helpers/utils";
import {useGetWarehouses} from "hooks/useGetWarehouses";
import {useGetTrucks} from "hooks/useGetTrucks";
import {CrudFilter, useGetIdentity, useSelect} from "@refinedev/core";
import {SearchField} from "../../components/SearchField";
import {useGetRole} from "../../hooks/getRoles/useGetRole";
import {DriverSelectField} from "../../components/DriverSelectField";
import {DataGridPro} from "@mui/x-data-grid-pro";
import DateFilter, {DateInterval} from "../../components/list/DateFilter";
import {format} from "date-fns";

interface IRoutesStepOneProps {
  setStep: any;
  setCurrentRoutes: any;
  selectedOrigin: any;
  setSelectedOrigin: (value: any) => void;
}

export const RoutesStepOne: React.FC<IRoutesStepOneProps> = ({
                                                               setStep,
                                                               setCurrentRoutes,
                                                               selectedOrigin,
                                                               setSelectedOrigin
                                                             }) => {
  const [dateFilterInterval, setDateFilterInterval] = React.useState<DateInterval>({from: new Date(), to: new Date()});

  const [warehousesEnabled, setWarehousesEnabled] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [origins, setOrigins] = useState<any>([]);

  const [trucksEnabled, setTrucksEnabled] = useState<boolean>(false);
  const [trucks, setTrucks] = useState<any>([]);

  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [trucksSort, setTrucksSort] = useState<string>("random");

  const [startTime, setStartTime] = useState<number>(480);

  const [waitForAppointments, setWaitForAppointments] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDriver, setSelectedDriver] = useState<string>("all");

  const role = useGetRole();
  const {data: user} = useGetIdentity<IUser>();

  const filters: CrudFilter[] = [
    {
      field: "status",
      operator: "eq",
      value: "confirmed",
    },
    {
      field: "driver_id",
      operator: "eq",
      value:
          role === "driver"
              ? user?.id
              : selectedDriver !== "all"
                  ? selectedDriver
                  : null,
    },
  ]
  if (searchQuery) {
    filters.push({
      field: "q",
      operator: "eq",
      value: searchQuery,
    });
  }
  if (dateFilterInterval.from) {
    filters.push({
      field: "range_start",
      operator: "eq",
      value: format(dateFilterInterval.from, "yyyy-MM-dd"),
    });
  }
  if (dateFilterInterval.to) {
    filters.push({
      field: "range_end",
      operator: "eq",
      value: format(dateFilterInterval.to, "yyyy-MM-dd"),
    });
  }

  const {
    data: warehousesData,
    isLoading: warehousesIsLoading,
    isError: warehousesIsError,
  } = useGetWarehouses({
    enabled: warehousesEnabled,
  });

  const {
    data: trucksData,
    isLoading: trucksIsLoading,
    isError: trucksIsError,
  } = useGetTrucks({
    enabled: trucksEnabled,
  });

  const stopEnableds = () => {
    setWarehousesEnabled(false);
  };

  useEffect(() => {
    setWarehousesEnabled(true);
    setTrucksEnabled(true);
  }, []);

  useEffect(() => {
    if (warehousesIsError || trucksIsError) {
      // Do something
    } else {
      if (!warehousesIsLoading && !trucksIsLoading) {
        setWarehouses(warehousesData);
        setTrucks(trucksData);
        stopEnableds();
      }
    }
  }, [
    warehousesData,
    warehousesIsLoading,
    warehousesIsError,
    trucksIsLoading,
    trucksData,
    trucksIsError,
  ]);

  useEffect(() => {
    const onlyBasepoints =
      _.filter(warehouses, (w) => {
        return w.typology === "basepoint";
      }) ?? [];
    setSelectedOrigin(onlyBasepoints[0]);
    setOrigins(onlyBasepoints);
  }, [warehouses]);

  const getSelectedRows = async () => {
    switch (trucksSort) {
      case "bigger":
        const truckBiggerSort = trucks.sort(
          (a: any, b: any) => b.capacity - a.capacity
        );
        setTrucks(truckBiggerSort);
        break;
      case "smaller":
        const truckSmallerSort = trucks.sort(
          (a: any, b: any) => a.capacity - b.capacity
        );
        setTrucks(truckSmallerSort);
        break;
      case "random":
        const truckRandomSort = trucks.sort(() => Math.random() - 0.5);
        setTrucks(truckRandomSort);
        break;
      default:
        break;
    }

    const orders = selectedRows?.map((row: any) => {
      const shipping_address = JSON.parse(row.shipping_address);
      return {
        id: row.id,
        range_start: row.range_start,
        range_end: row.range_end,
        address: shipping_address,
        weight: row.shipping_weight,
        typology: "delivery",
        delivery_date: row.delivery_date,
        delivery_time: row.delivery_time,
        customer_name: row.customer_name,
        contact_name: row.contact_name,
        telephone: row.telephone,
        shipping_address: row.shipping_address,
        shipping_weight: row.shipping_weight,
        driver_name: row.driver_name,
      };
    });

    // console.log("orders", orders);
    // console.log("warehouses", selectedOrigin);
    // console.log("trucks", trucks);

    const {points, places} = getPoints(selectedOrigin, orders);
    // console.log("points", points);
    // console.log("places", places);
    const distances = await calculateDistanceMatrix(points, places);
    // console.log("deliveries", distances);
    const routes = getRoutes(trucks, distances, startTime, waitForAppointments);
    setCurrentRoutes(routes);
    setStep(2);
    // console.log("routes");
    // console.table(routes);
    // console.log(routes);
    // console.log('without routes');
    // console.table(ordersCopy.filter((item) => {
    //     return !uniqueOrderIds.has(item.id) && item.typology === 'delivery';
    // }));
    // console.log(ordersCopy.filter((item) => {
    //     return !uniqueOrderIds.has(item.id) && item.typology === 'delivery';
    // }));
  };

  const {dataGridProps: orderRoutesDataGridProps} = useDataGrid<IOrderRoutes>(
    {
      resource: "orders/v2/routes",
      filters: {
        permanent: filters,
      },
    }
  );

  const columns = [
    {field: "id", headerName: "ID", minWidth: 130},
    {
      field: "delivery_date",
      headerName: "Delivery Date",
      minWidth: 130,
    },
    {
      field: "delivery_time",
      headerName: "Delivery Time",
      minWidth: 130,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 130,
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      minWidth: 130,
    },
    {
      field: "telephone",
      headerName: "Telephone",
      minWidth: 130,
    },
    {
      field: "shipping_address",
      headerName: "Shipping Address",
      minWidth: 200,
      valueGetter: (params: any) => {
        const shippingAddress = JSON.parse(params.row.shipping_address);
        return `${shippingAddress.street} ${shippingAddress.city} ${shippingAddress.province} ${shippingAddress.country}`;
      },
    },
    {
      field: "shipping_weight",
      headerName: "Weight",
    },
    {
      field: "driver_name",
      headerName: "Driver name",
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: 16,
        }}
      >
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            value={selectedOrigin?.id || ""}
            onChange={(e) => {
              const selectItem = _.find(
                origins,
                (o: any) => o.id === e.target.value
              );
              setSelectedOrigin(selectItem);
            }}
            select
            label="Origin"
            placeholder="Select an origin"
            style={{marginTop: 16, marginBottom: 8}}
            fullWidth
          >
            {origins?.map((warehouse: any, index: number) => (
              <MenuItem value={warehouse.id} key={index}>
                {warehouse.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2} xl={1}>
          <TextField
            value={trucksSort}
            onChange={(e) => setTrucksSort(e.target.value)}
            select
            label="Truck priority"
            style={{marginTop: 16, marginBottom: 8}}
          >
            <MenuItem value={"random"}>Random</MenuItem>
            <MenuItem value={"bigger"}>Higher Capacity</MenuItem>
            <MenuItem value={"smaller"}>Lower Capacity</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          <TextField
            value={minutesToTime(startTime)}
            onChange={(e) => {
              const time = timeToMinutes(e.target.value);
              setStartTime(time);
            }}
            label="Start time"
            type="time"
            style={{marginTop: 16, marginBottom: 8}}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4} md={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            label="Wait for appointment time"
            control={
              <Switch
                checked={waitForAppointments}
                onChange={(event) => {
                  setWaitForAppointments(event.target.checked);
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} sx={{alignSelf: 'center'}}>
          <DriverSelectField value={selectedDriver} onChange={setSelectedDriver}/>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2} sx={{alignSelf: 'center'}}>
          <SearchField value={searchQuery} onChange={setSearchQuery}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateFilter
              value={dateFilterInterval}
              onChange={setDateFilterInterval}
              showQuickSelection
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            onClick={getSelectedRows}
            disabled={false}
          >
            Get route
          </Button>
        </Grid>
      </Grid>
      <DataGridPro
        {...orderRoutesDataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => "auto"}
        // onRowClick={(params) => {
        //   console.log("params", params);
        // }}
        checkboxSelection
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important",
          },
        }}
        getRowClassName={(params) => {
          if (params.row.delivery_time_split === "A")
            return "row-afternoon-background";
          return "row-morning-background";
        }}
        onSelectionModelChange={(ids) => {
          const selectedRowData = orderRoutesDataGridProps?.rows?.filter(
            (row) => ids.includes(row.id.toString())
          );
          setSelectedRows(selectedRowData);
          // setSelectedRows(ids);
          // const selectedIDs = new Set(ids);
          // const selectedRowData = rows.filter((row) =>
          //   selectedIDs.has(row.id.toString());
          // );
          // console.log(selectedRowData);
        }}
        hideFooter
      />
    </>
  );
};
