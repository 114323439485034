import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
  GridValueSetterParams,
  GridCellParams,
} from "@mui/x-data-grid";
import { List, NumberField, useDataGrid } from "@refinedev/mui";
import {
  CrudFilters,
  HttpError,
  useGetIdentity,
  useSelect,
  useTranslate, useUpdate, useUpdateMany,
} from "@refinedev/core";
import {ChipColors, IAddress, IExpense, IOrder, IPayment, IUser} from "interfaces";
import {
  Box,
  Button,
  Grid, IconButton,
  MenuItem,
  TextField,
  Theme, Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash-es";
import { useGetWarehouses } from "hooks/useGetWarehouses";
import { getPoints } from "helpers/utils";
import {
  calculateDistanceMatrix,
  cleanRoutes,
  getRoutesSimple,
  routes,
} from "helpers/maps";
import { useGetTrucks } from "hooks/useGetTrucks";
import {Add, Edit, Payment} from "@mui/icons-material";
import { useModalForm } from "@refinedev/react-hook-form";
import { AddPaymentModal } from "../orders/tables/orderPayments";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DateFilter, { DateInterval } from "../../components/list/DateFilter";
import {CreateExpenseModal} from "../expenses/components/createModal";

interface IDriverOrder {
  id: string;
  address: IAddress;
  weight: number;
  typology: string;
  delivery_date: string;
  delivery_time: string;
  customer_name: string;
  contact_name: string;
  telephone: string;
  shipping_address: string;
  shipping_weight: number;
  truck_id?: string;
  delivery_instructions: string;
  total_amount: number;
  last_payment_amount?: number;
  payment_balance?: string;
  last_payment_status: string;
  eta?: string;
  etd?: string;
  driver_id?: string;
}

export const DriverDashboard = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [origins, setOrigins] = useState<any>([]);
  const [selectedOrigin, setSelectedOrigin] = useState<any>(undefined);
  const [selectedOrder, setSelectedOrder] = useState<IDriverOrder>();

  const [warehousesEnabled, setWarehousesEnabled] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<any>([]);

  const [trucksEnabled, setTrucksEnabled] = useState<boolean>(false);

  const [currentOrders, setCurrentOrders] = useState<IDriverOrder[]>([]);

  const [isProcessing, setIsProcessing] = useState<boolean>(true);

  const [selectedStatus, setSelectedStatus] = useState<string>("confirmed");
  const [selectedDriver, setSelectedDriver] = useState<string>(user?.id || "");

  const [dateFilterInterval, setDateFilterInterval] =
    React.useState<DateInterval>({ from: null, to: null });

  const translate = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const orderStates: {
    [key: string]: {
      color: ChipColors;
      label: string;
    };
  } = {
    all: { label: translate("orders.status.all", "tutti"), color: "primary" },
    draft: {
      label: translate("orders.status.draft", "bozza"),
      color: "secondary",
    },
    pending: {
      label: translate("orders.status.pending", "attesa"),
      color: "warning",
    },
    confirmed: {
      label: translate("orders.status.confirmed", "confermato"),
      color: "primary",
    },
    delivered: {
      label: translate("orders.status.delivered", "consegnato"),
      color: "success",
    },
    cancelled: {
      label: translate("orders.status.cancelled", "cancellato"),
      color: "error",
    },
    "": { label: "", color: "primary" },
  };

  const {mutate: updateOrder, isLoading} = useUpdate<IDriverOrder>();

  const paymentModalFormProps = useModalForm<IPayment, HttpError, IPayment>({
    refineCoreProps: {
      action: "create",
      meta: { method: "post" },
      resource: "payments",
      onMutationSuccess: () => {
        paymentModal.close();
        tableQueryResult.refetch().then(() => {
          setSelectedOrder(undefined);
          paymentModalFormProps.reset();
        });
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const { modal: paymentModal } = paymentModalFormProps;

  const expenseModalFormProps = useModalForm<IExpense, HttpError, IExpense>({
    refineCoreProps: {
      action: "create",
      meta: { method: "post" },
      resource: "expenses",
      redirect: false,
      onMutationSuccess: () => {
        createExpenseModal.close();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: createExpenseModal,
  } = expenseModalFormProps;

  const {
    data: warehousesData,
    isLoading: warehousesIsLoading,
    isError: warehousesIsError,
  } = useGetWarehouses({
    enabled: warehousesEnabled,
  });

  const { options: usersList } = useSelect<IUser>({
    resource: "users",
    optionValue: "id",
    optionLabel: "username",
    hasPagination: false,
    filters: [
      {
        field: "driver_role",
        operator: "eq",
        value: "driver",
      },
    ],
  });

  const { data: trucksData, isLoading: trucksIsLoading } = useGetTrucks({
    enabled: trucksEnabled,
  });

  const filters: CrudFilters = [
    {
      field: "status",
      operator: "eq",
      value: selectedStatus !== "all" ? selectedStatus : null,
    },
    {
      field: "driver_id",
      operator: "eq",
      value: selectedDriver,
    },
  ];

  if (dateFilterInterval.from) {
    filters.push({
      field: "delivery_date",
      operator: "gte",
      value: format(dateFilterInterval.from, "yyyy-MM-dd"),
    });
  }
  if (dateFilterInterval.to) {
    filters.push({
      field: "delivery_date",
      operator: "lte",
      value: format(dateFilterInterval.to, "yyyy-MM-dd"),
    });
  }

  const { dataGridProps, tableQueryResult } = useDataGrid<IDriverOrder>({
    resource: "orders",
    // sorters: {
    //   initial: [
    //     {
    //       field: "delivery_time",
    //       order: "asc",
    //     },
    //   ],
    // },
    filters: {
      permanent: filters,
    },
    // meta: {
    //   filter: "objects",
    // },
  });

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const handleOrderFieldUpdate = async <T extends keyof IDriverOrder>(id: string, field: T, value: IDriverOrder[T]) => {
    return new Promise<IDriverOrder>((resolve, reject) => {
      updateOrder(
        {
          resource: "orders",
          meta: {method: "put"},
          values: {
            [field]: value,
          },
          id: id,
        },
        {
          onError: (error, variables, context) => {
            //notify("custom.orders_confirmed_error", "warning")
            // An error occurred!
            reject(error)
          },
          onSuccess: (data, variables, context) => {
            resolve(data.data)
            // Let's celebrate!
          },
        }
      );
    })
  }

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        width: isMobile ? 120 : 150,
        renderCell(params) {
          return <Link to={`/orders/show/${params.id}`}>{params.id}</Link>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        filterOperators: filterOperators,
        width: isMobile ? 160 : 180,
        renderCell: function render({ row, value }) {
          const orderId = row.id;
          return (
            <>
              <Button
                size="small"
                startIcon={<Payment fontSize="small" />}
                onClick={() => {
                  paymentModalFormProps.reset();
                  paymentModalFormProps.setValue('date', row.delivery_date)
                  setSelectedOrder({ ...row });
                  paymentModal.show(orderId);
                }}
              >
                Add payment
              </Button>
            </>
          );
        },
      },
      {
        field: "delivery_date",
        headerName: "Delivery Date",
        filterOperators: filterOperators,
        width: 150,
      },
      {
        field: "delivery_time",
        headerName: "Delivery Time",
        filterOperators: filterOperators,
        width: 150,
      },
      {
        field: "eta",
        headerName: "ETA",
        filterOperators: filterOperators,
        width: 150,
      },
      {
        field: "etd",
        headerName: "ETD",
        filterOperators: filterOperators,
        width: 150,
      },
      {
        field: "customer_name",
        headerName: "Customer",
        filterOperators: filterOperators,
        width: 200,
      },
      {
        field: "products",
        headerName: "Products NTW",
        filterOperators: filterOperators,
        width: 200,
      },
      {
        field: "shipping_address",
        headerName: "Shipping Address",
        filterOperators: filterOperators,
        width: 200,
        renderCell: function render({ value }) {
          if (!value) return null;
          const address = JSON.parse(value);
          return `${address.street}, ${address.province}, ${address.city}`;
        },
      },
      {
        field: "contact",
        headerName: "Contact",
        filterOperators: filterOperators,
        width: 200,
        renderCell: function render({ row }) {
          return (
            <div>
              <div>{row?.contact_name}</div>
              <div>{row?.telephone}</div>
            </div>
          );
        },
      },
      {
        field: "delivery_instructions",
        headerName: "Delivery Instructions",
        filterOperators: filterOperators,
        width: 200,
      },
      {
        field: "last_payment_status",
        headerName: "Last Payment Status",
        filterOperators: filterOperators,
      },
      {
        field: "last_payment_amount",
        headerName: "Last Payment Amount",
        filterOperators: filterOperators,
        renderCell: function render({ value }) {
          if (!value) {
            return "";
          }
          return (
            <NumberField
              value={parseFloat(value)}
              options={{
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
          );
        },
      },
      {
        field: "payment_balance",
        headerName: translate('orders.fields.payment_balance', "Payment Balance"),
        filterOperators: filterOperators,
        renderCell: function render({ value }) {
          if (!value) {
            return "";
          }
          return (
            <NumberField
              value={parseFloat(value)}
              options={{
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
          );
        },
      },
      {
        field: "total_amount",
        headerName: "Total Amount",
        filterOperators: filterOperators,
        renderCell: function render({ value }) {
          return (
            <NumberField
              value={value}
              options={{
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
            />
          );
        },
      },
      {
        field: "driver_id",
        headerName: "Driver",
        sortable: true,
        editable: true,
        valueGetter: ({ row }) => {
          return row?.driver_id;
        },
        minWidth: 150,
        valueOptions: (params) => usersList.map((item) => ({...item})),
        renderCell: function render({ value, row }) {
          if (!usersList.length) return <>Loading...</>;
          const user = usersList?.find((item) => item.value === value);

          return <>{user?.label||value}</>;
        },
        type: "singleSelect",
        // filterOperators: filterOperatorsDriverRole,
        // valueOptions: driverFilterOptions,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersList]
  );
  //

  const stopEnableds = () => {
    setWarehousesEnabled(false);
  };

  useEffect(() => {
    setWarehousesEnabled(true);
    setTrucksEnabled(true);
  }, []);

  useEffect(() => {
    // if (warehousesIsError || trucksIsError) {
    if (warehousesIsError) {
      // Do something
    } else {
      // if (!warehousesIsLoading && !trucksIsLoading) {
      if (!warehousesIsLoading) {
        setWarehouses(warehousesData);
        // setTrucks(trucksData);
        stopEnableds();
      }
    }
  }, [
    warehousesData,
    warehousesIsLoading,
    warehousesIsError,
    // trucksIsLoading,
    // trucksData,
    // trucksIsError,
  ]);

  useEffect(() => {
    const onlyBasepoints =
      _.filter(warehouses, (w) => {
        return w.typology === "basepoint";
      }) ?? [];
    setSelectedOrigin(onlyBasepoints[0]);
    setOrigins(onlyBasepoints);
  }, [warehouses]);

  useEffect(() => {
    const getRoute = async () => {
      const orders = dataGridProps?.rows?.map((row: IOrder & {truck_id?: string, eta?: string, etd?: string}): IDriverOrder => {
        const shipping_address = JSON.parse(row.shipping_address);
        // const range_start = row.delivery_time.split("-")[0].substring(1);
        // const range_end = row.delivery_time.split("-")[1].slice(0, -1);

        return {
          id: row.id,
          // range_start: range_start,
          // range_end: range_end,
          address: shipping_address,
          weight: row.shipping_weight,
          typology: "delivery",
          delivery_date: row.delivery_date,
          delivery_time: row.delivery_time,
          customer_name: row.customer_name,
          contact_name: row.contact_name,
          telephone: row.telephone,
          shipping_address: row.shipping_address,
          shipping_weight: row.shipping_weight,
          truck_id: row.truck_id,
          delivery_instructions: row.delivery_instructions,
          total_amount: row.total_amount,
          payment_balance: row.payment_balance,
          last_payment_amount: row.last_payment_amount,
          last_payment_status: row.last_payment_status,
          eta: row.eta,
          etd: row.etd,
          driver_id: row.driver_id,
        };
      });

      if (orders.length > 0) {
        const { points, places } = getPoints(selectedOrigin, orders?.filter(order => !!order.address));
        try{
          const distances = await calculateDistanceMatrix(points, places);

          getRoutesSimple({
            distances: distances,
          });


        } catch (e) {
          console.log(e)
        }
        setCurrentOrders(routes);
        cleanRoutes();

        // const truck = trucksData?.filter((t) => t.id === orders[0].truck_id);

        // const routes = getRoutes(
        //   truck,
        //   distances,
        //   timeToMinutes("08:00"),
        //   false
        // );
        // setCurrentOrders(routes);
      } else {
        setCurrentOrders([]);
      }
      setIsProcessing(false);
    };
    if (selectedOrigin && trucksData && !trucksIsLoading) {
      getRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataGridProps?.rows,
    selectedOrigin,
    selectedStatus,
    dateFilterInterval,
    trucksData,
    trucksIsLoading,
  ]);

  const getMapPoints = (orders: any) => {
    let points = `'${selectedOrigin?.address.lat},${selectedOrigin?.address.lng}'`;
    orders.forEach((order: any) => {
      points += `/'${order.address.lat},${order.address.lng}'`;
    });
    return `https://www.google.com.mx/maps/dir/${points}`;
  };

  const cashToCollect = currentOrders.reduce((acc: number, item: IDriverOrder) => {
    if (!item.last_payment_status || ["Insoluto", "Parziale"].indexOf(item.last_payment_status) !== -1) {
      acc += +(item.payment_balance||item.total_amount);
    }
    return acc;
  }, 0);

  const cashCollected = currentOrders.reduce((acc: number, item: IDriverOrder) => {
    if (["Payed in cash", "Parziale"].indexOf(item.last_payment_status) !== -1) {
      acc += +(item.last_payment_amount||item.total_amount);
    }
    return acc;
  }, 0);

  return (
    <>
      <List title="Assigned Confirmed Orders">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          mb={2}
        >
          <Grid item xs={12} sm={4} md={2} >
            <TextField
              value={selectedOrigin?.id || ""}
              onChange={(e) => {
                const selectItem = _.find(
                  origins,
                  (o: any) => o.id === e.target.value
                );
                setSelectedOrigin(selectItem);
              }}
              select
              label="Origin"
              placeholder="Select an origin"
              style={{ marginTop: 16, marginBottom: 8 }}
              fullWidth
            >
              {origins?.map((warehouse: any, index: number) => (
                <MenuItem value={warehouse.id} key={index}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={2} flexDirection="row">
            <TextField
              value={selectedDriver}
              onChange={(e) => {
                setIsProcessing(true);
                setSelectedDriver(e.target.value);
              }}
              select
              label="Driver"
              placeholder="Select a driver"
              style={{ marginTop: 16, marginBottom: 8 }}
              fullWidth
            >
              {usersList?.map((driver) => (
                <MenuItem value={driver.value} key={driver.value}>
                  {driver.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={2} flexDirection="row">
            <TextField
              value={selectedStatus || "confirmed"}
              onChange={(e) => {
                setIsProcessing(true);
                setSelectedStatus(e.target.value);
              }}
              select
              label="Order status"
              placeholder="Select a status"
              style={{ marginTop: 16, marginBottom: 8 }}
              fullWidth
            >
              {Object.keys(orderStates).map((stateKey) => (
                <MenuItem value={stateKey} key={stateKey}>
                  {orderStates[stateKey].label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            display="flex"
            alignItems="center"
            mt={0}
          >
            {/*<TextField
              value={selectedDay || "today"}
              onChange={(e) => {
                setIsProcessing(true)
                setSelectedDay(e.target.value);
              }}
              select
              label="Delivery date"
              placeholder="Select a delivery date"
              style={{ marginTop: 16, marginBottom: 8 }}
              fullWidth
            >
              {['yesterday','today','tomorrow', 'all'].map(day => <MenuItem value={day} key={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</MenuItem>)}
            </TextField>*/}
            <DateFilter
              spacing={1}
              containerProps={{mb:0, mt:1}}
              value={dateFilterInterval}
              onChange={setDateFilterInterval}
              labelFrom="Delivery date from"
              labelTo="Delivery date to"
            />
          </Grid>
        </Grid>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          rows={currentOrders}
          autoHeight
          getRowId={(row) => row.id}
          editMode="cell"
          getRowClassName={({row}) => row.payment_balance && parseFloat(row.payment_balance) ? 'background-red' : ''}
          onCellEditCommit={({id, field, value}, event, details) => handleOrderFieldUpdate(id.toString(), field as keyof IDriverOrder, value)}
          onProcessRowUpdateError={(error) => {console.error(error); tableQueryResult.refetch()}}
          getRowHeight={() => "auto"}
          loading={isProcessing}
          hideFooter
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "168px !important",
            },
          }}
        />
        <Grid container>
          <Grid xs={12} md={6} item>
            <Box display="flex" gap={1} my={2}>
              {currentOrders.length > 0 && (
                <Button
                  href={getMapPoints(currentOrders)}
                  target="_blank"
                  variant="contained"
                >
                  GET ROUTE
                </Button>
              )}
              <Button
                onClick={() => createExpenseModal.show()}
                variant="contained"
                color="warning"
              >
                <Add/>
                {translate("expenses.title.create", "Add expense")}
              </Button>
            </Box>
          </Grid>
          <Grid xs={12} md={6} item>
            <Box display="flex" gap={1} my={2} justifyContent="end">
              <Typography variant="h6">Cash collected</Typography>
              <Typography variant="h5" color="green">€ {(cashCollected||0).toFixed(2)}</Typography>
            </Box>
            <Box display="flex" gap={1} my={2} justifyContent="end">
              <Typography variant="h6">Cash to collect</Typography>
              <Typography variant="h5" color="error">€ {(cashToCollect||0).toFixed(2)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </List>
      {selectedOrder && (
        <AddPaymentModal
          {...paymentModalFormProps}
          orderId={selectedOrder.id}
          defaultAmount={selectedOrder.last_payment_status ? parseFloat(selectedOrder.payment_balance||'0.0') : selectedOrder.total_amount}
        />
      )}
      <CreateExpenseModal {...expenseModalFormProps} />
    </>
  );
};
