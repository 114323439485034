import { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import _ from "lodash-es";

interface AddressPickerSingleProps {
  label: string;
  value: any;
  onChange: (value: any) => void;
}

export const AddressPickerSingle: React.FC<AddressPickerSingleProps> = ({
  label,
  value,
  onChange,
}) => {
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState("");

  const handleSelected = async (selectPlace: any) => {
    const results = await geocodeByAddress(selectPlace.description);
    const latLng = await getLatLng(results[0]);
    const newAddress = { ...selectPlace, ...latLng };
    onChange(newAddress);
  };

  const autocompleteService = useRef<any>();

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  const fetch = useMemo(
    () =>
      _.throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const inputChangeHandler = (place: any) => {
    fetch({ input: place }, (results: any) => {
      setOptions(results || []);
    });
  };

  return (
    <Autocomplete
      getOptionLabel={(option: any) => {
        if (!option) return "";
        return typeof option === "string" ? option : option.description;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={currentValue}
      onChange={(_, newValue) => {
        handleSelected(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setCurrentValue(newInputValue);
        inputChangeHandler(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth />
      )}
      style={{ marginTop: 16, marginBottom: 8 }}
      //   renderOption={(option) => {
      //     const matches =
      //       option.structured_formatting.main_text_matched_substrings;
      //     const parts = parse(
      //       option.structured_formatting.main_text,
      //       matches.map((match) => [match.offset, match.offset + match.length])
      //     );

      //     return (
      //       <Grid container alignItems="center">
      //         <Grid item>
      //           <LocationOnIcon className={classes.icon} />
      //         </Grid>
      //         <Grid item xs>
      //           {parts.map((part, index) => (
      //             <span
      //               key={index}
      //               style={{ fontWeight: part.highlight ? 700 : 400 }}
      //             >
      //               {part.text}
      //             </span>
      //           ))}

      //           <Typography variant="body2" color="textSecondary">
      //             {option.structured_formatting.secondary_text}
      //           </Typography>
      //         </Grid>
      //       </Grid>
      //     );
      //   }}
    />
  );
};
