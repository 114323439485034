import {HttpError, useTranslate} from "@refinedev/core";
import {SaveButton} from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField, useMediaQuery, useTheme,
} from "@mui/material";

import {UseModalFormReturnType} from "@refinedev/react-hook-form";

import {ICustomer, IExpense, IOrder, IPayment, IUser} from "interfaces";
import {Controller} from "react-hook-form";
import {EditFieldManager} from "../../../components/edit/fields";
import {useState} from "react";
import {getCurrentDate} from "../../../helpers/utils";
import {statusOptions} from "../enums";


export const CreateOrderModal: React.FC<UseModalFormReturnType<IOrder, HttpError, IOrder>> = ({
                                                                                                      saveButtonProps,
                                                                                                      refineCore: {onFinish},
                                                                                                      modal: {visible, close, title},
                                                                                                      register,
                                                                                                      control,
                                                                                                      handleSubmit,
                                                                                                      formState: {errors},
                                                                                                    }) => {
  const translate = useTranslate();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [unsavedRecord, setUnsavedRecord] = useState<Partial<IOrder>>({});



  const defaultDate = getCurrentDate()

  //translate("expenses.fields.status", "Status")
  const fieldManager = new EditFieldManager(control, register, unsavedRecord, errors, (value) => {
    setUnsavedRecord(value);
  });

  const onSubmit = (data: any) => {
    onFinish({
      ...data
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      fullScreen={isMobile}
      PaperProps={{sx: {minWidth: {xs: 'undefined', sm: '500px'} }}}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{display: "flex", flexDirection: "column"}}
        >
          {fieldManager.selectField(
              "status",
              translate("orders.fields.status", "Status"),
              statusOptions,
              {required: true, defaultValue:"pending"}
          )}
          {fieldManager.autocompleteField<ICustomer>(
              "customer_id",
              translate("orders.fields.customer", "Customer"),
              "customers",
              "id",
              "company_name",
              [],
              "company_name",
              "company_name",
              undefined,
              {required:true}
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{mb: {xs: '48px', sm: 'undefined'} }}>
        <Button onClick={close}>{translate("buttons.cancel", "Cancel")}</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)}/>
      </DialogActions>
    </Dialog>
  );
};
