import React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton
} from "@mui/x-data-grid";

export interface CustomToolbarProps {

}

const CustomToolbar: React.FC<CustomToolbarProps> = ({}) => {
  return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
  );
};

export default CustomToolbar;
