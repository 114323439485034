import { addDays, subDays } from 'date-fns';
export const getObjectWithMinValue = (arr, key) => {
    if (arr.length === 0) return null; // return null if array is empty
    return arr.reduce((min, obj) => obj[key] < min[key] ? obj : min);
}


export const workingHours = [
    {
        time_start: '8:00',
        time_end: '9:00',
        type: 'morning',
    },
    {
        time_start: '9:00',
        time_end: '10:00',
        type: 'morning',
    },
    {
        time_start: '10:00',
        time_end: '11:00',
        type: 'morning',
    },
    {
        time_start: '11:00',
        time_end: '12:00',
        type: 'morning',
    },
    {
        time_start: '12:00',
        time_end: '13:00',
        type: 'morning',
    },
]

export const getPoints = (origin, orders) => {
    const points = [origin, ...orders].map((item) => {
        return new window.google.maps.LatLng(item.address.lat, item.address.lng);
    })
    return {
        points,
        places: [origin, ...orders]
    };
}

export const checkIfValidUUID = (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
}

export const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) mm = `0${mm}`;
    if (dd < 10) dd = `0${dd}`;

    return `${yyyy}-${mm}-${dd}`;
}

export const getCurrentDates = (day) => {
    const today = new Date();

    switch (day) {
        case 'yesterday':
            return subDays(today, 1);
        case 'tomorrow':
            return addDays(today, 1);
        default:
            return today;
    }
};
export const generateReadableAddress = address =>
    `${address.street}${address.street ? ', ' : ''}${address.zipcode ? address.zipcode + ' ' : ''}${address.city ? address.city + ' ' : ''}${address.province ? '(' + address.province + ') ' : ''}${address.country || ''}`;
