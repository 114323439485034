import React from "react";
import { useMediaQuery, Theme, Box, BoxProps } from "@mui/material";
import { useTranslate } from "@refinedev/core";
import { ShowCurrency, ShowNumber, ShowText } from "../../helpers/fields/show";
import { IOrder } from "../../interfaces";
import { EditFieldManager } from "../../components/edit/fields";

interface OrderTotalsProps {
  record?: IOrder;
  fieldManager?: EditFieldManager<IOrder>;
  edit?: boolean;
}

export const OrderTotals: React.FC<OrderTotalsProps> = ({
  record,
  fieldManager,
  edit,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const translate = useTranslate();

  const showFieldsGridBox: BoxProps = isMobile
    ? {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
        my: 1,
      }
    : { flexGrow: 1 };

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(6, 1fr)",
      }}
    >
      <ShowNumber
        label={translate("orders.fields.shipping_weight", "Shipping weight")}
        value={record?.shipping_weight}
        suffix={" kg"}
        box={showFieldsGridBox}
      />
      <ShowCurrency
        label={translate("orders.fields.taxable_amount", "Taxable amount")}
        value={record?.taxable_amount}
        box={showFieldsGridBox}
      />
      {edit ? (
        <Box ml={isMobile ? 0 : 1} mr={3} {...showFieldsGridBox}>
          {fieldManager &&
            fieldManager.textField(
              "tax_percentage",
              translate("orders.fields.tax_percentage", "Tax percentage"),
              { variant: "standard" }
            )}
        </Box>
      ) : (
        <ShowNumber
          label="Tax percentage"
          suffix="%"
          decimals={0}
          value={record?.tax_percentage}
          box={showFieldsGridBox}
        />
      )}
      <ShowCurrency
        label={translate("orders.fields.tax_amount", "Tax amount")}
        value={record?.tax_amount}
        box={showFieldsGridBox}
      />
      <ShowCurrency
        label={translate("orders.fields.total_amount", "Total amount")}
        value={record?.total_amount}
        box={showFieldsGridBox}
      />
      <ShowText
        label={translate("orders.fields.due_date", "Due date")}
        value={record?.due_date}
        box={showFieldsGridBox}
      />
    </Box>
  );
};

export default OrderTotals;
