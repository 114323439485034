// "axios" package needs to be installed
import axios, { AxiosInstance } from "axios";
// "stringify" function is re-exported from "query-string" package by "@refinedev/simple-rest"
import { stringify } from "@refinedev/simple-rest";
import { DataProvider } from "@refinedev/core";
import { generateSort, generateFilter } from "./utils";
import qs from "qs";

const axiosInstance = axios.create();

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const queryFilters = generateFilter(filters);

    const query: {
      range?: string;
      sort?: string;
      skip?: number;
      limit?: number;
      _order?: string;
      by?: string;
    } = {};

    if (mode === "server") {
      query.skip = (current - 1) * pageSize;
      query.limit = pageSize;
      query.range = `[${(current - 1) * pageSize},${(current * pageSize) - 1}]`;
    }
    if (meta?.by) {
      query.by = meta.by
    }

    let generatedSort: any = generateSort(sorters);
    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      query.sort = _sort.join(",") + ',' + _order.join(",")||'asc';
      query._order = _order.join(",");
      query.sort = `["${_sort[0]}","${_order[0]}"]`;
    }

    const { data, headers } = await httpClient.get(
      `${url}?${stringify(queryFilters)}&${stringify(query)}`
    );

    const total = +headers["content-range"];

    return {
      data,
      total: total || data.length,
    };
  },

  getMany: async ({ resource, ids, metaData }) => {
    let filterField = "id";

    if (metaData?.filterField) {
      filterField = metaData.filterField;
    }
    const query = {
      filter: JSON.stringify({ [filterField]: ids }),
    };

    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${qs.stringify(query)}`
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    if (meta?.method === "put") {
      const { data } = await httpClient.put(url, variables);
      return {
        data,
      };
    }

    const { data } = await httpClient.patch(url, variables);
    return {
      data,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;


    const { data } = await httpClient.delete(url, {
      data: variables,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
