export const calculateDistanceMatrix = async (points, places) => {
    return new Promise((resolve, reject) => {
        const service = new window.google.maps.DistanceMatrixService();

        service.getDistanceMatrix(
            {
                origins: points,
                destinations: points,
                travelMode: window.google.maps.TravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC
            },
            (response, status) => {
                if (status === window.google.maps.DistanceMatrixStatus.OK) {
                    const rows = response?.rows;
                    let distances = [];
                    places.forEach((item, index) => {
                        let element = {
                            ...item,
                            range_start: timeToMinutes(item?.range_start),
                            range_end: timeToMinutes(item?.range_end),
                            distances: []
                        }
                        const row = rows[index];
                        [...row?.elements].forEach((rowItem, rowIndex) => {
                            if (rowIndex !== index) {
                                const place = places[rowIndex];
                                element.distances.push({
                                    ...place,
                                    range_start: timeToMinutes(place.range_start),
                                    range_end: timeToMinutes(place.range_end),
                                    distance: rowItem.distance.value,
                                    duration: rowItem.duration.value
                                })
                            }
                        })
                        distances.push(element);
                    })

                    resolve(distances);
                } else {
                    reject(new Error(`Error calculating distance: ${status}`));
                }
            }
        );
    });
}

export const timeToMinutes = (time) => {
    if (!time) return 0;
    if (typeof time === 'number') {
        return time
    }
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
}

export const minutesToTime = (minutes) => {
    if (!minutes) return '00:00';
    let hours = Math.floor(minutes / 60);
    hours = hours < 10 ? `0${hours}` : hours;
    let mins = minutes % 60;
    mins = mins < 10 ? `0${mins}` : mins;
    return `${hours}:${mins}`;
}

export const getRoutes = (trucks, distances, start_time = 480, waitForAppointments) => {
    let routes = [];
    let ordersCopy = [...distances];
    let trucksCopy = [...trucks];
    let currentOrigin = [...distances[0]?.distances];
    let currentCapacity = 0;
    let current_start_time = start_time;
    const uniqueOrderIds = new Set();

    const stop_time = 15;
    const stop_time_10_kg = 2;

    trucksCopy.forEach((truck) => {
        currentCapacity = truck.capacity;
        let orders = [...getOrderCapacity(currentOrigin, currentCapacity, current_start_time)];
        orders = orders.filter((item) => !uniqueOrderIds.has(item.id));
        while (true) {
            if (orders.length === 0) break;
            const order = orders[0];
            if (waitForAppointments) {
                if (order.range_start > current_start_time) {
                    current_start_time = order.range_start;
                }
            }

            const eta = current_start_time + Math.ceil(order.duration / 60);
            const stop_kg = Math.ceil((order.weight / 10) * stop_time_10_kg);
            const max_end_time = eta + stop_time + stop_kg;

            routes = [...routes, {
                ...order,
                eta: minutesToTime(eta),
                etd: minutesToTime(max_end_time),
                truck: truck,
            }];
            currentCapacity -= order.weight;
            current_start_time = max_end_time;
            uniqueOrderIds.add(order.id);
            currentOrigin = ordersCopy.find(
                (item) => item.id === order.id
            )?.distances.filter((item) => !uniqueOrderIds.has(item.id));
            orders = [...getOrderCapacity(currentOrigin, currentCapacity, current_start_time)];
        }
        currentOrigin = [...distances[0]?.distances].filter((item) => !uniqueOrderIds.has(item.id));
        orders = [...getOrderCapacity(currentOrigin, currentCapacity, current_start_time)];
    })

    return routes;
}

export let routes = [];

export const getRoutesSimple = ({ distances, start_time = 480, waitForAppointments = false }) => {
    let ordersCopy = [...distances];
    // let current_start_time = start_time;
    const uniqueOrderIds = new Set();

    // const stop_time = 15;
    // const stop_time_10_kg = 2;

    getOrder({
        orders: ordersCopy,
        routes: routes,
        uniqueOrderIds: uniqueOrderIds,
    })
}

export const getOrderCapacity = (orders, capacity, start_time) => {
    const ordersCapacity = orders?.filter((order) => {
        const new_capacity = capacity - order.weight;
        return new_capacity >= 0 && start_time <= order.range_end;
    }).sort((a, b) => {
        if (a.range_start === b.range_start) {
            return a.distance - b.distance;
        }
        return a.range_start - b.range_start;
    });
    return ordersCapacity;
}

export const getOrder = ({ orders, uniqueOrderIds }) => {
    let currentOrder = orders.shift(0, 1);
    if (currentOrder !== undefined) {
        if (currentOrder.typology !== "basepoint") {
            routes = [...routes, currentOrder];
        }
        uniqueOrderIds.add(currentOrder?.id);
        const nextOrders = getDistances({
            place: currentOrder,
            orders: orders,
            routes: routes,
            uniqueOrderIds: uniqueOrderIds,
        });

        if (nextOrders.length > 0) {
            getOrder({
                orders: nextOrders,
                routes: routes,
                uniqueOrderIds: uniqueOrderIds,
            })
        }
    }
}

export const getDistances = ({ place, orders, uniqueOrderIds }) => {
    const nextOrder = place?.distances?.filter((item) => {
        return !uniqueOrderIds.has(item.id);
    }).sort((a, b) => {
        if (a.range_start === b.range_start) {
            return a.distance - b.distance;
        }
        return a.range_start - b.range_start;
    })[0];

    const currentOrder = orders.find((item) => item.id === nextOrder?.id);
    if (!currentOrder) {
        return [];
    }

    const newArray = [
        currentOrder,
        ...orders.filter((item) => item.id !== nextOrder?.id)
    ];
    return newArray;
}

export const cleanRoutes = () => {
    routes = [];
}
