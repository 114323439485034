import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { ISetting } from "interfaces";

export const EditSettingModal: React.FC<
  UseModalFormReturnType<ISetting, HttpError, ISetting>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  handleSubmit,
  formState: { errors },
}) => {
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("id")}
            error={!!errors.id}
            helperText={errors.id?.message}
            margin="normal"
            fullWidth
            label="Id"
            autoFocus
            defaultValue={" "}
            disabled
          />
          <TextField
            {...register("key")}
            error={!!errors.key}
            helperText={errors.key?.message}
            margin="normal"
            fullWidth
            label="key"
            autoFocus
            defaultValue={" "}
            disabled
          />
          <TextField
            {...register("value")}
            error={!!errors.value}
            helperText={errors.value?.message}
            margin="normal"
            fullWidth
            label="Value"
            autoFocus
            defaultValue={" "}
          />

          <TextField
            {...register("description")}
            error={!!errors.description}
            helperText={errors.description?.message}
            margin="normal"
            label="Description"
            defaultValue={" "}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
