import { MetaQuery } from "@refinedev/core";
import axios from "axios";

interface ILoginResponse {
    access_token: string;
}

export type MeOptions = {
    meta?: MetaQuery;
    /**
     * @deprecated `metaData` is deprecated with refine@4, refine will pass `meta` instead, however, we still support `metaData` for backward compatibility.
     */
    metaData?: MetaQuery;
};

export const AuthHelper = (apiUrl: string) => ({
    login: async (username: string, password: string, origin: string, remember = false) => {
        const url = `${apiUrl}/auth/login`;

        return await axios.post<ILoginResponse>(url, {
            username,
            password,
            remember,
            origin,
        });
    },
    me: async (token: string, options?: MeOptions) => {
        // const { metaData, meta: _meta } = options ?? {};
        // const meta = pickNotDeprecated(_meta, metaData);
        // const locale = meta?.locale;
        // const fields = meta?.fields;
        // const populate = meta?.populate;

        // const query = {
        //     locale,
        //     fields,
        //     populate,
        // };

        return await axios.get<any>(
            `${apiUrl}/auth/refresh`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    },
});
