import { useEffect, useState } from "react";
import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IWarehouse } from "interfaces";
import { Controller } from "react-hook-form";
import { AddressPickerSingle } from "components/addresPickerSingle";

const typologies = [
  { id: "basepoint", label: "Basepoint" },
  { id: "checkpoint", label: "Checkpoint" },
];

const hours = [
  { id: "8:00", label: "8:00" },
  { id: "8:30", label: "8:30" },
  { id: "9:00", label: "9:00" },
  { id: "9:30", label: "9:30" },
  { id: "10:00", label: "10:00" },
  { id: "10:30", label: "10:30" },
  { id: "11:00", label: "11:00" },
  { id: "11:30", label: "11:30" },
  { id: "12:00", label: "12:00" },
  { id: "12:30", label: "12:30" },
  { id: "13:00", label: "13:00" },
  { id: "14:00", label: "14:00" },
  { id: "15:00", label: "15:00" },
  { id: "16:00", label: "16:00" },
  { id: "17:00", label: "17:00" },
  { id: "18:00", label: "18:00" },
  { id: "19:00", label: "19:00" },
];

type Place = {
  description: string;
  place_id: string;
  label: string;
  lat: string;
  lng: string;
};

export const EditWarehouseModal: React.FC<
  UseModalFormReturnType<IWarehouse, HttpError, IWarehouse>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const [place, setPlace] = useState<Place>();

  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      name: data.name,
      typology: data.typology,
      hour_start: data.hour_start,
      hour_end: data.hour_end,
      latitude: place?.lat || "",
      longitude: place?.lng || "",
      address: place?.description || "",
    });
    setPlace(undefined);
  };

  useEffect(() => {
    const currentData = queryResult?.data?.data;
    if (currentData?.address) {
      setPlace({
        description: currentData?.address,
        place_id: currentData?.address,
        label: currentData?.address,
        lat: currentData?.latitude,
        lng: currentData?.longitude,
      });
    }
  }, [queryResult]);

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            margin="normal"
            fullWidth
            label="Name"
            autoFocus
            defaultValue={" "}
          />
          <AddressPickerSingle
            label={"Address"}
            value={place}
            onChange={setPlace}
          />
          <Controller
            control={control}
            name="typology"
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value || ""}
                  select
                  label="Typology"
                  style={{ marginTop: 16, marginBottom: 8 }}
                >
                  {typologies.map((typology) => (
                    <MenuItem key={typology.id} value={typology.id}>
                      {typology.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
          <Controller
            control={control}
            name="hour_start"
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value || ""}
                  select
                  label="Hour start"
                  style={{ marginTop: 16, marginBottom: 8 }}
                >
                  {hours.map((hour) => (
                    <MenuItem key={hour.id} value={hour.id}>
                      {hour.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
          <Controller
            control={control}
            name="hour_end"
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value || ""}
                  select
                  label="Hour end"
                  style={{ marginTop: 16, marginBottom: 8 }}
                >
                  {hours.map((hour) => (
                    <MenuItem key={hour.id} value={hour.id}>
                      {hour.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
