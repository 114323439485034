import React from "react";
import { useDataGrid, List, EditButton, DeleteButton } from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { useModalForm } from "@refinedev/react-hook-form";
import { ITelegram } from "interfaces";
import { HttpError } from "@refinedev/core";
import { Button, Link } from "@mui/material";
import { EditTelegramModal } from "./components/editModal";
import { AddBoxOutlined } from "@mui/icons-material";
import { CreateTelegramModal } from "./components/createModal";

export const TelegramList = () => {
  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
  });

  const editModalFormProps = useModalForm<ITelegram, HttpError, ITelegram>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      resource: "telegrams",
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const createModalFormProps = useModalForm<ITelegram, HttpError, ITelegram>({
    refineCoreProps: {
      action: "create",
      meta: { method: "post" },
      resource: "telegrams",
      onMutationSuccess: () => {
        closeCreateModal();
        resetCreate();
      },
    },
  });

  const {
    modal: { show: showCreateModal, close: closeCreateModal },
    reset: resetCreate,
  } = createModalFormProps;

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "trigger",
        headerName: "Trigger",
        filterOperators: filterOperators,
        minWidth: 250,
      },
      {
        field: "recipient",
        headerName: "Recipient",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "message",
        headerName: "Message",
        filterOperators: filterOperators,
        minWidth: 450,
      },
      {
        field: "is_active",
        headerName: "Is Active",
        type: "boolean",
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
        filterable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <Button
                variant="contained"
                onClick={() => showCreateModal()}
                startIcon={<AddBoxOutlined />}
              >
                Create
              </Button>
            </>
          );
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
        />
      </List>
      <EditTelegramModal {...editModalFormProps} />
      <CreateTelegramModal {...createModalFormProps} />
    </>
  );
};
