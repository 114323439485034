import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IProduct } from "interfaces";
import { Controller } from "react-hook-form";

export const EditProductModal: React.FC<
  UseModalFormReturnType<IProduct, HttpError, IProduct>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
      height: data.height ? data.height : 0,
      length: data.length ? data.length : 0,
      step: data.step ? data.step : 0,
      shop_order: data.shop_order ? data.shop_order : null,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("abbreviation")}
            error={!!errors.abbreviation}
            helperText={errors.abbreviation?.message}
            margin="normal"
            fullWidth
            label="Abbreviation"
            autoFocus
            defaultValue={" "}
          />

          <TextField
            {...register("weight_kg")}
            error={!!errors.weight_kg}
            helperText={errors.weight_kg?.message}
            margin="normal"
            label="Weight Kg"
            type="number"
            defaultValue={"0"}
          />

          <TextField
            {...register("length")}
            error={!!errors.length}
            helperText={errors.length?.message}
            margin="normal"
            label="Length"
            type="number"
            defaultValue={"0"}
          />

          <TextField
            {...register("width")}
            error={!!errors.width}
            helperText={errors.width?.message}
            margin="normal"
            label="Width"
            type="number"
            defaultValue={"0"}
          />

          <TextField
            {...register("height")}
            error={!!errors.height}
            helperText={errors.height?.message}
            margin="normal"
            label="Height"
            type="number"
            defaultValue={"0"}
          />

          <TextField
            {...register("step")}
            error={!!errors.step}
            helperText={errors.step?.message}
            margin="normal"
            label="Step"
            type="number"
            defaultValue={"0"}
          />

          <TextField
            {...register("shop_name")}
            error={!!errors.shop_name}
            helperText={errors.shop_name?.message}
            margin="normal"
            fullWidth
            label="Shop Name"
            autoFocus
            defaultValue={" "}
          />

          <TextField
            {...register("shop_order")}
            error={!!errors.shop_order}
            helperText={errors.shop_order?.message}
            margin="normal"
            label="Shop Order"
            // type="number"
            defaultValue={" "}
          />

          <Controller
            control={control}
            name="shop_enabled"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Shop Enabled"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                  //   <Checkbox
                  //     {...field}
                  //     checked={field.value}
                  //     onChange={(event) => {
                  //       field.onChange(event.target.checked);
                  //     }}
                  //   />
                }
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
