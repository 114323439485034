import React from "react";
import { useDataGrid, List, EditButton, DeleteButton } from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { useModalForm } from "@refinedev/react-hook-form";
import { ISetting } from "interfaces";
import { HttpError, useMany } from "@refinedev/core";
import { Button, Link } from "@mui/material";
import { EditSettingModal } from "./components/editModal";
import { AddBoxOutlined } from "@mui/icons-material";
import { CreateSettingModal } from "./components/createModal";

export const DescriptionList = () => {
  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "key",
          order: "asc",
        },
      ],
    },
  });

  const { data: usersData, isLoading: usersIsLoading } = useMany({
    resource: "users",
    ids:
      dataGridProps?.rows?.map((item: any) => {
        return item?.driver_id;
      }) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const editModalFormProps = useModalForm<ISetting, HttpError, ISetting>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const createModalFormProps = useModalForm<ISetting, HttpError, ISetting>({
    refineCoreProps: {
      action: "create",
      onMutationSuccess: () => {
        closeCreateModal();
        resetCreate();
      },
    },
  });

  const {
    modal: { show: showCreateModal, close: closeCreateModal },
    reset: resetCreate,
  } = createModalFormProps;

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        flex: 1,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "key",
        headerName: "Key",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "value",
        headerName: "Value",
        flex: 1,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersIsLoading, usersData?.data]
  );

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <Button
                variant="contained"
                onClick={() => showCreateModal()}
                startIcon={<AddBoxOutlined />}
              >
                Create
              </Button>
            </>
          );
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
      </List>
      <EditSettingModal {...editModalFormProps} />
      <CreateSettingModal {...createModalFormProps} />
    </>
  );
};
