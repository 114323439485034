import React, {useEffect} from "react";
import {SaveButton, useAutocomplete, useDataGrid} from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton,
  Theme, Typography,
  useMediaQuery,
} from "@mui/material";
import {
  useModalForm,
  UseModalFormReturnType,
} from "@refinedev/react-hook-form";
import {IAddress, IOrderItem, IProduct} from "interfaces";
import {HttpError, Option, useLink, useSelect, useTranslate} from "@refinedev/core";
import { EditFieldManager } from "components/edit/fields";
import { ControlledAutocomplete } from "components/edit/controlledAutocomplete";
import { NewCrudColumn } from "components/edit/crudControls";
import {Add} from "@mui/icons-material";

export interface OrderItemsProps {
  edit?: boolean;
  orderId?: string;
  isLoading: boolean;
}

interface AddOrderItemModalProps {
  orderId?: string;
}

export const AddOrderItemModal: React.FC<
  UseModalFormReturnType<IOrderItem, HttpError, IOrderItem> &
    AddOrderItemModalProps
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
  orderId,
}) => {
  const translate = useTranslate();
  const record = queryResult?.data?.data;

  const fieldManager = new EditFieldManager(control, register, record, errors);
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
      order_id: orderId,
    });
  };

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 320 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {/*<ControlledAutocomplete<IOrderItem, IProduct>
            filters={[]}
            resource="products"
            sortField="name"
            searchField="name"
            control={control}
            enabled={!!orderId}
            label="Product"
            name="product_id"
            getOptionLabel={(option?: IProduct) => (option ? option.name : "")}
            serializer={(value) => value?.id || null}
            deserializer={(value) =>
              typeof value !== "object" ? { id: value } : value
            }
          />*/}
          {fieldManager.autocompleteField<IProduct>(
              "product_id",
              translate('order_items.fields.product', "Product"),
              "products",
              "id",
              "name",
              [],
              "name",
              "search",
              queryResult?.data?.data?.product_id
          )}
          {fieldManager.numberField("quantity", translate('order_items.fields.quantity', "Quantity"))}
          {!!record && fieldManager.percentageField("discount_percentage", translate('order_items.fields.discount_percentage', "Discount"))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
export const OrderItems: React.FC<OrderItemsProps> = ({
  orderId,
  isLoading,
  edit,
}) => {
  const { dataGridProps: {loading: dataGridLoading, filterModel, onFilterModelChange, ...dataGridProps} } = useDataGrid({
    resource: "order_items",
    filters: {
      permanent: [
        {
          field: "order_id",
          operator: "eq",
          value: orderId,
        },
      ],
    },
  });

  const {
    options,
    queryResult: { isLoading: isProductLoading },
  } = useSelect<IProduct>({
    resource: "products",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });

  const Link = useLink();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const defaultMinWidth = 100;
  useEffect(() => {
    if (!isLoading) {

    }
  }, [isLoading])

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "product_id",
        headerName: "Product",
        type: "singleSelect",
        headerAlign: "left",
        align: "left",
        minWidth: 250,
        flex: 0.5,
        valueOptions: options,
        valueFormatter: (params: GridValueFormatterParams<Option>) => {
          return params.value;
        },
        renderCell: function render({ row }) {
          if (isProductLoading) {
            return "Loading...";
          }
          const product = options.find(
            (item) => item.value.toString() === row.product_id.toString()
          );
          return product ? (
            <Link
              to={`/products/?filters[0][field]=id&filters[0][value]=${product.value}&filters[0][operator]=eq`}
            >
              {product.label}
            </Link>
          ) : (
            ""
          );
        },
      },
      {
        field: "quantity",
        headerName: "Quantity",
        type: "number",
        flex: 1,
        minWidth: defaultMinWidth,
      },
      {
        field: "base_price",
        headerName: "Base price",
        type: "number",
        flex: 1,
        renderCell: function render({ value }) {
          return <span>€ {(value || 0).toFixed(2)}</span>;
        },
        minWidth: defaultMinWidth,
      },
      {
        field: "discount_percentage",
        headerName: "Discount percentage",
        type: "number",
        flex: 1,
        renderCell: function render({ value }) {
          return <span>{(value || 0).toFixed(2)} %</span>;
        },
        minWidth: defaultMinWidth,
      },
      {
        field: "net_price",
        headerName: "Net price",
        type: "number",
        flex: 1,
        renderCell: function render({ value }) {
          return <span>€ {(value || 0).toFixed(2)}</span>;
        },
        minWidth: defaultMinWidth,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, orderId, options]
  );

  const createModalFormProps = useModalForm<IOrderItem, HttpError, IOrderItem>({
    refineCoreProps: {
      action: "create",
      meta: { method: "post" },
      resource: "order_items",
      onMutationSuccess: () => {
        createModal.close();
        createModalFormProps.reset();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const { modal: createModal } = createModalFormProps;

  const editModalFormProps = useModalForm<IOrderItem, HttpError, IOrderItem>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      resource: "order_items",
      onMutationSuccess: () => {
        editModal.close();
        editModalFormProps.reset();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const { modal: editModal } = editModalFormProps;

  const crudColumn = NewCrudColumn<IOrderItem>(
    "order_items",
    createModal,
    editModal
  );
  if (edit) {
    columns.push(crudColumn);
  }

  if (!orderId) {
    return <></>;
  }

  return (
    <Box>
      <Box p={1} display="flex" alignItems="center">
        <Typography sx={{flexGrow: 1}} variant="h6" color="primary">
          Items
        </Typography>
        <Box>
          <IconButton color="primary" onClick={() => createModal.show()}><Add/></IconButton>
        </Box>
      </Box>
      <DataGrid
        {...dataGridProps}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        loading={dataGridLoading || isLoading}
        columns={columns}
        autoHeight
        getRowHeight={() => (isMobile ? 50 : "auto")}
        // onRowClick={(params) => {
        //
        // }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important",
          },
          "& .MuiDataGrid-cell:last-of-type": {
            // Forced to use important since overriding inline styles
            minWidth: "80px !important",
          },
          "& .MuiDataGrid-columnHeader:last-of-type": {
            // Forced to use important since overriding inline styles
            minWidth: "80px !important",
          },
        }}
      />
      {createModalFormProps?.modal?.visible && (
        <AddOrderItemModal {...createModalFormProps} orderId={orderId} />
      )}
      {editModalFormProps?.modal?.visible && (
        <AddOrderItemModal {...editModalFormProps} orderId={orderId} />
      )}
    </Box>
  );
};
