import React, {useState} from "react";
import {
  useDataGrid,
  List,
  TagField,
  EditButton,
  DeleteButton,
} from "@refinedev/mui";
import {GridColumns} from "@mui/x-data-grid";
import {CrudFilter, HttpError, useMany} from "@refinedev/core";
import {Box, Button, Link} from "@mui/material";
import { useModalForm } from "@refinedev/react-hook-form";
import { IDiscountRule } from "interfaces";
import { EditDiscountRuleModal } from "./components/editModal";
import { AddBoxOutlined } from "@mui/icons-material";
import { CreateDiscountRuleModal } from "./components/createModal";
import {useNavigate} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import CustomToolbar from "../../components/list/CustomToolbar";
import {SearchField} from "../../components/SearchField";

export const DisconuntRulesList = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const filters: CrudFilter[] = []
  if (searchQuery) {
    filters.push({
      field: "q",
      operator: "eq",
      value: searchQuery,
    });
  }

  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
    filters: {
      permanent: filters,
    },
  });

  const {
    data: discountItemsData,
    isLoading: discountItemsIsLoading,
    refetch: discountItemsRefetch,
  } = useMany({
    resource: "discount_items",
    ids:
      dataGridProps?.rows.map((item: any) => {
        return item?.id;
      }) ?? [],
    metaData: {
      filterField: "discount_rule_id",
    },
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const { data: productsData, isLoading: productsIsLoading } = useMany({
    resource: "products",
    ids: discountItemsData?.data
      ? discountItemsData?.data.map((item: any) => item?.product_id)
      : [],
    queryOptions: {
      enabled: !discountItemsIsLoading && !!discountItemsData,
    },
  });

  const {
    data: discountsData,
    isLoading: discountsIsLoading,
    refetch: discountsRefetch,
  } = useMany({
    resource: "discounts",
    ids:
      dataGridProps?.rows.map((item: any) => {
        return item?.id;
      }) ?? [],
    metaData: {
      filterField: "discount_rule_id",
    },
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const { data: customersData, isLoading: customersIsLoading } = useMany({
    resource: "customers",
    ids: discountsData?.data
      ? discountsData?.data.map((item: any) => item?.customer_id)
      : [],
    queryOptions: {
      enabled: !discountsIsLoading && !!discountsData,
    },
  });

  const editModalFormProps = useModalForm<
    IDiscountRule,
    HttpError,
    IDiscountRule
  >({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      resource: "discount_rules/v2",
      onMutationSuccess: () => {
        closeEditModal();
        discountsRefetch();
        discountItemsRefetch();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const createModalFormProps = useModalForm<
    IDiscountRule,
    HttpError,
    IDiscountRule
  >({
    refineCoreProps: {
      action: "create",
      onMutationSuccess: ({data}) => {
        const id = data.id
        closeCreateModal();
        resetCreate();
        showEditModal(id)
      },
    },
  });

  const {
    modal: { show: showCreateModal, close: closeCreateModal },
    reset: resetCreate,
  } = createModalFormProps;

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        minWidth: 130,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
      },
      {
        field: "target_products",
        headerName: "Target Products",
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => {
          const value = row?.id;
          return value;
        },
        minWidth: 500,
        renderCell: function render({ value }) {
          if (productsIsLoading) return <>Loading...</>;
          const discountItem = discountItemsData?.data
            ?.filter((item) => item?.discount_rule_id === value)
            .map((item) => item?.product_id);

          const product = productsData?.data?.filter((item) =>
            discountItem?.includes(item?.id)
          );
          return (
            <div>
              {product?.map((item, key) => {
                return (
                  <TagField key={key} value={item.name} style={{ margin: 4 }} />
                );
              })}
            </div>
          );
        },
      },
      {
        field: "target_customers",
        headerName: "Target Customers",
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => {
          const value = row?.id;
          return value;
        },
        minWidth: 500,
        renderCell: function render({ value }) {
          if (customersIsLoading) return <>Loading...</>;
          const discounts = discountsData?.data
            ?.filter((item) => item?.discount_rule_id === value)
            .map((item) => item?.customer_id);

          const customers = customersData?.data?.filter((item) =>
            discounts?.includes(item?.id)
          );
          return (
            <div>
              {customers?.map((item, key) => {
                return (
                  <TagField
                    key={key}
                    value={item.company_name}
                    style={{ margin: 4 }}
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
        filterable: false,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      productsIsLoading,
      productsData?.data,
      customersIsLoading,
      customersData?.data,
      discountItemsData?.data,
      discountsData?.data,
    ]
  );

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              <SearchField value={searchQuery} onChange={(value) => setSearchQuery(value)}/>
              {defaultButtons}
              <Button
                variant="contained"
                onClick={() => showCreateModal()}
                startIcon={<AddBoxOutlined />}
              >
                Create
              </Button>
            </>
          );
        }}
      >
        <Box
            style={{
              height: `calc(100vh - 240px)`,
            }}
        >
        <DataGridPro
          {...dataGridProps}
          pagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          columns={columns}
          autoHeight={false}
          components={{Toolbar: CustomToolbar}}
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
        </Box>
      </List>
      {editModalFormProps?.modal?.visible && (
        <EditDiscountRuleModal {...editModalFormProps} />
      )}
      <CreateDiscountRuleModal {...createModalFormProps} />
    </>
  );
};
