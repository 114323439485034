import {FormEvent, useEffect, useState} from "react";
import {
    Autocomplete, Box, Button, Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid, Switch,
    TextField,
    Typography
} from "@mui/material";

import {IContact} from "../../interfaces";
import {Person2} from "@mui/icons-material";
import {useAutocomplete} from "@refinedev/mui";

interface ContactPickerProps {
    label: string;
    value?: IContact;
    isLoading?: boolean
    customerId?: string;
    preloadedValues?: IContact[]
    onChange: (contact?: IContact | null, isNew?: boolean, saveContact?: boolean) => void;
}


export const ContactPicker: React.FC<ContactPickerProps> = ({
                                                                label,
                                                                value,
                                                                isLoading = false,
                                                                onChange,
                                                                customerId,
                                                                preloadedValues = []
                                                            }) => {
    const [currentValue, setCurrentValue] = useState("");
    const [open, toggleOpen] = useState(false);

    const [saveContact, setSaveContact] = useState(false)

    const [dialogValue, setDialogValue] = useState<IContact>({
        name: '',
        telephone: '',
        customer_id: "",
        customer_name: "",
        electronic_post: "",
        email: "",
        fax: "",
        id: ""

    });

    const handleDialogClose = () => {
        setDialogValue({
            name: '',
            telephone: '',
            customer_id: "",
            customer_name: "",
            electronic_post: "",
            email: "",
            fax: "",
            id: ""
        });
        toggleOpen(false);
    };

    const handleSubmit = <T, >(e: FormEvent<T>) => {
        e.preventDefault()

        // @ts-ignore
        autocompleteProps.onInputChange(e, dialogValue.name, "input")
        onChange(dialogValue, true, saveContact)

        setSaveContact(false)
        handleDialogClose()
    }

    const {autocompleteProps: {options, ...autocompleteProps}, onSearch} = useAutocomplete<IContact>({
        resource: 'contacts',
        defaultValue: value?.id || value?.telephone,
        sorters: [
            {
                field: "name",
                order: "asc",
            },
        ],
        queryOptions: {
            enabled: !isLoading,
        },
        filters: [{
            field: "customer_id",
            operator: "eq",
            value: customerId
        }],
        pagination: {
            pageSize: 30,
            mode: "server",
        },
        onSearch: (searchText) => {
            if (!searchText) {
                return [{
                    field: "name",
                    operator: "ne",
                    value: "",
                }];
            }
            return [{
                field: "name",
                operator: "contains",
                value: searchText,
            }];
        }
    });

    const emptyContact: IContact = {
        customer_id: customerId || '',
        customer_name: "",
        electronic_post: "",
        email: "",
        fax: "",
        id: "",
        name: `Add ${currentValue}`,
        telephone: ""
    }

    useEffect(() => {
        onSearch(value?.name || '')
    }, [value]);

    const optionsWithEmptyContact = options.length < 2 ? [...options, emptyContact] : options

    return (
        <>
            <Autocomplete {...autocompleteProps}
                          options={optionsWithEmptyContact}
                          renderInput={(params) => {
                              return <TextField {...params} label={label} variant="outlined" fullWidth/>
                          }}
                          getOptionLabel={(option: any) => {
                              if (!option) return "";
                              return typeof option === "string" ? option : (option.name ? `${option.name}, ${option.telephone}` : '')
                          }}
                          isOptionEqualToValue={(option, value) => {
                              if (!value?.id) {
                                  return option.telephone === value.telephone && option.name === value.name
                              }
                              return option.id === value.id
                          }}
                          onChange={(_, newValue) => {
                              if (typeof newValue === 'string') {
                                  toggleOpen(true)
                              } else if (newValue && !newValue.telephone) {
                                  setDialogValue({
                                      ...dialogValue,
                                      name: newValue.name?.replace(/^Add /, '') || '',
                                      telephone: ''
                                  })
                                  toggleOpen(true)
                              } else {
                                  onChange(newValue, false)
                              }
                              //handleSelected(newValue);
                          }}
                          value={value || null}
                          style={{marginTop: 16, marginBottom: 8}}
                          renderOption={(props, option) => {
                              const key = `${option.id || props.id}-${option.telephone}`
                              return (
                                  <li {...props} key={key}>
                                      <Grid container display="flex" alignItems="center">
                                          <Grid mr={1} item>
                                              <Person2/>
                                          </Grid>
                                          <Grid item xs>
                                              <Typography variant="body2" color="textPrimary">
                                                  {option.name}
                                              </Typography>
                                              <Typography variant="body2" color="textSecondary">
                                                  {option.telephone}
                                              </Typography>
                                          </Grid>
                                      </Grid>
                                  </li>
                              );
                          }}
                          onInputChange={(e, newInputValue, reason) => {
                              if (reason === 'clear') {
                                  setCurrentValue('');
                                  //inputChangeHandler('');
                              } else {
                                  setCurrentValue(newInputValue);
                                  //inputChangeHandler(newInputValue);
                              }
                              autocompleteProps.onInputChange(e, newInputValue, reason)
                          }}
            />
            <Dialog open={open} onClose={handleDialogClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Add a new contact</DialogTitle>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                value={dialogValue.name}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        name: event.target.value,
                                    })
                                }
                                label="Name"
                                type="text"
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                id="name"
                                value={dialogValue.telephone}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        telephone: event.target.value,
                                    })
                                }
                                label="Phone"
                                type="text"
                                variant="standard"
                            />
                            <FormControlLabel
                                label="Save contact"
                                control={
                                    <Checkbox value={saveContact} onChange={(e) => setSaveContact(e.currentTarget.checked)}/>
                                }
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleDialogClose}>Cancel</Button>
                        <Button variant="contained" color="success" type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
