import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, admin, dashboard, (list)

p, admin, orders, (list)
p, admin, orders/*, (edit)|(show)|(delete)

p, admin, routes, (list)

p, admin, customers, (list)
p, admin, customers/*, (edit)|(delete)|(sync)

p, admin, addresses, (list)

p, admin, products, (list)
p, admin, products/*, (sync)

p, admin, contacts, (list)
p, admin, contacts/*, (edit)|(delete)

p, admin, discount_rules, (list)|(create)
p, admin, discount_rules/*, (edit)|(delete)

p, admin, telegrams, (list)|(create)
p, admin, telegrams/*, (edit)|(delete)

p, admin, timeslots, (list)
p, admin, timeslots/*, (edit)|(delete)

p, admin, trucks, (list)|(create)
p, admin, trucks/*, (edit)|(delete)

p, admin, warehouses, (list)|(create)
p, admin, warehouses/*, (edit)|(delete)

p, admin, expenses, (list)|(create)
p, admin, expenses/*, (delete)

p, admin, reports, (list)

p, admin, shipox_tokens, (list)
p, admin, shipox_tokens/*, (edit)|(delete)

p, admin, users, (list)|(create)
p, admin, users/*, (edit)|(delete)

p, admin, settings, (list)|(create)
p, admin, settings/*, (edit)|(delete)



p, driver, dashboard, (list)

p, driver, orders, (list)
p, driver, orders/*, (edit)|(show)

p, driver, customers, (list)

p, driver, addresses, (list)

p, driver, contacts, (list)

p, driver, products, (list)

p, driver, trucks, (list)

p, driver, warehouses, (list)

p, driver, expenses, (list)|(create)

p, driver, reports, (list)

`);