import React, {useEffect, useMemo, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {useGetDrivers} from "hooks/useGetDrivers";
import {Link, useLocation} from "react-router-dom";
import {ExpandMore, OpenInNew} from "@mui/icons-material";
import {useCreate, useTranslate} from "@refinedev/core";
import {calculateDistanceMatrix, getRoutesSimple, routes} from "../../helpers/maps";
import {GOOGLE_API_KEY} from "../../constants";
import {getPoints} from "../../helpers/utils";

interface IRoutesStepTwoProps {
  setStep: any;
  currentRoutes: any[];
  selectedOrigin: any;
}
interface TruckRoute {
  truckName: string;
  mapUrl: string;
  mapLink: string;
}


export const RoutesStepTwo: React.FC<IRoutesStepTwoProps> = ({
                                                               setStep,
                                                               currentRoutes,
                                                               selectedOrigin,
                                                             }) => {
  const translate = useTranslate();

  const [driversEnabled, setDriversEnabled] = useState<boolean>(false);
  const [trucksDrivers, setTrucksDrivers] = useState<any>([]);
  const [truckRoutes, setTruckRoutes] = useState<TruckRoute[]>();


  const trucks = currentRoutes
    .reduce((acc: any, route: any) => {
      const truckExists = acc.some(
        (truckObj: any) => truckObj.name === route.truck.name
      );

      if (!truckExists) {
        acc.push({
          id: route.truck.id,
          name: route.truck.name,
          capacity: route.truck.capacity,
        });
      }
      return acc;
    }, [])
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
    .map((truckObj: any, index: number) => {
      const totalWeight = currentRoutes.reduce((acc: number, route: any) => {
        if (route.truck.id === truckObj.id) {
          acc += route.weight;
        }
        return acc;
      }, 0);

      return {
        id: index,
        truck: {
          id: truckObj.id,
          name: truckObj.name,
          capacity: truckObj.capacity,
          totalWeight: totalWeight.toFixed(2),
        },
        driver: {},
      };
    });

  const getMapPoints = (orders: any) => {
    if (!selectedOrigin) {
      return ''
    }
    const queryParams = [
      `key=${GOOGLE_API_KEY}`,
      `origin=${selectedOrigin?.address.lat},${selectedOrigin?.address.lng}`
    ]
    let points: string[] = [];
    let lastPoint = ''
    orders.forEach((order: any, i: number) => {
      if (i === orders.length -1) {
        lastPoint = `${order.address.lat},${order.address.lng}`
      } else {
        points.push(`${order.address.lat},${order.address.lng}`);
      }

    });

    if (points.length) {
      queryParams.push(`waypoints=${points.join('|')}`)
    }
    queryParams.push(`destination=${lastPoint}`)
    queryParams.push(`mode=driving`)
    return `${window.location.protocol}//www.google.com/maps/embed/v1/directions?${queryParams.join('&')}`;
  };
  const getMapLink = (orders: any) => {
    let points = `'${selectedOrigin?.address.lat},${selectedOrigin?.address.lng}'`;
    orders.forEach((order: any) => {
      points += `/'${order.address.lat},${order.address.lng}'`;
    });
    return `https://www.google.com.mx/maps/dir/${points}`;
  };

  const loadMap = async () => {
    const { points, places } = getPoints(selectedOrigin, currentRoutes?.filter((order) => !!order.address));
    try{
      const sortedOrders = await calculateDistanceMatrix(points, places);
      const newTruckRoutes: TruckRoute[] = trucks.map((truck: any) => {
        const truckOrders = sortedOrders.filter((order: any) => order?.truck?.id === truck?.truck?.id)
        console.log('truck: ', truck?.truck, truckOrders)
        return {
          truckName: truck?.truck?.name,
          mapUrl: getMapPoints(truckOrders),
          mapLink: getMapLink(truckOrders)
        }
      })
      if (newTruckRoutes.length) {
        setTruckRoutes(newTruckRoutes)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    loadMap()
  }, []);
  //window.google.maps

  useEffect(() => {
    if (trucks.length > 0 && trucksDrivers.length === 0) {
      setTrucksDrivers(trucks);
    }
  }, [trucks, trucksDrivers.length]);

  const {data: driversData} = useGetDrivers({
    enabled: driversEnabled,
  });

  useEffect(() => {
    setDriversEnabled(true);
  }, []);

  const columnsDrivers = useMemo<GridColumns<any>>(
    () => [
      {
        field: "truck",
        headerName: "Truck",
        minWidth: 300,
        renderCell: function render({value}: any) {
          return (
            <div>
              <div>Name: {value.name}</div>
              <div>Capacity: {value.capacity} KG</div>
              <div>Total Orders Weight: {value.totalWeight} KG</div>
            </div>
          );
        },
      },
      {
        field: "driver",
        headerName: "Driver",
        minWidth: 150,
        renderCell: ({row}: any) => {
          return (
            <TextField
              value={row.driver}
              onChange={(e) => {
                const driverId = e.target.value;
                const newTrucksDrivers = trucksDrivers.map(
                  (truckDriver: any) => {
                    if (truckDriver.id === row.id) {
                      truckDriver.driver = driverId;
                    }
                    return truckDriver;
                  }
                );
                setTrucksDrivers(newTrucksDrivers);
              }}
              select
              label="Driver"
              style={{marginTop: 16, marginBottom: 8, width: 300}}
            >
              {driversData?.map((driver: any, index: number) => (
                <MenuItem value={driver.id} key={index}>
                  {driver.name || driver.username}
                </MenuItem>
              ))}
            </TextField>
          );
        },
      },
    ],
    [driversData, trucksDrivers]
  );

  const columnsRoutes = [
    {field: "id", headerName: "ID", minWidth: 130},
    {
      field: "truck",
      headerName: "Truck",
      minWidth: 150,
      renderCell: function render({value}: any) {
        return (
          <div>
            <div>Name: {value.name}</div>
            <div>Capacity: {value.capacity} KG</div>
          </div>
        );
      },
    },
    {
      field: "delivery_date",
      headerName: "Delivery Date",
      minWidth: 130,
    },
    {
      field: "delivery_time",
      headerName: "Delivery Time",
      minWidth: 130,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 130,
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      minWidth: 130,
    },
    {
      field: "telephone",
      headerName: "Telephone",
      minWidth: 130,
    },
    {
      field: "shipping_address",
      headerName: "Shipping Address",
      minWidth: 200,
      valueGetter: (params: any) => {
        const shippingAddress = JSON.parse(params.row.shipping_address);
        return `${shippingAddress.street} ${shippingAddress.city} ${shippingAddress.province} ${shippingAddress.country}`;
      },
    },
    {
      field: "shipping_weight",
      headerName: "Weight",
    },
  ];

  const {mutate} = useCreate();

  const handleSave = async () => {
    const routes = currentRoutes.map((route: any) => {
      const truckDriver = trucksDrivers.find(
        (truckDriver: any) => truckDriver.truck.id === route.truck.id
      );
      return {
        id: route.id,
        truck_id: route.truck.id,
        driver_id: truckDriver.driver,
      };
    });
    mutate(
      {
        resource: "orders/v2/assign",
        values: {
          data: routes,
        },
      },
      {
        onSuccess: () => {
          "success";
        },
      }
    );
  };

  let mapGridSize = 4
  if (truckRoutes?.length === 1) {
    mapGridSize = 12
  } else if(truckRoutes?.length === 2) {
    mapGridSize = 6
  }

  return (
    <>
      <Link
        to="#"
        onClick={() => {
          setStep(1);
        }}
      >
        Back
      </Link>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Routes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            rows={currentRoutes}
            columns={columnsRoutes}
            autoHeight
            getRowHeight={() => "auto"}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeader": {
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                maxHeight: "168px !important",
              },
            }}
            hideFooter
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Assign Drivers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            rows={trucksDrivers}
            columns={columnsDrivers}
            autoHeight
            getRowHeight={() => "auto"}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                // Forced to use important since overriding inline styles
                maxHeight: "168px !important",
              },
            }}
            hideFooter
          />
          <Button
            variant="contained"
            onClick={handleSave}
            style={{
              marginTop: 16,
            }}
          >
            Assign drivers
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Map</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {truckRoutes?.map(route =>
              <Grid item xs={12} sm={mapGridSize > 6 ? mapGridSize : 6} md={mapGridSize > 4 ? mapGridSize : 4}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h6">{route.truckName}</Typography>
                  <Button href={route.mapLink} target="_blank"><OpenInNew sx={{mr:1}}/> {translate("buttons.open", "Open")}</Button>
                </Box>
                <iframe src={route.mapUrl} height={Math.max(window.innerHeight * 0.3 , 250)} width="100%"/>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
