import {HttpError, useTranslate} from "@refinedev/core";
import {SaveButton} from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";

import {UseModalFormReturnType} from "@refinedev/react-hook-form";

import {IExpense, IPayment, IUser} from "interfaces";
import {Controller} from "react-hook-form";
import {EditFieldManager} from "../../../components/edit/fields";
import {useState} from "react";
import {getCurrentDate} from "../../../helpers/utils";


export const CreateExpenseModal: React.FC<UseModalFormReturnType<IExpense, HttpError, IExpense>> = ({
                                                                                                      saveButtonProps,
                                                                                                      refineCore: {onFinish},
                                                                                                      modal: {visible, close, title},
                                                                                                      register,
                                                                                                      control,
                                                                                                      handleSubmit,
                                                                                                      formState: {errors},
                                                                                                    }) => {

  const [unsavedRecord, setUnsavedRecord] = useState<Partial<IExpense>>({});

  const translate = useTranslate();
  const expenses = [
    {id: "rifornimento", label: "Rifornimento"},
    {id: "pedaggi", label: "Pedaggi"},
    {id: "cibo", label: "Cibo"},
    {id: "compensi", label: "Compensi"},
    {id: "altro", label: "Altro"},
  ].map(({id, label}) => ({id, label: translate(`expenses.expense.${id}`, label)}))

  const defaultDate = getCurrentDate()

  //translate("expenses.fields.status", "Status")
  const fieldManager = new EditFieldManager(control, register, unsavedRecord, errors, (value) => {
    setUnsavedRecord(value);
  });


  //{fieldManager.dateField("date", "Date", new Date())}

  const onSubmit = (data: any) => {
    onFinish({
      ...data,
      date: data.date || defaultDate,
      amount: data.amount ? data.amount : 0,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{sx: {minWidth: 500}}}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{display: "flex", flexDirection: "column"}}
        >
          {fieldManager.selectField("expense", "Expense", expenses.map(item => ({value: item.id, label: item.label})), {required: true} )}
          {unsavedRecord.expense === 'altro' && fieldManager.textField("expense_description", "Expense", {required: true})}
          {fieldManager.dateField("date", "Date", defaultDate)}
          {fieldManager.numberField("amount", "Amount", {min: 0.01, required: true})}
          {fieldManager.autocompleteField<IUser>(
              "driver_id",
              translate("orders.fields.driver", "Driver"),
              "users",
              "id",
              "name",
              [
                {
                  field: "driver_role",
                  operator: "startswith",
                  value: "Driver",
                },
              ]
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)}/>
      </DialogActions>
    </Dialog>
  );
};
