import React, {useState} from "react";
import {useDataGrid, List, EditButton, DeleteButton} from "@refinedev/mui";
import {
    DataGrid,
    GridColumns,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {useModalForm} from "@refinedev/react-hook-form";
import {IProduct} from "interfaces";
import {CanAccess, CrudFilter, HttpError, useCreate} from "@refinedev/core";
import {Button, Link} from "@mui/material";
import {EditProductModal} from "./components/editModal";
import {Sync} from "@mui/icons-material";
import CustomToolbar from "../../components/list/CustomToolbar";
import {SearchField} from "../../components/SearchField";

export const ProductList = () => {

    const [searchQuery, setSearchQuery] = useState("");
    const filters: CrudFilter[] = []
    if (searchQuery) {
        filters.push({
            field: "q",
            operator: "eq",
            value: searchQuery,
        });
    }

    const {
        dataGridProps,
        tableQueryResult: {refetch},
    } = useDataGrid({
        sorters: {
            initial: [
                {
                    field: "code",
                    order: "desc",
                },
            ],
        },
        filters: {
            permanent: filters,
        },
    });

    const editModalFormProps = useModalForm<IProduct, HttpError, IProduct>({
        refineCoreProps: {
            action: "edit",
            meta: {method: "put"},
            onMutationSuccess: () => {
                closeEditModal();
            },
        },
        modalProps: {
            autoResetForm: true,
        },
    });

    const {
        modal: {show: showEditModal, close: closeEditModal},
    } = editModalFormProps;

    const {mutate} = useCreate();

    const filterOperators = getGridStringOperators().filter(
        (operator) => operator.value === "contains"
    );

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "id",
                headerName: "Id",
                filterOperators: filterOperators,
                renderCell(params) {
                    return (
                        <Link
                            onClick={() => showEditModal(params.id)}
                            style={{cursor: "pointer"}}
                        >
                            {params.id}
                        </Link>
                    );
                },
            },
            {
                field: "fatture_id",
                headerName: "Fatture",
                filterOperators: filterOperators,
            },
            {
                field: "code",
                headerName: "Code",
                filterOperators: filterOperators,
            },
            {
                field: "name",
                headerName: "Name",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "shop_name",
                headerName: "Shop Name",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "fatture_category",
                headerName: "Fatture Category",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "abbreviation",
                headerName: "Abbreviation",
                filterOperators: filterOperators,
                minWidth: 150,
            },
            {
                field: "net_price",
                headerName: "Net Price",
                filterOperators: filterOperators,
            },
            {
                field: "weight_kg",
                headerName: "Weight Kg",
                filterOperators: filterOperators,
            },
            {
                field: "length",
                headerName: "Length",
                filterOperators: filterOperators,
            },
            {
                field: "width",
                headerName: "Width",
                filterOperators: filterOperators,
            },
            {
                field: "height",
                headerName: "Height",
                filterOperators: filterOperators,
            },
            {
                field: "step",
                headerName: "Step",
                filterOperators: filterOperators,
            },
            {
                field: "shop_order",
                headerName: "Shop Order",
                filterOperators: filterOperators,
            },
            {
                field: "current_stock",
                headerName: "Current Stock",
                filterOperators: filterOperators,
            },
            {
                field: "shop_enabled",
                headerName: "Shop Enabled",
                type: "boolean",
            },
            {
                field: "fatture_enabled",
                headerName: "Fatture Enabled",
                type: "boolean",
            },
            {
                field: "actions",
                headerName: "Actions",
                renderCell: function render({row}) {
                    return (
                        <>
                            <EditButton hideText onClick={() => showEditModal(row.id)}/>
                            <DeleteButton hideText recordItemId={row.id}/>
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                sortable: false,
                filterable: false,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const syncProducts = () => {
        mutate(
            {
                resource: "admin/sync/products",
                values: {
                    syncme: true,
                },
            },
            {
                onSuccess: () => {
                    refetch();
                },
            }
        );
    };

    return (
        <>
            <List
                headerButtons={({defaultButtons}) => {
                    return (
                        <>
                            <SearchField size="small" value={searchQuery} onChange={(value) => setSearchQuery(value)}/>
                            {defaultButtons}
                            <CanAccess resource="products" action="sync">
                                <Button
                                    variant="contained"
                                    onClick={() => syncProducts()}
                                    startIcon={<Sync/>}
                                >
                                    Sync with Fatture in Cloud
                                </Button>
                            </CanAccess>
                        </>
                    );
                }}
            >
                <DataGrid
                    {...dataGridProps}
                    components={{Toolbar: CustomToolbar}}
                    columns={columns}
                    autoHeight
                    getRowHeight={() => "auto"}
                    // onRowClick={(params) => {
                    //   console.log("params", params);
                    // }}
                />
            </List>
            <EditProductModal {...editModalFormProps} />
        </>
    );
};
