import React from "react";
import { useDataGrid, List, EditButton, DeleteButton } from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { useModalForm } from "@refinedev/react-hook-form";
import { IWarehouse } from "interfaces";
import { CanAccess, HttpError } from "@refinedev/core";
import { Button, Link } from "@mui/material";
import { EditWarehouseModal } from "./components/editModal";
import { AddBoxOutlined } from "@mui/icons-material";
import { CreateWarehouseModal } from "./components/createModal";

export const WarehouseList = () => {
  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  });

  const editModalFormProps = useModalForm<IWarehouse, HttpError, IWarehouse>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const createModalFormProps = useModalForm<IWarehouse, HttpError, IWarehouse>({
    refineCoreProps: {
      action: "create",
      onMutationSuccess: () => {
        closeCreateModal();
        resetCreate();
      },
    },
  });

  const {
    modal: { show: showCreateModal, close: closeCreateModal },
    reset: resetCreate,
  } = createModalFormProps;

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        flex: 1,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "address",
        headerName: "Address",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "hour_start",
        headerName: "Hour Start",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "hour_end",
        headerName: "Hour End",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <CanAccess resource="warehouses" action="create">
                <Button
                  variant="contained"
                  onClick={() => showCreateModal()}
                  startIcon={<AddBoxOutlined />}
                >
                  Create
                </Button>
              </CanAccess>
            </>
          );
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
      </List>
      <EditWarehouseModal {...editModalFormProps} />
      <CreateWarehouseModal {...createModalFormProps} />
    </>
  );
};
