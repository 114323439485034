import React, {useState} from "react";
import {SaveButton, useDataGrid} from "@refinedev/mui";
import {
    DataGrid,
    GridColumns,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
    useModalForm,
    UseModalFormReturnType,
} from "@refinedev/react-hook-form";
import {IPayment, IUser} from "interfaces";
import {
    CreateResponse,
    HttpError,
    Option,
    useLink,
    useSelect,
    useTranslate,
} from "@refinedev/core";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton,
    Theme,
    Typography,
    useMediaQuery, useTheme,
} from "@mui/material";
import {EditFieldManager} from "components/edit/fields";
import {NewCrudColumn} from "components/edit/crudControls";
import {ShowCurrency, ShowDate} from "helpers/fields/show";
import {paymentStautsOptions} from "../enums";
import {getCurrentDate} from "../../../helpers/utils";
import {Add} from "@mui/icons-material";

export interface OrderPaymentsProps {
    edit?: boolean;
    orderId?: string;
    totalAmount?: number;
    paymentBalance?: number;
    defaultDate?: string
    isLoading: boolean;
}

interface AddPaymentModalProps {
    orderId?: string;
    defaultAmount?: number;
    defaultDate?: string;
}

export const AddPaymentModal: React.FC<
    UseModalFormReturnType<IPayment, HttpError, IPayment> & AddPaymentModalProps
> = ({
         saveButtonProps,
         refineCore: {queryResult, onFinish},
         modal: {visible, close, title},
         register,
         control,
         handleSubmit,
         formState: {errors},
         orderId,
         defaultAmount,
         defaultDate = getCurrentDate(),
     }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const record = queryResult?.data?.data;
    const [unsavedRecord, setUnsavedRecord] = useState<Partial<IPayment>>({});


    const translate = useTranslate();

    const statusOptions = paymentStautsOptions.filter(p => !p.hidden).map(
        ({value, translationKey, description}) => ({
            label: translate(translationKey, description),
            value: value,
        })
    );

    const onSubmit = (data: IPayment) => {
        if (!orderId) {
            return;
        }
        const currentData = queryResult?.data?.data;

        if (!data.amount) {
            data.amount = defaultAmount ? defaultAmount.toString() : "0";
        }

        onFinish({
            ...currentData,
            ...data,
            date: data.date || defaultDate,
            order_id: orderId,
        });
    };

    const fieldManager = new EditFieldManager(
        control,
        register,
        record,
        errors,
        (value) => {
            setUnsavedRecord(value);
        }
    );


    return (
        <Dialog
            open={visible}
            onClose={close}
            fullScreen={isMobile}
            maxWidth={"sm"}
            PaperProps={{sx: {minWidth: 320}}}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    autoComplete="off"
                    sx={{display: "flex", flexDirection: "column"}}
                >
                    {fieldManager.selectField("status", "Status", statusOptions, {required: true, defaultValue: "Payed in cash"})}
                    {unsavedRecord.status !== "Insoluto"
                        ? fieldManager.numberField("amount", "Amount", {
                            defaultValue: defaultAmount,
                            required: true,
                            min: 0,
                            max: defaultAmount || 0
                        })
                        : ""}
                    {typeof defaultAmount !== 'undefined' && (
                        <ShowCurrency
                            label="Order amount"
                            value={defaultAmount}
                            box={{flexGrow: 1}}
                        />
                    )}
                    {fieldManager.autocompleteField<IUser>(
                        "user_id",
                        translate("orders.fields.driver", "Driver"),
                        "users",
                        "id",
                        "name",
                        [
                            {
                                field: "driver_role",
                                operator: "startswith",
                                value: "Driver",
                            },
                        ]
                    )}
                    {fieldManager.dateField("date", "Data", defaultDate)}
                </Box>
            </DialogContent>
            <DialogActions sx={{mb: {xs: '48px', sm: 'undefined'} }}>
                <Button onClick={close}>Cancel</Button>
                <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)}/>
            </DialogActions>
        </Dialog>
    );
};

export const OrderPayments: React.FC<OrderPaymentsProps> = ({
                                                                orderId,
                                                                totalAmount,
                                                                paymentBalance,
                                                                isLoading,
                                                                defaultDate,
                                                                edit = false,
                                                            }) => {
    const {dataGridProps} = useDataGrid({
        resource: "payments",
        filters: {
            permanent: [
                {
                    field: "order_id",
                    operator: "eq",
                    value: orderId,
                },
            ],
        },
    });

    const {
        options,
        queryResult: {isLoading: isUserLoading},
    } = useSelect<IUser>({
        resource: "users",
        optionValue: "id",
        optionLabel: "username",
        hasPagination: false,
    });

    const Link = useLink();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const defaultMinWidth = 100;

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "status",
                headerName: "Status",
                type: "string",
                flex: 1,
                minWidth: defaultMinWidth,
            },
            {
                field: "amount",
                headerName: "Amount",
                type: "number",
                flex: 1,
                minWidth: defaultMinWidth,
                renderCell: function render({value}) {
                    return <span>€ {parseFloat(value || 0).toFixed(2)}</span>;
                },
            },
            {
                field: "user_id",
                headerName: "User",
                type: "singleSelect",
                headerAlign: "left",
                align: "left",
                minWidth: isMobile ? 150 : 250,
                flex: 0.5,
                valueOptions: options,
                valueFormatter: (params: GridValueFormatterParams<Option>) => {
                    return params.value;
                },
                renderCell: function render({row}) {
                    if (isUserLoading) {
                        return "Loading...";
                    }
                    const user = options.find(
                        (item) => item.value?.toString() === row?.user_id?.toString()
                    );
                    return user ? (
                        <Link
                            to={`/users/?filters[0][field]=id&filters[0][value]=${user.value}&filters[0][operator]=eq`}
                        >
                            {user.label}
                        </Link>
                    ) : (
                        ""
                    );
                },
            },
            {
                field: "created_at",
                headerName: "Created at",
                type: "dateTime",
                flex: 1,
                minWidth: defaultMinWidth,
                renderCell: ({row}) => {
                    return <ShowDate value={row.created_at} color="textPrimary"/>;
                },
            },
            {
                field: "date",
                headerName: "Payment date",
                type: "dateTime",
                flex: 1,
                minWidth: defaultMinWidth,
                renderCell: ({row}) => {
                    return <ShowDate value={row.date} format="YYYY-MM-DD" color="textPrimary"/>;
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, orderId, edit, options]
    );

    const createModalFormProps = useModalForm<IPayment, HttpError, IPayment>({
        refineCoreProps: {
            action: "create",
            meta: {method: "post"},
            resource: "payments",
            onMutationSuccess: (data) => {
                createModal.close();
                createModalFormProps.reset();
                window.dispatchEvent(new CustomEvent('payment:created', {detail: {id: data?.data?.id}}))
            },
        },
        modalProps: {
            autoResetForm: true,
        },
    });

    const {modal: createModal} = createModalFormProps;


    const crudColumn = NewCrudColumn<IPayment>("payments", createModal, null);
    if (edit) {
        columns.push(crudColumn);
    }

    if (!orderId) {
        return <></>;
    }

    return (
        <Box sx={{maxWidth: "90vw"}}>
            <Box
                p={1}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" color="primary">
                    Payments
                </Typography>
                <Box>
                    <IconButton color="primary" onClick={() => createModal.show()}><Add/></IconButton>
                </Box>
            </Box>
            <DataGrid
                {...dataGridProps}
                columns={columns}
                autoHeight
                getRowHeight={() => (isMobile ? 50 : "auto")}
                // onRowClick={(params) => {
                //
                // }}
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        // Forced to use important since overriding inline styles
                        height: "unset !important",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        // Forced to use important since overriding inline styles
                        maxHeight: "168px !important",
                    },
                }}
            />
            {createModalFormProps?.modal?.visible && (
                <AddPaymentModal
                    {...createModalFormProps}
                    orderId={orderId}
                    defaultAmount={paymentBalance||totalAmount}
                    defaultDate={defaultDate}
                />
            )}
        </Box>
    );
};
