import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { ITruck } from "interfaces";
import { Controller } from "react-hook-form";

export const CreateTruckModal: React.FC<
  UseModalFormReturnType<ITruck, HttpError, ITruck>
> = ({
  saveButtonProps,
  refineCore: { onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const onSubmit = (data: any) => {
    onFinish({
      ...data,
      capacity: data.capacity ? data.capacity : 0,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            margin="normal"
            fullWidth
            label="Name"
            autoFocus
          />

          <TextField
            {...register("capacity")}
            error={!!errors.capacity}
            helperText={errors.capacity?.message}
            margin="normal"
            label="Capacity"
            type="number"
            defaultValue={"0"}
          />

          <TextField
            {...register("qty")}
            error={!!errors.qty}
            helperText={errors.qty?.message}
            margin="normal"
            label="Qty"
            type="number"
            defaultValue={"0"}
          />
          <Controller
            control={control}
            name="is_available"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Is Available"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
