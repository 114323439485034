import React from "react";
import { useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { IOrder } from "interfaces";
import { useLink, useTranslate } from "@refinedev/core";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { ShowDate } from "helpers/fields/show";
import { Warning } from "@mui/icons-material";

export interface OrderInsolutiProps {
  edit?: boolean;
  orderId?: string;
  customerId?: string;
  isLoading: boolean;
}

export const OrderInsoluti: React.FC<OrderInsolutiProps> = ({
  orderId,
  customerId,
  isLoading,
  edit = false,
}) => {
  const { dataGridProps } = useDataGrid<IOrder>({
    resource: "orders",
    filters: {
      permanent: [
        /*{
          field: "id",
          operator: "ne",
          value: orderId,
        },*/
        {
          field: "customer_id",
          operator: "eq",
          value: customerId,
        },
        {
          field: "payment_balance",
          operator: "gt",
          value: 0,
        },
        {
          field: "status",
          operator: "ne",
          value: 'cancelled',
        },
      ],
    },
  });

  const translate = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const defaultMinWidth = 100;

  const Link = useLink();

  const columns = React.useMemo<GridColumns<IOrder>>(
    () => [
      {
        field: "id",
        headerName: translate("orders.fields.id", "Id"),
        type: "string",
        flex: 1,
        minWidth: defaultMinWidth,
        renderCell: function render({ value }) {
          return <Link to={`/orders/show/${value}`}>{value}</Link>;
        },
      },
      {
        field: "status",
        headerName: translate("orders.fields.status", "Status"),
        type: "string",
        flex: 1,
        minWidth: defaultMinWidth,
        renderCell: function render({ value }) {
          return translate(`orders.status.${value}`, value);
        },
      },
      {
        field: "total_amount",
        headerName: translate("orders.fields.total_amount", "Total amount"),
        type: "number",
        flex: 1,
        minWidth: defaultMinWidth,
        renderCell: function render({ value }) {
          return <span>€ {parseFloat(value || 0).toFixed(2)}</span>;
        },
      },
      {
        field: "last_payment_amount",
        headerName: translate(
          "orders.fields.last_payment_amount",
          "Last payment amount"
        ),
        type: "number",
        flex: 1,
        minWidth: defaultMinWidth,
        renderCell: function render({ value }) {
          return <span>€ {parseFloat(value || 0).toFixed(2)}</span>;
        },
      },
      {
        field: "not_payed_amount",
        headerName: translate(
          "orders.fields.not_payed_amount",
          "Amount not payed"
        ),
        type: "number",
        flex: 1,
        minWidth: defaultMinWidth,
        renderCell: function render({ value, row }) {
          return (
            <span>
              €{" "}
              {(
                (parseFloat(row.payment_balance||'0.0') || 0)
              ).toFixed(2)}
            </span>
          );
        },
      },
      {
        field: "delivery_date",
        headerName: translate("orders.fields.delivery_date", "Delivery date"),
        type: "date",
        flex: 1,
        minWidth: defaultMinWidth,
        renderCell: ({ row }) => {
          return (
            <ShowDate
              value={row.delivery_date}
              color="textPrimary"
              format="YYYY-MM-DD"
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, orderId, edit]
  );

  const totalUnpaidAmount = dataGridProps.rows
    .map(
      (row: IOrder) =>
        parseFloat(row.payment_balance?.toString() || "0.0")
    )
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);


  dataGridProps.rows = dataGridProps.rows.filter(
    (row: IOrder) => row.id !== orderId
  );

  if (!orderId || !customerId || !dataGridProps.rows.length) {
    return <></>;
  }

  return (
    <Box>
      <Box
        p={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h6" color="error">
          <Warning sx={{ transform: "translateY(5px)" }} /> Insoluti
        </Typography>
        <Typography variant="h6" color="error">
          € {totalUnpaidAmount.toFixed(2)}
        </Typography>
      </Box>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowHeight={() => (isMobile ? 50 : "auto")}
        density="standard"
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important",
          },
        }}
      />
    </Box>
  );
};
