import {InputAdornment, InputLabel, IconButton, FilledInput, MenuItem, TextField} from "@mui/material";
import {FormControl} from "@mui/material";
import {Search} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import useDebounce from "../hooks/useDebounce";
import {useSelect} from "@refinedev/core";
import {IUser} from "../interfaces";

export interface DriverSelectFieldProps {
    label?: string
    placeholder?: string
    size?: "small" | "medium"
    value: string
    onChange: (value: string) => void
}

export const DriverSelectField = ({label = 'Driver', placeholder="Select a driver", value, onChange, size = "medium"}: DriverSelectFieldProps) => {

    const {options: usersList} = useSelect<IUser>({
        resource: "users",
        optionValue: "id",
        optionLabel: "username",
        hasPagination: false,
        filters: [
            {
                field: "driver_role",
                operator: "eq",
                value: "driver",
            },
        ],
    });

    return (
        <TextField
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            select
            label={label}
            placeholder={placeholder}
            style={{marginTop: 0, marginBottom: 8}}
            size={size}
            fullWidth
        >
            <MenuItem value={"all"}>All</MenuItem>
            {usersList?.map((driver) => (
                <MenuItem value={driver.value} key={driver.value}>
                    {driver.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
