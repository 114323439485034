import { useEffect, useState } from "react";
import { HttpError, useMany } from "@refinedev/core";
import { SaveButton, useAutocomplete } from "@refinedev/mui";

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { ICustomer } from "interfaces";
import { Controller } from "react-hook-form";

export const EditProductModal: React.FC<
  UseModalFormReturnType<ICustomer, HttpError, ICustomer>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const currentData = queryResult?.data?.data;

  const [discountRule, setDiscountRule] = useState<any>([]);

  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
      height: data.height ? data.height : 0,
      length: data.length ? data.length : 0,
      step: data.step ? data.step : 0,
      shop_order: data.shop_order ? data.shop_order : null,
      current_discount: discountsRulesData?.data,
      new_discount: discountRule,
    });
  };

  const { autocompleteProps: autocompletePropsSegments } = useAutocomplete<any>(
    {
      resource: "segments",
      sorters: [
        {
          field: "name",
          order: "asc",
        },
      ],
      defaultValue: queryResult?.data?.data?.segment,
    }
  );

  const { data: discountsData, isLoading: discountsIsLoading } = useMany({
    resource: "discounts",
    ids: currentData?.id ? [currentData?.id] : [],
    metaData: {
      filterField: "customer_id",
    },
    queryOptions: {
      enabled: !!currentData?.id,
    },
  });

  const { data: discountsRulesData, isLoading: discountsRulesIsLoading } =
    useMany({
      resource: "discount_rules",
      ids: discountsData?.data
        ? discountsData?.data.map((item: any) => item?.discount_rule_id)
        : [],
      queryOptions: {
        enabled: !discountsIsLoading && !!discountsData,
      },
    });

  const { autocompleteProps: autocompletePropsDiscountsRules } =
    useAutocomplete<any>({
      resource: "discount_rules",
      sorters: [
        {
          field: "name",
          order: "asc",
        },
      ],
      queryOptions: {
        enabled: !!currentData?.id,
      },
      pagination: {
        pageSize: 30,
        mode: "server",
      },
      onSearch: (searchText) => {
        return [
          {
            field: "name",
            operator: "contains",
            value: searchText,
          },
        ];
      },
    });

  useEffect(() => {
    if (!discountsRulesIsLoading && !!discountsRulesData?.data && visible) {
      setDiscountRule(discountsRulesData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountsRulesIsLoading, discountsRulesData, visible]);

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("username")}
            error={!!errors.username}
            helperText={errors.username?.message}
            margin="normal"
            fullWidth
            label="Username"
            autoFocus
            defaultValue={" "}
          />
          <TextField
            {...register("custom_name")}
            error={!!errors.custom_name}
            helperText={errors.custom_name?.message}
            margin="normal"
            fullWidth
            label="Custom Name"
            autoFocus
            defaultValue={" "}
          />
          <Controller
            control={control}
            name="segment"
            rules={{ required: "El campo empleado es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...autocompletePropsSegments}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    const option =
                      autocompletePropsSegments?.options?.find(
                        (p) => p?.id === item
                      )?.name ?? "";

                    return option;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return value === undefined || option.id === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Segment"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                  )}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="is_active"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Is Active"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
          <Controller
            control={control}
            name="split_payment"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Split Payment"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
          <Controller
            control={control}
            name="show_fatture_doc"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Show Fatture Doc"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
          <Autocomplete
            {...autocompletePropsDiscountsRules}
            value={discountRule}
            onChange={(_, newValue) => {
              setDiscountRule(newValue);
            }}
            getOptionLabel={(item) => item.name}
            isOptionEqualToValue={(option, value) =>
              value === undefined ||
              option?.id?.toString() === (value?.id ?? value)?.toString()
            }
            placeholder="Select discount"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Discounts"
                margin="normal"
                variant="outlined"
                required
              />
            )}
            multiple
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  sx={{ bgcolor: "primary.main", color: "white" }}
                />
              ));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
