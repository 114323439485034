import React from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {DeleteButton, EditButton} from "@refinedev/mui";
import {UseModalFormReturnType} from "@refinedev/react-hook-form/dist/useModalForm";
import {BaseRecord, HttpError} from "@refinedev/core";

//
export interface CrudColumnReturnType<T extends BaseRecord> {
    crudColumn: GridEnrichedColDef<T>;
    createModalFormProps: UseModalFormReturnType<
        T,
        HttpError,
        T,
        {},
        T,
        T,
        HttpError
    >;
    editModalFormProps: UseModalFormReturnType<
        T,
        HttpError,
        T,
        {},
        T,
        T,
        HttpError
    >;
}

type Modal<T extends BaseRecord> = UseModalFormReturnType<
    T,
    HttpError,
    T,
    {},
    T,
    T,
    HttpError
>["modal"];

export const NewCrudColumn = <T extends BaseRecord>(
    resource: string,
    createModal: Modal<T> | null,
    editModal: Modal<T> | null,
    deleteDisabled = false
): GridEnrichedColDef<T> => {
    return {
        field: "actions",
        headerName: "",
        flex: 1,
        sortable: false,
        renderHeader: (params) => {
            return (
                <Box display="flex" justifyContent="flex-end" sx={{width: "100%", minWidth: '80px'}}>
                    {createModal && (
                        <Tooltip title="Create new">
                            <IconButton
                                color="inherit"
                                size="small"
                                aria-label="add"
                                onClick={() => {
                                    createModal.show();
                                }}
                            >
                                <Add fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            );
        },
        renderCell: function render({row, ...params}) {
            return (
                <Box display="flex" flexDirection="row">
                    {editModal && (
                        <EditButton
                            hideText
                            disabled={!row?.id}
                            onClick={() => {
                                editModal && editModal.show(row?.id);
                            }}
                        />
                    )}
                    <DeleteButton
                        hideText
                        disabled={!row?.id || deleteDisabled}
                        resource={resource}
                        recordItemId={row.id}
                    />
                </Box>
            );
        },
    };
};
