import {Box, Button, Chip, Grid, Paper, Theme, Typography, useMediaQuery} from "@mui/material";
import {useShow, useTranslate} from "@refinedev/core";
import React from "react";
import {ChipColors, IOrder} from "interfaces";
import {ShowBool, ShowDate, ShowField, ShowLink, ShowText} from "helpers/fields/show";
import {OrderItems} from "./tables/orderItems";
import {OrderPayments} from "./tables/orderPayments";
import {OrderInsoluti} from "./tables/orderInsoluti";
import ResponsiveShow from "components/show/ResponsiveShow";
import OrderTotals from "./totals";
import {Dangerous} from "@mui/icons-material";


export const ShowOrderPage: React.FC = () => {
  const {queryResult} = useShow<IOrder>();
  const {data, isLoading} = queryResult;
  const record: IOrder | undefined = data?.data;

  // {
  //           field: "order_id",
  //           operator: "eq",
  //           value: record?.id,
  //         },

  const paymentStates: {
    [key: string]: {
      color: ChipColors;
      label: string;
      value: number;
    }
  } = {
    'Insoluto': {color: 'error', label: 'Insoluto', value: (record?.total_amount || 0)},
    'Parziale': {color: 'warning', label: 'Parziale', value: (record?.total_amount || 0) - (record?.last_payment_amount || 0)},
    '': {color: 'success', label: 'Ok', value: 0},
  }
  let paymentState = paymentStates['']
  const paymentBalance = parseFloat(record?.payment_balance||'0.0')

  if (!isNaN(paymentBalance) && paymentBalance > 0 ) {
    paymentState = paymentStates['Insoluto']
  }

  const paymentStateField = <ShowField label="Insoluti">
    <Box><Chip sx={{width: '100%'}} variant="filled" color={paymentState.color} label={paymentState.value ? `€ ${paymentBalance.toFixed(2)}` : '-'}/></Box>
  </ShowField>

  const translate = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const orderStates: {
    [key: string]: {
      color: ChipColors;
      label: string;
    }
  } = {
    draft: {label: translate("orders.status.draft", "bozza"), color: 'secondary'},
    pending: {label: translate("orders.status.pending", "attesa"), color: 'warning'},
    confirmed: {label: translate("orders.status.confirmed", "confermato"), color: 'primary'},
    delivered: {label: translate("orders.status.delivered", "consegnato"), color: 'success'},
    cancelled: {label: translate("orders.status.cancelled", "cancellato"), color: 'error'},
    '': {label: '', color: 'primary'}
  }

  const orderState = orderStates[record?.status || ''] || orderStates['']
  const orderStateField = <ShowField label="Status">
    <Box><Chip variant="filled" color={orderState.color} label={orderState.label}/></Box>
  </ShowField>

  const customHeaderButtons = []

  if (['pending', 'confirmed'].indexOf(record?.status || '') !== -1) {
    customHeaderButtons.push(<Button color="error">Annulla ordine</Button>)
  }

  const linkFatture = record?.order_confirmation_token ? `https://compute.fattureincloud.it/doc/${record?.order_confirmation_token}.pdf` : undefined

  const infoGridProps = isMobile ? {
    display:"grid",
    sx: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  } : {}
  const infoBoxProps = isMobile ? {my:1}: {}
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ResponsiveShow isLoading={isLoading} customHeaderButtons={customHeaderButtons}>
          <Grid container spacing={3}>
            {(record?.payment_balance && parseFloat(record?.payment_balance) > 0) ? <Grid item xs={12} flexDirection="column">
              <Typography variant="h5" color="error"><Dangerous style={{transform: 'translateY(3px)'}} fontSize="medium"/> Ordine insoluto (€ {record?.payment_balance} da pagare)</Typography>
            </Grid> : <></>}
            <Grid item xs={12} md={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} flexDirection="column">
                  <Paper sx={{p: 2}}>
                    <Box display="flex" justifyContent="space-between">
                      <ShowText value={record?.id} label="ID"/>
                      {orderStateField}
                    </Box>
                    <Box>
                      <ShowLink label="Fatture" value={record?.fatture_id} href={linkFatture}/>
                      <ShowBool label="Update fatture" value={record?.update_fatture}/>
                      <ShowBool label="Show fatture doc" value={record?.show_fatture_doc}/>
                      <ShowBool label="Update Shipox" value={record?.update_shipox}/>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper sx={{p: 2}}>
                    <Box display="flex" flexDirection="column">
                      <ShowText value={record?.customer_name} label="Customer"/>
                      <ShowText value={record?.contact_name} label="Contact name"/>
                      <ShowText value={record?.telephone} label="Contact phone"/>
                      <ShowText value={`${record?.delivery_date} ${record?.delivery_time}`} label="Delivery date"/>
                      <ShowText value={record?.delivery_instructions} label="Delivery instructions"/>
                      <ShowText value={record?.internal_note} label="Internal note"/>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} lg={2} pt={3}>
              <Paper>
                <Grid container p={2}>
                  <Grid item xs={12} {...infoGridProps}>
                    <ShowDate value={record?.created_at} label="Created at" box={infoBoxProps}/>
                    <ShowDate value={record?.last_update} label="Last update" box={infoBoxProps} />
                    <ShowDate value={record?.confirmed_at} label="Confirmed at" box={infoBoxProps} />
                    <ShowText value={record?.confirmed_by} label="Confirmed by" box={infoBoxProps} />
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    {paymentStateField}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 0}}>
                <OrderInsoluti isLoading={queryResult?.isLoading || false} orderId={record?.id} customerId={record?.customer_id}/>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 0}}>
                <OrderItems isLoading={queryResult?.isLoading || false} orderId={record?.id}/>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 0}}>
                <OrderPayments isLoading={queryResult?.isLoading || false} orderId={record?.id} totalAmount={record?.total_amount} paymentBalance={parseFloat(record?.payment_balance||'0.0')}/>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 2}}>
                <OrderTotals record={record}/>
              </Paper>
            </Grid>
          </Grid>
        </ResponsiveShow>
      </Grid>
    </Grid>
  );
};
