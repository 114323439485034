import React from "react";
import {
  useDataGrid,
  List,
  DateField,
  EditButton,
  DeleteButton,
} from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { HttpError, useMany } from "@refinedev/core";
import { useModalForm } from "@refinedev/react-hook-form";
import { IShipoxToken } from "interfaces";
import { Link } from "@mui/material";
import { EditShipoxTokenModal } from "./components/editModal";

export const ShipoxTokenList = () => {
  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
  });

  const editModalFormProps = useModalForm<
    IShipoxToken,
    HttpError,
    IShipoxToken
  >({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const { data: usersData, isLoading: usersIsLoading } = useMany({
    resource: "users",
    ids:
      dataGridProps?.rows
        ?.filter((item) => !!item.user_id)
        ?.map((item: any) => {
          return item?.user_id;
        }) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "username",
        headerName: "Username",
        filterOperators: filterOperators,
        minWidth: 300,
      },
      {
        field: "password",
        headerName: "Password",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "date_token",
        headerName: "Date Token",
        minWidth: 100,
        renderCell: function render({ value }) {
          return <DateField value={value} format={"DD/MM/YYYY HH:mm"} />;
        },
      },
      {
        field: "shipox_id",
        headerName: "Shipox",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "customer_id",
        headerName: "Customer",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "user",
        headerName: "User",
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => {
          const value = row?.user_id;
          return value;
        },
        minWidth: 150,
        renderCell: function render({ value }) {
          if (usersIsLoading) return <>Loading...</>;
          return usersData?.data?.find((item) => item.id === value)?.username;
        },
      },
      {
        field: "token",
        headerName: "Token",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersData?.data, usersIsLoading]
  );

  return (
    <>
      <List>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
        />
      </List>
      <EditShipoxTokenModal {...editModalFormProps} />
    </>
  );
};
