import { TOKEN_KEY } from "../../constants";
import jwt_decode from "jwt-decode";

export const useGetRole = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const decoded: any = jwt_decode(token);
    if (decoded.permissions.includes("admin")) {
      return "admin";
    } else if (decoded.permissions.includes("driver")) {
      return "driver";
    } else if (decoded.permissions.includes("staff")) {
      return "staff";
    } else {
      return "customer";
    }
  }
};
