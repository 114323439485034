import React from "react";
import { useDataGrid, List, EditButton, DeleteButton } from "@refinedev/mui";
import {
  DataGridPro,
  GridColumns,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import { useModalForm } from "@refinedev/react-hook-form";
import { IUser } from "interfaces";
import { HttpError } from "@refinedev/core";
import { Button, Link } from "@mui/material";
import { EditUserModal } from "./components/editModal";
import { CreateUserModal } from "./components/createModal";
import { AddBoxOutlined, Password } from "@mui/icons-material";
import { PasswordUserModal } from "./components/passwordModal";

export const UserList = () => {
  const { dataGridProps } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
  });

  const editModalFormProps = useModalForm<IUser, HttpError, IUser>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const passwordModalFormProps = useModalForm<IUser, HttpError, IUser>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closePasswordModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showPasswordModal, close: closePasswordModal },
  } = passwordModalFormProps;

  const createModalFormProps = useModalForm<IUser, HttpError, IUser>({
    refineCoreProps: {
      action: "create",
      onMutationSuccess: () => {
        closeCreateModal();
        resetCreate();
      },
    },
  });

  const {
    modal: { show: showCreateModal, close: closeCreateModal },
    reset: resetCreate,
  } = createModalFormProps;

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const filterOperatorsDriverRole = getGridSingleSelectOperators().filter(
    (operator) => operator.value === "is"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        flex: 1,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "username",
        headerName: "Username",
        filterOperators: filterOperators,
        flex: 1,
      },
      {
        field: "is_active",
        headerName: "Active",
        flex: 1,
        type: "boolean",
      },
      {
        field: "is_superuser",
        headerName: "Superuser",
        flex: 1,
        type: "boolean",
      },
      {
        field: "driver_role",
        headerName: "Driver Role",
        flex: 1,
        type: "singleSelect",
        filterOperators: filterOperatorsDriverRole,
        valueOptions: [
          { value: "SuperDriver", label: "SuperDriver" },
          { value: "Driver Advance", label: "Driver Advance" },
          { value: "Driver Basic", label: "Driver Basic" },
          { value: "Guest Driver", label: "Guest Driver" },
        ],
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <Button
                onClick={() => showPasswordModal(row.id)}
                size="small"
                style={{ minWidth: "unset" }}
                title="Change Password"
              >
                <Password fontSize={"small"} />
              </Button>
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 140,
        sortable: false,
        filterable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <Button
                variant="contained"
                onClick={() => showCreateModal()}
                startIcon={<AddBoxOutlined />}
              >
                Create
              </Button>
            </>
          );
        }}
      >
        <DataGridPro
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
      </List>
      <EditUserModal {...editModalFormProps} />
      <CreateUserModal {...createModalFormProps} />
      <PasswordUserModal {...passwordModalFormProps} />
    </>
  );
};
