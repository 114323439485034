import React from "react";
import { useDataGrid, List, EditButton, DeleteButton } from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { useModalForm } from "@refinedev/react-hook-form";
import { ITimeslot } from "interfaces";
import { HttpError, useCreate } from "@refinedev/core";
import { Button, Link } from "@mui/material";
import { EditProductModal } from "./components/editModal";
import { Sync } from "@mui/icons-material";

export const TimeslotList = () => {
  const {
    dataGridProps,
    tableQueryResult: { refetch },
  } = useDataGrid({
    sorters: {
      initial: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  });

  const editModalFormProps = useModalForm<ITimeslot, HttpError, ITimeslot>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      onMutationSuccess: () => {
        closeEditModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const { mutate } = useCreate();

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        filterOperators: filterOperators,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "timeslot_id",
        headerName: "Timeslot",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "timeslot_availability_id",
        headerName: "Timeslot Availability",
        filterOperators: filterOperators,
        minWidth: 100,
      },
      {
        field: "name",
        headerName: "Name",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "description",
        headerName: "Description",
        filterOperators: filterOperators,
        minWidth: 200,
      },
      {
        field: "start_time",
        headerName: "Start Time",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "end_time",
        headerName: "End Time",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "cutoff_time",
        headerName: "Cutoff Time",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "capacity",
        headerName: "Capacity",
        filterOperators: filterOperators,
        minWidth: 100,
      },
      {
        field: "courier_type",
        headerName: "Courier Type",
        filterOperators: filterOperators,
        minWidth: 450,
      },
      {
        field: "is_active",
        headerName: "Is Active",
        type: "boolean",
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
        filterable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const syncProducts = () => {
    mutate(
      {
        resource: "admin/sync/timeslots",
        values: {
          syncme: true,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              {defaultButtons}
              <Button
                variant="contained"
                onClick={() => syncProducts()}
                startIcon={<Sync />}
              >
                Sync with shipox
              </Button>
            </>
          );
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
      </List>
      <EditProductModal {...editModalFormProps} />
    </>
  );
};
