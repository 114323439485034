import React from "react";
import {Box, BoxProps, Typography} from "@mui/material";
import {BooleanField, DateField as DateFieldComponent} from "@refinedev/mui";
import {TypographyProps} from "@mui/material/Typography/Typography";

type FieldProps<T> = {
  label?: string;
  box?: BoxProps;
  value?: T;
}

interface NumberFieldProps extends FieldProps<number> {
  prefix?: string
  suffix?: string
  decimals?: number
}
interface LinkFieldProps extends FieldProps<string> {
  href?: string
}

interface ShowFieldProps {
  label?: string|null;
  children: React.ReactNode;
  box?: BoxProps;
}

export const ShowField: React.FC<ShowFieldProps> = ({label=null, box, children}) => {
  return label ? (
    <Box sx={{minWidth: '100px'}} {...box}>
      <Typography variant="caption">{label}</Typography>
      {children}
    </Box>
  ) : <>{children}</>;
};
export const ShowText: React.FC<FieldProps<string>> = ({label, value, box}) => {
  return <ShowField label={label} box={box}><Typography variant="body2" color="primary" sx={{minHeight: '1.43rem'}}>{value || ''}</Typography></ShowField>;
};
export const ShowLink: React.FC<LinkFieldProps> = ({label, value, href, box}) => {
  return <ShowField label={label} box={box}><Typography variant="body2" color="primary" sx={{minHeight: '1.43rem'}}>{ href? <a href={href} target="_blank">{value||href}</a> : value || ''}</Typography></ShowField>;
};
export const ShowDate: React.FC<FieldProps<string> & {color?: TypographyProps['color'], format?: string}> = ({label=null, value, box, color='primary', format='YYYY-MM-DD HH:mm'}) => {
  return <ShowField label={label} box={box}>{value ? <DateFieldComponent value={value} format={format} variant="body2" color={color}/> :
    <Typography variant="body2" color={color}>{'-'}</Typography>}</ShowField>;
};
export const ShowBool: React.FC<FieldProps<boolean>> = ({label, value, box}) => {
  return <ShowField label={label} box={box}><Box><BooleanField value={value}/></Box></ShowField>
};
export const ShowNumber: React.FC<NumberFieldProps> = ({label, value, box, prefix, suffix, decimals = 2}) => {
  return <ShowField label={label} box={box}><Box><Typography variant="body2"
                                                             color="primary">{prefix && `${prefix} `}{value ? value.toFixed(decimals) : '-'}{suffix}</Typography></Box></ShowField>
};
export const ShowCurrency: React.FC<FieldProps<number>> = ({label, value, box}) => {
  return <ShowNumber label={label} box={box} value={value} prefix={"€"}/>
};
