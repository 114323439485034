import React from "react";
import {Box, BoxProps, Button, ButtonGroup, Grid, GridSpacing} from "@mui/material";
import {
  DatePicker,
  DatePickerSlotsComponentsProps,
} from "@mui/x-date-pickers";
import {isToday, isTomorrow, isYesterday} from "date-fns";
import {getCurrentDates} from "../../helpers/utils";

export interface DateInterval {
  from: Date | null;
  to: Date | null;
}

export interface DateFilterProps {
  showQuickSelection?: boolean;
  labelFrom?: string;
  labelTo?: string;
  value: DateInterval;
  onChange: (val: DateInterval) => void;
  spacing?: GridSpacing;
  containerProps?: BoxProps
}

const DateFilter: React.FC<DateFilterProps> = ({
                                                 value,
                                                 onChange,
                                                 showQuickSelection = false,
                                                 labelFrom = "From",
                                                 labelTo = "To",
                                                 spacing,
                                                 containerProps = {mb: 1}
                                               }) => {
  let selectedDate = "";
  if (
    value.from &&
    value.to &&
    value.from.toDateString() === value.to.toDateString()
  ) {
    if (isYesterday(value.from)) {
      selectedDate = "yesterday";
    } else if (isTomorrow(value.from)) {
      selectedDate = "tomorrow";
    } else if (isToday(value.from)) {
      selectedDate = "today";
    }
  }

  const setDateIntervalValue = (
    fieldValue: Date | null,
    field: keyof DateInterval
  ) => {
    if (!fieldValue || !isNaN(fieldValue.getTime())) {
      const newInterval: DateInterval = {...value, [field]: fieldValue};
      onChange(newInterval);
    }
  };
  const setDateValue = (dateValue: Date | null) => {
    const newInterval: DateInterval = {from: dateValue, to: dateValue};
    onChange(newInterval);
  };

  const datePickerSlotProps: DatePickerSlotsComponentsProps<Date | null> = {
    actionBar: {actions: ["clear", "accept"]},
    shortcuts: {
      items: [
        {label: "Yesterday", getValue: () => getCurrentDates("yesterday")},
        {label: "Today", getValue: () => getCurrentDates("today")},
        {label: "Tomorrow", getValue: () => getCurrentDates("tomorrow")},
      ],
    },
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={spacing} flexDirection="row" justifyContent="center" alignItems="center" {...containerProps}>
      {showQuickSelection && (<Box mx={2} my={2}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              disabled={!selectedDate}
              onClick={() => {
                setDateValue(null);
              }}
            >
              All
            </Button>
            <Button
              disabled={selectedDate === "yesterday"}
              onClick={() => {
                setDateValue(getCurrentDates("yesterday"));
              }}
            >
              Yesterday
            </Button>
            <Button
              disabled={selectedDate === "today"}
              onClick={() => {
                setDateValue(getCurrentDates("today"));
              }}
            >
              Today
            </Button>
            <Button
              disabled={selectedDate === "tomorrow"}
              onClick={() => {
                setDateValue(getCurrentDates("tomorrow"));
              }}
            >
              Tomorrow
            </Button>
          </ButtonGroup>
      </Box>)}
      {(!selectedDate || !showQuickSelection) && (<Box display="flex" flexWrap="nowrap">
          <DatePicker
            slotProps={datePickerSlotProps}
            format="dd/MM/yyyy"
            value={value.from}
            onChange={(value) => setDateIntervalValue(value, "from")}
            maxDate={value.to || null}
            label={labelFrom}
          />
          <DatePicker
            slotProps={datePickerSlotProps}
            sx={{ml: 1}}
            format="dd/MM/yyyy"
            value={value.to}
            onChange={(value) => setDateIntervalValue(value, "to")}
            minDate={value.from || null}
            label={labelTo}
          />
      </Box>)}
    </Box>
  );
};

export default DateFilter;
