import { useState } from "react";
import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IUser } from "interfaces";
import { Controller } from "react-hook-form";

import "./styles.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const types = [
  { id: "user", label: "User" },
  { id: "driver", label: "Driver" },
];

const driverRoles = [
  { id: "SuperDriver", label: "SuperDriver" },
  { id: "Driver Advance", label: "Driver Advance" },
  { id: "Driver Basic", label: "Driver Basic" },
  { id: "Guest Driver", label: "Guest Driver" },
];

const defaultHours = [
  { id: "8:00", label: "8:00" },
  { id: "8:30", label: "8:30" },
  { id: "9:00", label: "9:00" },
  { id: "9:30", label: "9:30" },
  { id: "10:00", label: "10:00" },
  { id: "10:30", label: "10:30" },
  { id: "11:00", label: "11:00" },
  { id: "11:30", label: "11:30" },
  { id: "12:00", label: "12:00" },
];

const defaultHoursEnd = [
  { id: "8:30", label: "8:30" },
  { id: "9:00", label: "9:00" },
  { id: "9:30", label: "9:30" },
  { id: "10:00", label: "10:00" },
  { id: "10:30", label: "10:30" },
  { id: "11:00", label: "11:00" },
  { id: "11:30", label: "11:30" },
  { id: "12:00", label: "12:00" },
  { id: "12:30", label: "12:30" },
  { id: "13:00", label: "13:00" },
  { id: "13:30", label: "13:30" },
  { id: "14:00", label: "14:00" },
  { id: "14:30", label: "14:30" },
  { id: "15:00", label: "15:00" },
  { id: "15:30", label: "15:30" },
  { id: "16:00", label: "16:00" },
  { id: "16:30", label: "16:30" },
  { id: "17:00", label: "17:00" },
  { id: "17:30", label: "17:30" },
  { id: "18:00", label: "18:00" },
  { id: "18:30", label: "18:30" },
  { id: "19:00", label: "19:00" },
  { id: "19:30", label: "19:30" },
  { id: "20:00", label: "20:00" },
];

export const CreateUserModal: React.FC<
  UseModalFormReturnType<IUser, HttpError, IUser>
> = ({
  saveButtonProps,
  refineCore: { onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const [type, setType] = useState("user");
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = (data: any) => {
    if (type === "user") {
      onFinish({
        username: data.username,
        password: data.password,
        is_active: data.is_active,
        is_superuser: data.is_superuser,
        address_home: null,
        address_home2: null,
        address_home3: null,
        address_home4: null,
      });
    } else {
      onFinish({
        username: data.username,
        password: data.password,
        is_active: data.is_active,
        is_superuser: data.is_superuser,
        address_home: data.address_home,
        address_home2: data.address_home2,
        address_home3: data.address_home3,
        address_home4: data.address_home4,
        default_hours: data.default_hours,
        default_hours_end: data.default_hours_end,
        driver_role: data.driver_role,
        name: data.name,
        surname: data.surname,
      });
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            value={type}
            onChange={(e) => setType(e.target.value)}
            select
            label="Type"
            style={{ marginTop: 16, marginBottom: 8 }}
          >
            {types.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            {...register("username")}
            error={!!errors.username}
            helperText={errors.username?.message}
            margin="normal"
            fullWidth
            label="Username"
            autoFocus
          />
          <TextField
            {...register("password")}
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  {showPassword ? (
                    <Visibility
                      className="cursor_pointer"
                      onClick={togglePassword}
                    />
                  ) : (
                    <VisibilityOff onClick={togglePassword} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            margin="normal"
            fullWidth
            label="Name"
            autoFocus
            style={{ display: type === "user" ? "none" : "block" }}
          />
          <TextField
            {...register("surname")}
            error={!!errors.surname}
            helperText={errors.surname?.message}
            margin="normal"
            fullWidth
            label="Surname"
            autoFocus
            style={{ display: type === "user" ? "none" : "block" }}
          />
          <TextField
            {...register("address_home")}
            error={!!errors.address_home}
            helperText={errors.address_home?.message}
            margin="normal"
            fullWidth
            label="Address Home"
            autoFocus
            style={{ display: type === "user" ? "none" : "block" }}
          />

          <TextField
            {...register("address_home2")}
            error={!!errors.address_home2}
            helperText={errors.address_home2?.message}
            margin="normal"
            fullWidth
            label="Address Home 2"
            autoFocus
            style={{ display: type === "user" ? "none" : "block" }}
          />

          <TextField
            {...register("address_home3")}
            error={!!errors.address_home3}
            helperText={errors.address_home3?.message}
            margin="normal"
            fullWidth
            label="Address Home 3"
            autoFocus
            style={{ display: type === "user" ? "none" : "block" }}
          />

          <TextField
            {...register("address_home4")}
            error={!!errors.address_home4}
            helperText={errors.address_home4?.message}
            margin="normal"
            fullWidth
            label="Address Home 4"
            autoFocus
            style={{ display: type === "user" ? "none" : "block" }}
          />

          <Controller
            control={control}
            name="driver_role"
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value || ""}
                  select
                  label="Driver Role"
                  style={{
                    marginTop: 16,
                    marginBottom: 8,
                    display: type === "user" ? "none" : "",
                  }}
                >
                  {driverRoles.map((driverRole) => (
                    <MenuItem key={driverRole.id} value={driverRole.id}>
                      {driverRole.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            control={control}
            name="default_hours"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value || ""}
                  select
                  label="Default Hours Start"
                  style={{
                    marginTop: 16,
                    marginBottom: 8,
                    display: type === "user" ? "none" : "",
                  }}
                >
                  {defaultHours.map((defaultHour) => (
                    <MenuItem key={defaultHour.id} value={defaultHour.id}>
                      {defaultHour.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            control={control}
            name="default_hours_end"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value || ""}
                  select
                  label="Default Hours End"
                  style={{
                    marginTop: 16,
                    marginBottom: 8,
                    display: type === "user" ? "none" : "",
                  }}
                >
                  {defaultHoursEnd.map((defaultHourEnd) => (
                    <MenuItem key={defaultHourEnd.id} value={defaultHourEnd.id}>
                      {defaultHourEnd.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            control={control}
            name="is_active"
            defaultValue={true}
            render={({ field }) => (
              <FormControlLabel
                label="Is Active"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
          <Controller
            control={control}
            name="is_superuser"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Is Superuser"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
