import { useState } from "react";
import {HttpError, useTranslate} from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import {ICustomer} from "interfaces";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {ShowText} from "../../../helpers/fields/show";

export const PasswordCustomerModal: React.FC<
  UseModalFormReturnType<Partial<ICustomer>, HttpError, Partial<ICustomer>>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, },
  register,
  handleSubmit,
  formState: { errors },
}) => {
  const [showPassword, setShowPassword] = useState(true);
  const translate = useTranslate()
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;
    if (currentData?.username) {
      onFinish({
        username: currentData.username||'',
        password: data.password||'',
      })
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{translate('customers.titles.change_password', "Change password")}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <ShowText label="Username" value={queryResult?.data?.data?.username||''}/>
          <ShowText label="Company name" value={queryResult?.data?.data?.company_name||''}/>
          <TextField
            {...register("password")}
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  {showPassword ? (
                    <Visibility
                      className="cursor_pointer"
                      onClick={togglePassword}
                    />
                  ) : (
                    <VisibilityOff onClick={togglePassword} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
