import React, {useState} from "react";
import {
  useDataGrid,
  List,
  TagField,
  DateField,
  EditButton,
  DeleteButton,
} from "@refinedev/mui";
import {
  DataGrid,
  GridColumns,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import {CanAccess, CrudFilter, HttpError, useCreate, useMany, useTranslate} from "@refinedev/core";
import { Button, Link } from "@mui/material";
import {Password, Sync} from "@mui/icons-material";
import { useModalForm } from "@refinedev/react-hook-form";
import {ICustomer, IUser} from "interfaces";
import { EditProductModal } from "./components/editModal";
import {PasswordUserModal} from "../users/components/passwordModal";
import {PasswordCustomerModal} from "./components/passwordModal";
import {SearchField} from "../../components/SearchField";

export const CustomerList = () => {
  const translate = useTranslate()

  const [searchQuery, setSearchQuery] = useState("");
  const filters: CrudFilter[] = []
  if (searchQuery) {
    filters.push({
      field: "q",
      operator: "eq",
      value: searchQuery,
    });
  }

  const {
    dataGridProps,
    tableQueryResult: { refetch },
  } = useDataGrid({
    resource: "customers/v2",
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
    filters: {
      permanent: filters,
    },
  });

  const { data: mainContactData, isLoading: mainContactIsLoading } = useMany({
    resource: "contacts",
    ids:
      dataGridProps?.rows
        ?.filter((item) => !!item.main_contact)
        .map((item: any) => {
          return item?.main_contact;
        }) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const { data: contactsData, isLoading: contactsIsLoading } = useMany({
    resource: "contacts",
    ids:
      dataGridProps?.rows.map((item: any) => {
        return item?.id;
      }) ?? [],
    metaData: {
      filterField: "customer_id",
    },
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const { data: addressData, isLoading: addressIsLoading } = useMany({
    resource: "addresses",
    ids:
      dataGridProps?.rows.map((item: any) => {
        return item?.id;
      }) ?? [],
    metaData: {
      filterField: "customer_id",
    },
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const {
    data: discountsData,
    isLoading: discountsIsLoading,
    refetch: discountsRefetch,
  } = useMany({
    resource: "discounts",
    ids:
      dataGridProps?.rows.map((item: any) => {
        return item?.id;
      }) ?? [],
    metaData: {
      filterField: "customer_id",
    },
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const { data: discountsRulesData, isLoading: discountsRulesIsLoading } =
    useMany({
      resource: "discount_rules",
      ids: discountsData?.data
        ? discountsData?.data.map((item: any) => item?.discount_rule_id)
        : [],
      queryOptions: {
        enabled: !discountsIsLoading && !!discountsData,
      },
    });

  const { data: segmentData, isLoading: segmentIsLoading } = useMany({
    resource: "segments",
    ids:
      dataGridProps?.rows.map((item: any) => {
        return item?.segment;
      }) ?? [],
    queryOptions: {
      enabled: !!dataGridProps?.rows,
    },
  });

  const { mutate, isLoading: isLoadingMutate } = useCreate();

  const editModalFormProps = useModalForm<ICustomer, HttpError, ICustomer>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      resource: "customers/v2", // /v2
      onMutationSuccess: () => {
        closeEditModal();
        discountsRefetch();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showEditModal, close: closeEditModal },
  } = editModalFormProps;

  const passwordModalFormProps = useModalForm<Partial<ICustomer>, HttpError, Partial<ICustomer>>({
    refineCoreProps: {
      action: "edit",
      meta: { method: "put" },
      resource: "customers/v2",
      onMutationSuccess: () => {
        closePasswordModal();
      },
    },
    modalProps: {
      autoResetForm: true,
    },
  });

  const {
    modal: { show: showPasswordModal, close: closePasswordModal },
  } = passwordModalFormProps;

  const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const filterOperatorsSegment = getGridSingleSelectOperators().filter(
    (operator) => operator.value === "is"
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        minWidth: 130,
        filterOperators: filterOperators,
        renderCell(params) {
          return (
            <Link
              onClick={() => showEditModal(params.id)}
              style={{ cursor: "pointer" }}
            >
              {params.id}
            </Link>
          );
        },
      },
      {
        field: "fatture_id",
        headerName: "Fatture",
        filterOperators: filterOperators,
      },
      {
        field: "username",
        headerName: "Username",
        filterOperators: filterOperators,
      },
      {
        field: "company_name",
        headerName: "Company Name",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "custom_name",
        headerName: "Custom Name",
        filterOperators: filterOperators,
        minWidth: 150,
      },
      {
        field: "main_contact",
        headerName: "Main Contact",
        valueGetter: ({ row }) => {
          const value = row?.main_contact;
          return value;
        },
        sortable: false,
        filterable: false,
        minWidth: 150,
        renderCell: function render({ value }) {
          if (mainContactIsLoading) return <>Loading...</>;
          return mainContactData?.data?.find((item) => item.id === value)?.name;
        },
      },
      {
        field: "contacts",
        headerName: "Contacts",
        valueGetter: ({ row }) => {
          const value = row?.id;
          return value;
        },
        sortable: false,
        filterable: false,
        minWidth: 300,
        renderCell: function render({ value }) {
          if (contactsIsLoading) return <>Loading...</>;
          return (
            <div>
              {contactsData?.data
                .filter((item) => item.customer_id === value)
                ?.map((item, key) => {
                  return (
                    <TagField
                      key={key}
                      value={item.name}
                      style={{ margin: 4 }}
                    />
                  );
                })}
            </div>
          );
        },
      },
      {
        field: "addresses",
        headerName: "Addresses",
        valueGetter: ({ row }) => {
          const value = row?.id;
          return value;
        },
        sortable: false,
        filterable: false,
        minWidth: 300,
        renderCell: function render({ value }) {
          if (addressIsLoading) return <>Loading...</>;
          return (
            <div>
              {addressData?.data
                .filter((item) => item.customer_id === value)
                ?.map((item, key) => {
                  return (
                    <TagField
                      key={key}
                      value={item.street}
                      style={{ margin: 4 }}
                    />
                  );
                })}
            </div>
          );
        },
      },
      {
        field: "discounts",
        headerName: "Discounts",
        valueGetter: ({ row }) => {
          const value = row?.id;
          return value;
        },
        sortable: false,
        filterable: false,
        minWidth: 500,
        renderCell: function render({ value }) {
          if (discountsRulesIsLoading) return <>Loading...</>;
          const discounts = discountsData?.data
            ?.filter((item) => item?.customer_id === value)
            .map((item) => item?.discount_rule_id);

          const discountsRules = discountsRulesData?.data?.filter((item) =>
            discounts?.includes(item?.id)
          );

          return (
            <div>
              {discountsRules?.map((item, key) => {
                return (
                  <TagField key={key} value={item.name} style={{ margin: 4 }} />
                );
              })}
            </div>
          );
        },
      },
      {
        field: "segment",
        headerName: "Segment",
        filterOperators: filterOperatorsSegment,
        valueGetter: ({ row }) => {
          const value = row?.segment;
          return value;
        },
        renderCell: function render({ value }) {
          if (segmentIsLoading) return <>Loading...</>;
          return segmentData?.data?.find((item) => item.id === value)?.name;
        },
        type: "singleSelect",
        valueOptions: [
          { value: "47VU4VEYP", label: "B2B" },
          { value: "47VU4VF03", label: "B2C" },
        ],
      },
      {
        field: "split_payment",
        headerName: "Split Payment",
        type: "boolean",
      },
      {
        field: "show_fatture_doc",
        headerName: "Show Fatture Doc",
        type: "boolean",
      },
      {
        field: "is_active",
        headerName: "Is Active",
        type: "boolean",
      },
      {
        field: "created_at",
        headerName: "Created At",
        type: "date",
        renderCell: function render({ value }) {
          return <DateField value={value} format={"DD/MM/YYYY"} />;
        },
      },
      {
        field: "last_visit",
        headerName: "Last Visit",
        type: "date",
        renderCell: function render({ value }) {
          if (!value) return "";
          return <DateField value={value} format={"DD/MM/YYYY"} />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText onClick={() => showEditModal(row.id)} />
              <Button
                  onClick={() => showPasswordModal(row.id)}
                  size="small"
                  style={{ minWidth: "unset" }}
                  title="Change Password"
              >
                <Password fontSize={"small"} />
              </Button>
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
        filterable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mainContactIsLoading,
      mainContactData?.data,
      contactsIsLoading,
      contactsData?.data,
      addressIsLoading,
      addressData?.data,
      segmentIsLoading,
      segmentData?.data,
      discountsIsLoading,
      discountsData?.data,
      discountsRulesIsLoading,
      discountsRulesData?.data,
    ]
  );

  const syncCustomers = () => {
    mutate(
      {
        resource: "admin/sync/customers",
        values: {
          syncme: true,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  return (
    <>
      <List
        headerButtons={({ defaultButtons }) => {
          return (
            <>
              <SearchField value={searchQuery} onChange={(value) => setSearchQuery(value)}/>
              {defaultButtons}
              <CanAccess resource="customers" action="sync">
                <Button
                  variant="contained"
                  onClick={() => syncCustomers()}
                  startIcon={<Sync />}
                  disabled={isLoadingMutate}
                >
                  Sync with Fatture in Cloud
                </Button>
              </CanAccess>
            </>
          );
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          autoHeight={false}
          getRowHeight={() => "auto"}
          // onRowClick={(params) => {
          //   console.log("params", params);
          // }}
          sx={{
            height: 'calc(100vh - 220px)',
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
        />
      </List>
      {editModalFormProps?.modal?.visible && (
        <EditProductModal {...editModalFormProps} />
      )}
      <PasswordCustomerModal {...passwordModalFormProps}/>
    </>
  );
};
