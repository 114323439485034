import {InputAdornment, InputLabel, IconButton, FilledInput } from "@mui/material";
import { FormControl } from "@mui/material";
import {Search} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import useDebounce from "../hooks/useDebounce";

export interface SearchFieldProps {
  label?: string
  size?:  "small" | "medium"
  value: string
  onChange: (value: string) => void
}

export const SearchField = ({label = 'Search', value, onChange, size="small"}: SearchFieldProps) => {
  const [searchQuery, setSearchQuery] = useState(value);
  const debouncedSearchQuery = useDebounce(searchQuery, 500)

  useEffect(() => {
    onChange(debouncedSearchQuery)
  }, [debouncedSearchQuery]);


  //const debouncedSearchTerm = useDebounce(searchTerm, 200)

  return (
    <FormControl sx={{ }} variant="filled">
      <InputLabel htmlFor="filled-adornment-password">{label}</InputLabel>
      <FilledInput
        type="text"
        size={size}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="search" edge="end">
              <Search/>
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
