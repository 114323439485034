import React, {ReactElement, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {
    HttpError,
    useInvalidate,
    useTranslate,
    useUpdate
} from "@refinedev/core";
import {IAddress, IContact, IOrder, ITimeslot} from "interfaces";
import {ShowText} from "../../helpers/fields/show";
import {OrderItems} from "./tables/orderItems";
import {OrderPayments} from "./tables/orderPayments";
import {ControlledAutocomplete} from "components/edit/controlledAutocomplete";
import {EditFieldManager} from "../../components/edit/fields";
import {OrderInsoluti} from "./tables/orderInsoluti";
import OrderTotals from "./totals";
import ResponsiveEdit from "components/edit/ResponsiveEdit";
import {Cancel, Dangerous} from "@mui/icons-material";
import {SaveButton} from "@refinedev/mui";
import {statusOptions} from "./enums";
import {CustomerAddressPicker} from "../../components/customerAddressPicker";
import {ContactPicker} from "../../components/contactPicker";
import {useNavigate} from "react-router-dom";

export const EditOrderPage: React.FC = () => {

    const {
        saveButtonProps,
        refineCore: {queryResult, onFinish, redirect, formLoading},
        handleSubmit,
        register,
        reset,
        formState: {errors},
        control,
        watch,
        setValue,
    } = useForm<IOrder, HttpError, IOrder>({
        refineCoreProps: {
            redirect: false,
            meta: {method: "put"},
            mutationMode: "pessimistic"
        },
    })
    const invalidate = useInvalidate();
    const translate = useTranslate();
    const navigate = useNavigate();
    const [selectedContact, setSelectedContact] = useState<IContact>()
    const [selectedAddress, setSelectedAddress] = useState<IAddress>()

    const orderStatus = watch("status")


    useEffect(() => {
        const onPaymentCreated = (e: CustomEvent<{ id?: string }>) => {
            invalidate({
                resource: "orders",
                invalidates: ["list", "detail"]
            })
            queryResult?.refetch()
        }
        // @ts-ignore
        window.addEventListener('payment:created', onPaymentCreated)
        return () => {
            // @ts-ignore
            window.removeEventListener('payment:created', onPaymentCreated)
        }
    }, [])

    const onSubmit = (data: any) => {
        const currentData = queryResult?.data?.data;

        onFinish({
            ...currentData,
            ...data,
        });
    };

    const record = queryResult?.data?.data;

    const {mutate} = useUpdate<IOrder>();

    useEffect(() => {
        if (!selectedContact) {
        }
    }, []);

    const fieldManager = new EditFieldManager(control, register, record, errors);

    const contact: IContact = selectedContact || {
        name: record?.contact_name || '',
        telephone: record?.telephone || '',
        customer_id: "",
        customer_name: "",
        electronic_post: "",
        email: "",
        fax: "",
        id: ""
    }
    const shippingAddress: IAddress = selectedAddress || JSON.parse(record?.shipping_address || '{}')

    const saveAndContinue = (variables: IOrder) => {

        onFinish(variables).then((value) => {
            if (!value) {
                return
            }
            const data: IOrder = value.data
            // We'll wait for the mutation to finish and grab the id of the created product from the response.
            // This will only work on `pesimistic` mutation mode.
            setValue("save_address", false)
            setValue("save_contact", false)

        });
    };
    const saveAndAddAnother = (variables: IOrder) => {
        onFinish(variables).then(() => {
            // We'll wait for the mutation to finish and reset the form.
            reset();
            redirect("list")
        });
    };
    const handleCancel = () => {
        if (!record?.id) {
            return
        }
        mutate({
            resource: "orders",
            meta: {method: "put"},
            values: {
                status: "cancelled"
            },
            id: record?.id
        }, {
            onSuccess: (data, variables, context) => {
                navigate("/orders")
                // Let's celebrate!
            },
        })
    };
    const onAddressChanged = (address?: IAddress | null) => {
        if (!address) {
            setValue("shipping_address", '')
            return
        }
        setValue("shipping_address", JSON.stringify(address))
        setSelectedAddress(address)
        /*if (!address.id) {
            setValue("save_address", true)
        } else {
            setValue("save_address", false)
        }*/
    }
    const onContactChanged = (contact?: IContact | null, isNew?: boolean, saveContact: boolean = false) => {
        if (!contact) {
            setValue("telephone", '')
            setValue("contact_name", '')
            return
        }
        setSelectedContact(contact)
        setValue("telephone", contact.telephone || '')
        setValue("contact_name", contact.name || '')
        setValue("save_contact", isNew && !contact.id && saveContact)
    }

    const footerButtons: ReactElement[] = []
    if (orderStatus === 'cancelled') {
        footerButtons.push(
            <Button color="error" variant="contained" startIcon={<Cancel/>} onClick={() => handleCancel()}>{translate("orders.titles.cancel", "Cancel order")}</Button>,
            <Box flexGrow={1}></Box>
        )
    }

    footerButtons.push(
        <SaveButton {...saveButtonProps} type="submit" sx={{minWidth: '180px'}}
                                         onClick={handleSubmit(saveAndContinue)}>{translate("buttons.saveRemain", "Save and continue")}</SaveButton>,
        <SaveButton {...saveButtonProps} sx={{minWidth: '180px'}}
                    onClick={handleSubmit(saveAndAddAnother)}>{translate("buttons.save", "Save")}</SaveButton>
    )



    return (
        <ResponsiveEdit
            {...saveButtonProps}
            saveButtonProps={{
                //onClick: handleSubmit(onSubmit),
            }}
            footerButtons={<Box display="flex" sx={{flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', justifyContent: 'end', gap:2, width: '100%' }}>{footerButtons}</Box>}
            mutationMode="undoable"
        >
            <Box
                component="form"
                sx={{display: "flex", flexDirection: "column"}}
                autoComplete="off"
            >
                <Grid container spacing={3}>
                    {(record?.payment_balance && parseFloat(record?.payment_balance) > 0) ?
                        <Grid item xs={12} flexDirection="column">
                            <Typography variant="h5" color="error"><Dangerous style={{transform: 'translateY(3px)'}}
                                                                              fontSize="medium"/> Ordine insoluto
                                (€ {record?.payment_balance} da pagare)</Typography>
                        </Grid> : <></>}
                    <Grid item xs={12} sm={6} flexDirection="column">
                        <Paper sx={{p: 2}}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <ShowText
                                    value={record?.id}
                                    label={translate("orders.fields.id", "Id")}
                                />
                                <Box flexGrow={1}>
                                    {fieldManager.selectField(
                                        "status",
                                        translate("orders.fields.status", "Status"),
                                        statusOptions
                                    )}
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="column">
                                <ShowText
                                    label={translate("orders.fields.fatture", "Fatture")}
                                    value={record?.fatture_id}
                                />
                                {fieldManager.switchField(
                                    "update_fatture",
                                    translate("orders.fields.update_fatture", "Update fatture")
                                )}
                                {fieldManager.switchField(
                                    "show_fatture_doc",
                                    translate(
                                        "orders.fields.show_fatture_doc",
                                        "Show fatture doc"
                                    )
                                )}
                                {fieldManager.switchField(
                                    "update_shipox",
                                    translate("orders.fields.update_shipox", "Update Shipox")
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper sx={{p: 2}}>
                            <Box display="flex" flexDirection="column">
                                <ShowText
                                    label="Id cliente"
                                    value={record?.customer_id}
                                    box={{flexGrow: 1}}
                                />
                                <ShowText
                                    label="Nome cliente"
                                    value={record?.customer_name || ''}
                                    box={{flexGrow: 1}}
                                />
                                <CustomerAddressPicker label={"Shipping address"} customerId={record?.customer_id}
                                                       value={shippingAddress} onChange={onAddressChanged}/>
                                {!shippingAddress.id && fieldManager.switchField(
                                    "save_address",
                                    translate("orders.fields.save_address", "Save address"),
                                    false
                                )}
                                <ContactPicker label={"Contact name"} value={contact} customerId={record?.customer_id}
                                               onChange={onContactChanged}/>
                                {/*<ControlledAutocomplete<IOrder, IContact>
                                    filters={[
                                        {
                                            field: "customer_id",
                                            operator: "eq",
                                            value: record?.customer_id,
                                        },
                                    ]}
                                    resource="contacts"
                                    sortField="name"
                                    searchField="name"
                                    matchField="name"
                                    control={control}
                                    enabled={!!record?.id}
                                    label="Contact name"
                                    name="contact_name"
                                    getOptionLabel={(option?: IContact) =>
                                        option ? `${option.name}, ${option.telephone}` : ""
                                    }
                                    serializer={(value) => value?.name || null}
                                    deserializer={(value) =>
                                        typeof value === "string" ? {name: value} : value
                                    }
                                    onChange={(e, value) => {
                                        if (value && value.telephone) {
                                            setValue("telephone", value.telephone);
                                            // @ts-ignore
                                            //e.target.value = value.telephone
                                            //telephoneField.ref
                                        } else {
                                            console.log('new value')
                                            setValue("telephone", "");
                                        }
                                    }}
                                />*/}
                                {fieldManager.dateField("delivery_date", "Delivery date")}
                                <ControlledAutocomplete<IOrder, ITimeslot>
                                    filters={[
                                        {
                                            field: "is_active",
                                            operator: "eq",
                                            value: true,
                                        },
                                    ]}
                                    resource="timeslots"
                                    sortField="name"
                                    searchField="name"
                                    control={control}
                                    enabled={!!record?.id}
                                    label="Delivery time"
                                    name="timeslot_id"
                                    getOptionLabel={(option?: ITimeslot) =>
                                        option ? option.name : ""
                                    }
                                    serializer={(value) => value?.id || null}
                                    deserializer={(value) =>
                                        typeof value !== "object" ? {id: value} : value
                                    }
                                />
                                {fieldManager.textField("internal_note", "Internal note", {multiline: true})}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{p: 0}}>
                            <OrderInsoluti
                                isLoading={queryResult?.isLoading || false}
                                orderId={record?.id}
                                customerId={record?.customer_id}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{p: 0}}>
                            {formLoading ? <>
                                <Box p={1} display="flex" alignItems="center">
                                    <Typography sx={{flexGrow: 1}} variant="h6" color="primary">
                                        Items
                                    </Typography>
                                </Box>
                                <Box p={2} display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress/>
                                </Box>
                            </> : <OrderItems
                                isLoading={queryResult?.isLoading || formLoading || false}
                                orderId={record?.id}
                                edit
                            />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{p: 0}}>
                            <OrderPayments
                                isLoading={queryResult?.isLoading || false}
                                orderId={record?.id}
                                paymentBalance={parseFloat(record?.payment_balance || '0.0')}
                                totalAmount={record?.total_amount}
                                defaultDate={record?.delivery_date}
                                edit
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{p: 2}}>
                            <OrderTotals
                                edit={true}
                                fieldManager={fieldManager}
                                record={record}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </ResponsiveEdit>
    );
};
