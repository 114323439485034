import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IDiscountRule } from "interfaces";

export const CreateDiscountRuleModal: React.FC<
  UseModalFormReturnType<IDiscountRule, HttpError, IDiscountRule>
> = ({
  saveButtonProps,
  refineCore: { onFinish },
  modal: { visible, close, title },
  register,
  handleSubmit,
  formState: { errors },
}) => {
  const onSubmit = (data: any) => {
    onFinish({
      ...data,
      amount: data.amount ? data.amount : 0,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            margin="normal"
            label="Name"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
