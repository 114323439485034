import React, {useState} from "react";
import {useDataGrid, List, DateField} from "@refinedev/mui";
import {
    GridToolbar,
} from "@mui/x-data-grid";
import {
    DataGridPro,
    getGridStringOperators,
    GridColTypeDef,
    GridColumns,
    GridFilterInputValue,
    GridFilterOperator,
} from "@mui/x-data-grid-pro";
import {CrudFilter, useGetIdentity, useMany, useSelect, useTranslate} from "@refinedev/core";
import {Divider, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {getCurrentDate} from "helpers/utils";
import {useGetRole} from "hooks/getRoles/useGetRole";
import {IPayment, IReportItem, IUser} from "interfaces";
import {SearchField} from "../../components/SearchField";
import {DisplayNumber} from "../../components/DisplayNumber";

interface GroupedReportItems {
    [key: string]: {
        [key: string]: number;
    };
}

const groupAndSumPayments = (payments: IReportItem[]): GroupedReportItems => {
    const groupedPayments: GroupedReportItems = {};

    payments.forEach((payment) => {
        if (!groupedPayments[payment.type]) {
            groupedPayments[payment.type] = {};
        }

        if (!groupedPayments[payment.type][payment.status]) {
            groupedPayments[payment.type][payment.status] = 0;
        }


        if (payment.type === 'payment' && payment.status.toLowerCase() === 'insoluto') {
            groupedPayments[payment.type][payment.status] += payment.payment_balance;
        } else if (payment.type === 'payment' && payment.status.toLowerCase() !== 'insoluto') {
            groupedPayments[payment.type]['Insoluto'] += payment.payment_balance;
            groupedPayments[payment.type][payment.status] += payment.amount;
        } else {
            groupedPayments[payment.type][payment.status] += payment.amount;
        }
    });

    return groupedPayments;
}
export const ReportList = () => {
    const [startDate, setStartDate] = useState(getCurrentDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedDriver, setSelectedDriver] = useState<string>("all");

    const translate = useTranslate();

    const role = useGetRole();
    const {data: user} = useGetIdentity<IUser>();

    const {options: usersList} = useSelect<IUser>({
        resource: "users",
        optionValue: "id",
        optionLabel: "username",
        hasPagination: false,
        filters: [
            {
                field: "driver_role",
                operator: "eq",
                value: "driver",
            },
        ],
    });

    const filters: CrudFilter[] = [
        {
            field: "date",
            operator: "gte",
            value: startDate,
        },
        {
            field: "date",
            operator: "lte",
            value: endDate,
        },
        {
            field: "user_id",
            operator: "eq",
            value:
                role === "driver"
                    ? user?.id
                    : selectedDriver !== "all"
                        ? selectedDriver
                        : null,
        },
    ]

    if (searchQuery) {
        filters.push({
            field: "q",
            operator: "eq",
            value: searchQuery,
        })
    }


    // getRowClassName={({row}) => row.payment_balance && parseFloat(row.payment_balance) ? 'background-red' : ''}

    const {dataGridProps} = useDataGrid<IReportItem>({
        resource: "reports_v2", //"reports/balance",
        filters: {
            permanent: filters,
        },
        pagination: {pageSize: 200}
    });

    let euro = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
    });


    const {data: usersData, isLoading: usersIsLoading} = useMany({
        resource: "users",
        ids:
            dataGridProps?.rows?.map((item: any) => {
                return item?.user_id;
            }) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });


    const filterOperators = getGridStringOperators().filter(
        (operator) => operator.value === "contains"
    );

    const columns = React.useMemo<GridColumns<IReportItem>>(
        () => [
            {
                field: "order_id",
                headerName: "Order ID",
                filterOperators: filterOperators,
                flex: 1,
                renderCell: function render({value}) {
                    return (
                        <a
                            href={`/orders/show/${value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {value}
                        </a>
                    );
                },
            },
            {
                field: "type",
                headerName: "Type",
                filterOperators: filterOperators,
                flex: 1,
            },
            {
                field: "status",
                headerName: "Status",
                filterOperators: filterOperators,
                flex: 1,
            },
            {
                field: "amount",
                headerName: "Amount",
                type: "number",
                flex: 1,
                valueFormatter: ({value}) => value.toFixed(2)
            },
            {
                field: "payment_balance",
                headerName: translate("reports.fields.partial_payment", "Partial payment"),
                type: "number",
                flex: 1,
                valueGetter: ({row}) => row.payment_balance && row.payment_balance > 0 ? row.payment_balance : '',
            },
            {
                field: "user",
                headerName: "User",
                filterable: false,
                sortable: false,
                valueGetter: ({row}) => {
                    const value = row?.user_id;
                    return value;
                },
                minWidth: 150,
                renderCell: function render({value}) {
                    if (usersIsLoading) return <>Loading...</>;
                    const user = usersData?.data?.find((item) => item.id === value);
                    return <>{user?.name ? user?.name : user?.username}</>;
                },
            },
            {
                field: "company_name",
                headerName: "Client",
                flex: 1,
            },
            {
                field: "date",
                headerName: "Date",
                minWidth: 150,
                renderCell: function render({value}) {
                    // HH:mm
                    return <DateField value={value} format={"DD/MM/YYYY"}/>;
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [usersIsLoading, usersData?.data]
    );

    const payments = dataGridProps?.rows?.reduce((acc: any, item: any) => {
        if (item.type === "payment" && (item.payment_balance)) { //["Payed in cash", "Parziale"].indexOf(item.status) !== -1) ||
            acc += +item.amount;
        }
        return acc;
    }, 0);

    const expenses = dataGridProps?.rows?.reduce((acc: any, item: any) => {
        if (item.type === "expense") {
            acc += +item.amount;
        }
        return acc;
    }, 0);

    const groupedItems = groupAndSumPayments((dataGridProps?.rows || []) as IReportItem[])

    const cashPayments = (groupedItems['payment'] || {})['Payed in cash'] || 0
    const paymentTypes = Object.keys(groupedItems['payment'] || {})

    const balance = cashPayments - expenses;

    return (
        <>
            <List
                headerButtons={({defaultButtons}) => {
                    return <>{defaultButtons}</>;
                }}
            >
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: 20,
                    }}
                >
                    <Grid item xs={12} md={2}>
                        <SearchField value={searchQuery} size="medium" onChange={(value) => setSearchQuery(value)}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            label="Date start"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            label="Date end"
                            type="date"
                            fullWidth
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            value={selectedDriver}
                            onChange={(e) => {
                                // setIsProcessing(true);
                                setSelectedDriver(e.target.value);
                            }}
                            select
                            label="Driver"
                            placeholder="Select a driver"
                            style={{marginTop: 0, marginBottom: 8}}
                            fullWidth
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            {usersList?.map((driver) => (
                                <MenuItem value={driver.value} key={driver.value}>
                                    {driver.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                        <DataGridPro
                            {...dataGridProps}
                            getRowClassName={({row}) => row.payment_balance && parseFloat(row.payment_balance) > 0 ? 'background-red' : ''}
                            components={{Toolbar: GridToolbar}}
                            pagination
                            rowsPerPageOptions={[25, 50, 100, 200, 500]}
                            initialState={{
                                pagination: {pageSize: 200}
                            }}
                            componentsProps={{
                                toolbar: {
                                    useDensity: false,
                                    printOptions: {disableToolbarButton: true},
                                    csvOptions: {
                                        disableToolbarButton: false,
                                        delimiter: ";"
                                    },
                                }
                            }}
                            columns={columns}
                            autoHeight
                            getRowHeight={() => "auto"}
                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // Forced to use important since overriding inline styles
                                    maxHeight: "168px !important",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Grid container>
                            <Grid item mb={1} xs={12}>
                                <Typography variant="h5" component="h2">
                                    Report details
                                </Typography>
                            </Grid>
                            {paymentTypes.filter(p => p !== 'Payed in cash').map(paymentType => <DisplayNumber
                                label={paymentType} value={groupedItems['payment'][paymentType] || 0}/>)}
                            <Grid py={1} item xs={12}>
                                <Divider/>
                            </Grid>
                            <DisplayNumber label="Cash payments" value={cashPayments}/>
                            <DisplayNumber label="Expenses" value={expenses}/>
                            <DisplayNumber label="Balance" value={balance} highlight={true}/>
                        </Grid>
                    </Grid>
                </Grid>
            </List>
        </>
    );
};
