import { useState } from "react";
import { RoutesStepOne } from "./stepOne";
import { RoutesStepTwo } from "./stepTwo";

export const RoutesPage: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [selectedOrigin, setSelectedOrigin] = useState();
  const [currentRoutes, setCurrentRoutes] = useState<any>(undefined);

  const getSteps = () => {
    switch (step) {
      case 1:
        return (
          <RoutesStepOne
            setStep={setStep}
            selectedOrigin={selectedOrigin}
            setSelectedOrigin={setSelectedOrigin}
            setCurrentRoutes={setCurrentRoutes}
          />
        );
      case 2:
        return (
          <RoutesStepTwo setStep={setStep} currentRoutes={currentRoutes} selectedOrigin={selectedOrigin}/>
        );
      default:
      // return <RoutesStepOne />;
    }
  };
  return (
    <div>
      <h1>Routes</h1>
      {getSteps()}
    </div>
  );
};
