import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { ITimeslot } from "interfaces";
import { Controller } from "react-hook-form";

export const EditProductModal: React.FC<
  UseModalFormReturnType<ITimeslot, HttpError, ITimeslot>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  control,
  handleSubmit,
}) => {
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="is_active"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="Is Active"
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                  //   <Checkbox
                  //     {...field}
                  //     checked={field.value}
                  //     onChange={(event) => {
                  //       field.onChange(event.target.checked);
                  //     }}
                  //   />
                }
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
