import React, {useState} from "react";
import {
    DateField,
    DeleteButton,
    EditButton,
    List,
    NumberField,
    ShowButton,
    useDataGrid,
} from "@refinedev/mui";
import {
    GridColTypeDef,
    GridColumns,
    GridFilterInputValue,
    GridFilterOperator,
    DataGridPro,
    getGridSingleSelectOperators,
    getGridStringOperators,
    GridColumnVisibilityModel,

    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import {
    CrudFilter,
    HttpError, useCreate, useInvalidate,
    useList, useNavigation, useTranslate,
} from "@refinedev/core";
import {
    Box,
    Button,
    Divider,
    Tab,
    useMediaQuery, useTheme
} from "@mui/material";
// import { EditProductModal } from "./components/editModal";
import {AddBoxOutlined, Payment, Sync, Warning} from "@mui/icons-material";
import "./styles.css";
import {generateReadableAddress} from "helpers/utils";
import {Link, useNavigate} from "react-router-dom";
import {ICountResult, IOrder, IOrderItem, IPayment, IProduct, IUnpaidRecord, IUser} from "interfaces";
import ArticleChip from "components/show/ArticleChip";
import ProductCounterBar from "components/list/ProductCounterBar";
import {OrderActions, OrderStatus} from "pages/orders/list/actions";
import {useGetDrivers} from "hooks/useGetDrivers";
import {format} from "date-fns";
import DateFilter, {DateInterval} from "components/list/DateFilter";
import {AddPaymentModal} from "../tables/orderPayments";
import {useModalForm} from "@refinedev/react-hook-form";
import {SearchField} from "components/SearchField";
import {paymentStautsOptions} from "../enums";
import {CreateOrderModal} from "./createModal";
import ResponsiveTabs from "../../../components/ResponsiveTabs";

const isNullOrNotOperator = (label: string, nullLabel: string, notNullLabel: string): GridFilterOperator => ({
    label: label,
    value: "isNullOrNot",
    getApplyFilterFn: (filterItem: any) => {
        if (
            !filterItem.columnField ||
            !filterItem.value ||
            !filterItem.operatorValue
        ) {
            return null;
        }
        return ({value}) => {
            return filterItem.value === "null" ? value == null : value != null;
        };
    },
    InputComponent: (props: any) => (
        <GridFilterInputValue
            select
            SelectProps={{
                native: true,
            }}
            {...props}
        >
            <option value=""></option>
            <option value="null">{nullLabel}</option>
            <option value="notNull">{notNullLabel}</option>
        </GridFilterInputValue>
    ),
    getValueAsString: (value: number) => `${value}`,
})

const driverColumnType: GridColTypeDef<any, any> = {
    extendType: "singleSelect",
    filterOperators: [...getGridSingleSelectOperators(), isNullOrNotOperator("Assigned", "Not assigned", "Assigned")],
};

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{gap: 2, py: 1}}>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}

// @ts-ignore
export const OrderList = () => {
    const invalidate = useInvalidate();

    const [dateFilterInterval, setDateFilterInterval] = React.useState<DateInterval>({from: new Date(), to: new Date()});
    const [status, setStatus] = React.useState<OrderStatus>("pending");
    const [selectedOrders, setSelectedOrders] = React.useState<string[]>([]);
    const [tableOffset, setTableOffset] = React.useState<number | undefined>();
    const [selectedOrder, setSelectedOrder] = useState<IOrder>();
    const [searchQuery, setSearchQuery] = useState("");
    const translate = useTranslate();

    const {editUrl: generateEditUrl} = useNavigation();
    const navigate = useNavigate();
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const filters: CrudFilter[] = [
        {
            field: "status",
            operator: "eq",
            value: status,
        },
    ];

    const handleDateFilterIntervalChanged = (newInterval: DateInterval) => {

        if (!newInterval.from) {
            // newInterval.from = new Date('2020-01-01T00:00:00');
        }
        setDateFilterInterval(newInterval);
    }



    if (dateFilterInterval.from) {
        filters.push({
            field: "delivery_date",
            operator: "gte",
            value: format(dateFilterInterval.from, "yyyy-MM-dd"),
        });
    }
    if (dateFilterInterval.to) {
        filters.push({
            field: "delivery_date",
            operator: "lte",
            value: format(dateFilterInterval.to, "yyyy-MM-dd"),
        });
    }
    if (searchQuery) {
        filters.push({
            field: "q",
            operator: "eq",
            value: searchQuery,
        });
    }

    const paymentOptions = paymentStautsOptions.filter(p => !p.hidden).map(o => ({
        value: o.value,
        label: translate(o.translationKey, o.description)
    }))

    const {
        dataGridProps,
        tableQueryResult: {refetch, data: ordersData},
    } = useDataGrid<IOrder>({
        sorters: {
            initial: [
                {
                    field: "delivery_date",
                    order: "asc",
                },
                {
                    field: "delivery_time",
                    order: "asc",
                },
            ],
        },
        pagination: {
            mode: "server",
            pageSize: 200,
        },
        filters: {
            permanent: filters,
        },
        meta: {
            filter: "objects",
        },
    });



    const orderIds = (ordersData?.data || []).map((order) => order.id);
    const segmentOptions = [{value: "B2B", label: "B2B"}, {value: "B2C", label: "B2C"}];

    const {mutate} = useCreate();

    const filterOperators = getGridStringOperators().filter(
        (operator) => operator.value === "contains"
    );

    const filterOperatorsDriverRole = getGridSingleSelectOperators().filter(
        (operator) => operator.value === "is"
    );
    filterOperatorsDriverRole.push(isNullOrNotOperator("Assigned", "Not assigned", "Assigned")); //isNullOrNot

    const filterOperatorsSingleSelect = getGridSingleSelectOperators().filter(
        (operator) => operator.value === "is"
    );

    const {data: orderItemsResponseData} = useList<IOrderItem, HttpError>({
        resource: "order_items",
        pagination: {
            mode: "off",
        },
        filters: [
            {
                field: "order_id",
                operator: "in",
                value: orderIds,
            },
        ],
        queryOptions: {},
    });
    const {data: productsResponseData} = useList<IProduct, HttpError>({
        resource: "products",
        pagination: {
            mode: "off",
        },
        filters: [],
        queryOptions: {},
    });

    const {data: customersWithUnpaidOrders} = useList<IUnpaidRecord, HttpError>({
        resource: "orders/v2/unpaid",
        pagination: {
            mode: "off",
        },
        filters: [],
        queryOptions: {},
    });

    const {data: countResult} = useList<any, HttpError>({
        resource: "orders/count",
        filters: filters,
        meta: {
            by: "status"
        }
    });

    //@ts-ignore
    const statusCount = countResult?.data?.count

    const {data: driversData, isLoading: driversIsLoading} = useGetDrivers({
        enabled: true,
    });

    const paymentModalFormProps = useModalForm<IPayment, HttpError, IPayment>({
        refineCoreProps: {
            action: "create",
            meta: {method: "post"},
            resource: "payments",
            onMutationSuccess: () => {
                paymentModal.close();
                refetch().then(() => {
                    setSelectedOrder(undefined);
                    paymentModalFormProps.reset();
                });
            },
        },
        modalProps: {
            autoResetForm: true,
        },
    });
    const {modal: paymentModal} = paymentModalFormProps;

    const orderItems = orderItemsResponseData?.data || [];
    const products = productsResponseData?.data || [];

    const driverFilterOptions = driversData?.map((item) => {
        return {
            value: item.id,
            label: item.name ? item.name : item.username,
        };
    });

    const createOrderModalFormProps = useModalForm<IOrder, HttpError, IOrder>({
        refineCoreProps: {
            action: "create",
            redirect: "edit",
            onMutationSuccess: ({data}) => {
                //
                //const editUrl = generateEditUrl("orders", data.id)
                //navigate(editUrl, {replace: true})
                resetCreate();
                closeCreateModal();
            },
        },
        modalProps: {
            autoResetForm: true,
        },
    });

    const {
        modal: {show: showCreateModal, close: closeCreateModal},
        reset: resetCreate,
    } = createOrderModalFormProps;

    //driverFilterOptions.push({value: undefined, label: '(not assigned)'})

    const columns = React.useMemo<GridColumns<IOrder>>(
        () => [
            {
                field: "id",
                headerName: "Id",
                filterOperators: filterOperators,
                renderCell(params) {
                    return <Link to={`/orders/show/${params.id}`}>{params.id}</Link>;
                },
            },
            {
                field: "created_at",
                headerName: "Created At",
                type: "date",
                renderCell: function render({value}) {
                    return <DateField value={value} format={"DD/MM/YYYY - HH:mm"}/>;
                },
            },
            {
                field: "confirmed_by",
                headerName: "Confirmed By",
                filterOperators: filterOperators,
            },
            {
                field: "canceled_at",
                headerName: "Canceled At",
                type: "date",
                renderCell: function render({value}) {
                    if (!value) return null;
                    return <DateField value={value} format={"DD/MM/YYYY - HH:mm"}/>;
                },
            },
            {
                field: "canceled_by",
                headerName: "Canceled By",
                filterOperators: filterOperators,
            },
            {
                field: "customer_name",
                headerName: "Customer",
                filterOperators: filterOperators,
                width: 200,
                renderCell: ({row, value}) => {
                    const unpaidAmount = customersWithUnpaidOrders?.data.find(r => r.customer_id === row.customer_id)?.total_payment_balance_for_customer||0
                    if (unpaidAmount) {
                        return <><Warning sx={{transform: 'translateY(-2px)'}} fontSize="small" color="error"/><span>{value}</span></>
                    }
                    return <span>{value}</span>
                }
            },
            {
                field: "basket",
                headerName: "Products NTW",
                //filterOperators: filterOperators,
                //filterable: false,
                width: 200,
                renderCell: function render({row, value}) {
                    /*const itemsForOrder = orderItems.filter(
                        (item) => item.order_id === row.id
                    );*/
                    const itemsForOrder: IOrderItem[] = []
                    try {
                        const parsedItems: IOrderItem[] = JSON.parse(value)
                        if (parsedItems) {
                            itemsForOrder.push(...parsedItems)
                        }
                    } catch (e) {
                        console.error('products error', e)
                    }

                    return <ArticleChip items={itemsForOrder} products={products}/>;
                },
            },
            {
                field: "delivery_date",
                headerName: "Delivery Date",
                width: 130,
                filterable: false,
                renderCell: function render({row}) {
                    if (!row.delivery_date || !row.delivery_time) return null;
                    return (
                        <div>
                            <div>
                                <DateField value={row.delivery_date} format={"DD/MM/YYYY"}/>
                            </div>
                            <div>{row?.delivery_time}</div>
                        </div>
                    );
                },
            },
            {
                field: "shipping_address",
                headerName: "Shipping Address",
                filterOperators: filterOperators,
                width: 200,
                valueFormatter: ({value}) => {
                    if (!value) return null;
                    try {
                        const address = JSON.parse(value);
                        return `${address.street?.replace(",", "")}, ${address.city} (${address.province})`;
                    } catch(e) {
                        return `INDIRIZZO ERRATO`
                    }
                },
                renderCell: function render({value}) {
                    if (!value) return null;
                    const address = JSON.parse(value);
                    return `${address.street}, ${address.province}, ${address.city}`;
                },
            },
            {
                field: "contact",
                headerName: "Contact",
                filterOperators: filterOperators,
                width: 150,
                renderCell: function render({row}) {
                    return (
                        <div>
                            <div>{row?.contact_name}</div>
                            <div>{row?.telephone}</div>
                        </div>
                    );
                },
            },
            {
                field: "delivery_instructions",
                headerName: "Delivery Instructions",
                filterOperators: filterOperators,
                width: 200,
            },
            {
                field: "internal_note",
                headerName: "Internal Note",
                filterOperators: filterOperators,
                width: 200,
            },
            {
                field: "segment",
                headerName: "Segment",
                type: "singleSelect",
                filterOperators: filterOperatorsSingleSelect,
                valueOptions: segmentOptions
            },
            {
                field: "total_amount",
                headerName: "Total Amount",
                filterOperators: filterOperators,
                renderCell: function render({value}) {
                    return (
                        <NumberField
                            value={value}
                            options={{
                                style: "currency",
                                currency: "EUR",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    );
                },
            },
            {
                field: "driver_id",
                headerName: "Driver",
                sortable: true,
                valueGetter: ({row}) => {
                    const value = row?.driver_id;
                    return value;
                },
                minWidth: 150,
                renderCell: function render({value, row}) {
                    if (driversIsLoading) return <>Loading...</>;
                    const user: any = driversData?.find((item) => item.id === value);
                    return <>{(user?.name ? user?.name : user?.username) || row.driver_name || ''}</>;
                },
                type: "singleSelect",
                filterOperators: filterOperatorsDriverRole,
                valueOptions: driverFilterOptions,
            },
            {
                field: "last_payment_status",
                headerName: "Last Payment Status",
                filterOperators: filterOperatorsSingleSelect,
                type: "singleSelect",
                valueOptions: paymentOptions,
                renderCell: ({value, row}) => row.status !== 'cancelled' ? value : ''
            },
            {
                field: "last_payment_amount",
                headerName: "Last Payment Amount",
                filterOperators: filterOperators,
                renderCell: ({value, row}) => row.status !== 'cancelled' ? value : ''
            },
            {
                field: "fatture_id",
                headerName: "ID fattura",
                filterOperators: [...filterOperators, isNullOrNotOperator("Fattura", "No", "Sì")],
                renderCell: function render({value, row}) {
                    if (!row?.order_confirmation_token) {
                        return <span>{value}</span>
                    }
                    const href = `https://compute.fattureincloud.it/doc/${row.order_confirmation_token}.pdf`
                    return <><a href={href} target="_blank">{value}</a></>;
                },
            },
            {
                field: "payment_balance",
                headerName: translate('orders.fields.payment_balance', "Payment Balance"),
                filterOperators: filterOperators,
                renderCell: ({value, row}) => row.status !== 'cancelled' ? value : ''
            },
            {
                field: "actions",
                headerName: "Actions",
                disableExport: true,
                renderCell: function render({row}) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id}/>
                            <EditButton hideText recordItemId={row.id}/>
                            {(row.status === "draft" || row.status === "cancelled") && (
                                <DeleteButton hideText recordItemId={row.id}/>
                            )}
                            {(row.status === "confirmed" || row.status === "delivered") &&
                                <Button
                                    size="small"
                                    startIcon={<Payment fontSize="small"/>}
                                    onClick={() => {
                                        paymentModalFormProps.reset();
                                        paymentModalFormProps.setValue('date', row.delivery_date)
                                        setSelectedOrder({...row});
                                        paymentModal.show(row.id);
                                    }}
                                >
                                    Add payment
                                </Button>
                            }
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 240,
                sortable: false,
                filterable: false,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orderItems, products, driversData, status, dateFilterInterval]
    );

    const syncProducts = () => {
        mutate(
            {
                resource: "admin/sync/timeslots",
                values: {
                    syncme: true,
                },
            },
            {
                onSuccess: () => {
                    refetch();
                },
            }
        );
    };

    const tabs = [
        {id: "draft", name: "draft"},
        {id: "pending", name: "pending"},
        {id: "confirmed", name: "confirmed"},
        {id: "delivered", name: "delivered"},
        {id: "cancelled", name: "cancelled"},
    ].map(tab => {
        if (statusCount && statusCount[tab.id]) {
            tab.name = `${tab.name} (${statusCount[tab.id]})`
        }
        return tab
    });

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            created_at: false,
            confirmed_by: false,
            canceled_at: false,
            canceled_by: false,
            segment: false,
        });

    const selectedItems = orderItems
        .filter((item) => selectedOrders.indexOf(item.order_id) !== -1)
        .map((item) => ({
            ...item,
            product: products.find((p) => p.id === item.product_id),
        }));

    return (
        <>
            <List
                contentProps={{sx: {
                    maxWidth: 'calc(100vw - 16px)',
                        px: {xs: 0, sm: 2},
                }}}
                headerProps={{
                    sx: {
                        flexDirection: 'column', maxWidth: {xs: '100vw', md: undefined},
                        gap: 1,
                        "& .MuiCardHeader-action": {
                            alignSelf: "center"
                        },
                        pb: 0,
                    }
                }}
                headerButtons={({defaultButtons}) => {
                    return (
                        <Box sx={{maxWidth: "calc(100vw - 48px)", flexDirection: {xs: "column", sm: "row"}}} gap={1}
                             display="flex" justifyContent="center"
                             alignItems="center" flexWrap="wrap">
                            <SearchField value={searchQuery} onChange={(value) => setSearchQuery(value)}/>
                            <Box sx={{maxHeight: {xs: 'auto', md: "40px"}, flexDirection: {xs: 'column', sm: 'row'}}}
                                 my={1} gap={1} display="flex" justifyContent="center"
                                 alignItems="center" flexWrap="wrap">
                                <OrderActions
                                    status={status}
                                    buttonsBefore={<>
                                        {defaultButtons}
                                    </>}
                                    disabled={!selectedItems?.length}
                                    selectedIds={selectedOrders}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => syncProducts()}
                                    startIcon={<Sync/>}
                                >
                                    Sync with shipox
                                </Button>
                            </Box>
                        </Box>
                    );
                }}
            >
                <DateFilter
                    value={dateFilterInterval}
                    onChange={handleDateFilterIntervalChanged}
                    showQuickSelection
                />
                <ResponsiveTabs
                    value={status}
                    indicatorColor="primary"
                    onChange={(_, value) => {
                        setStatus(value);
                        invalidate({
                            resource: "orders",
                            invalidates: ["list"]
                        })
                        refetch()
                        if (value === 'cancelled') {
                            setColumnVisibilityModel({
                                ...columnVisibilityModel,
                                last_payment_amount: false,
                                last_payment_status: false,
                                payment_balance: false
                            })
                        } else {
                            setColumnVisibilityModel({
                                ...columnVisibilityModel,
                                last_payment_amount: true,
                                last_payment_status: true,
                                payment_balance: true
                            })
                        }
                    }}
                >
                    {tabs.map((choice) => (
                        <Tab
                            key={choice.id}
                            label={
                                // //@ts-ignore
                                // totals[choice.id]
                                //   ? //@ts-ignore
                                //     `${choice.name} (${totals[choice.id]})`
                                //   :
                                choice.name
                            }
                            value={choice.id}
                        />
                    ))}
                </ResponsiveTabs>
                <Divider
                    ref={(el) => {
                        if (el && !tableOffset) {
                            const top = parseInt(el?.getBoundingClientRect().top.toFixed(0));
                            setTableOffset(top + 100);
                        }
                    }}
                />
                {tableOffset && (
                    <Box
                        sx={{
                            height: {
                                xs: 'auto',
                                sm: `calc(100vh - ${tableOffset + ((selectedItems.length && status !== 'cancelled') > 0 ? 120 : 0)}px)`
                            },
                            minHeight: '200px'
                        }}
                    >
                        <DataGridPro
                            {...dataGridProps}
                            pagination
                            autoHeight={isMobile}
                            rowsPerPageOptions={[25, 50, 100, 200, 500, 1000]}
                            initialState={{
                                pagination: {
                                    pageSize: isMobile ? 50 : 200,
                                },
                            }}
                            columnTypes={{driver_id: driverColumnType}}
                            columns={columns}
                            components={{Toolbar: GridToolbar}}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={setColumnVisibilityModel}
                            componentsProps={{
                                toolbar: {
                                    useDensity: false,
                                    printOptions: {disableToolbarButton: true},
                                    csvOptions: {
                                        disableToolbarButton: false,
                                        delimiter: ";"
                                    },
                                }
                            }}
                            filterMode="server"
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedOrders(newSelection as string[]);
                            }}
                            getRowClassName={({row}) => row.payment_balance && parseFloat(row.payment_balance) && row.status !== 'cancelled' ? 'background-red' : ''}
                            getRowHeight={() => "auto"}
                            // onRowClick={(params) => {
                            //
                            // }}
                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // Forced to use important since overriding inline styles
                                    maxHeight: "168px !important",
                                },
                            }}
                        />
                        {status !== 'cancelled' && <ProductCounterBar
                            products={products}
                            items={selectedItems}
                            fixed
                        />}
                    </Box>
                )}
            </List>
            {/* <EditProductModal {...editModalFormProps} /> */}
            {selectedOrder && <AddPaymentModal
                {...paymentModalFormProps}
                orderId={selectedOrder.id}
                defaultAmount={selectedOrder.last_payment_status ? parseFloat(selectedOrder.payment_balance || "0.0") : selectedOrder.total_amount}
            />}
            {!selectedItems.length ? <Box display="flex" justifyContent="flex-end" mr={4} mt={2}>
                <Button
                    variant="contained"
                    onClick={() => showCreateModal()}
                    startIcon={<AddBoxOutlined/>}
                >
                    Create
                </Button>
            </Box> : ''}
            <CreateOrderModal {...createOrderModalFormProps}/>
        </>
    );
};

