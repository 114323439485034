import React, {ReactNode, useState} from "react";
import {
    HttpError,
    useDeleteMany,
    useTranslate,
    useUpdateMany,
} from "@refinedev/core";
import {useForm} from "@refinedev/react-hook-form";
import {IOrder, IUser} from "../../../interfaces";
import {EditFieldManager} from "../../../components/edit/fields";
import {
    Box,
    Button, ButtonGroup, ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper,
    Typography,
} from "@mui/material";
import {
    ArrowDropDown,
    Check, Delete,
    DeleteForever,
    LocalShippingOutlined, NoCrash,
} from "@mui/icons-material";
import {ConfirmDialog} from "components/ConfirmDialog";

export type OrderStatus =
    | "draft"
    | "pending"
    | "confirmed"
    | "delivered"
    | "cancelled";
export type OrderActionsProps = {
    buttonsBefore?: ReactNode
    status: OrderStatus;
    selectedIds?: string[];
    disabled: boolean;
};
export type OrderActionsModalProps = {
    title: string;
    fields: (fieldManager: EditFieldManager<IOrder>) => JSX.Element;
    open: boolean;
    confirmText?: string | React.ReactNode
    //keyof IOrder
    onClose: () => void;
    onSubmit: (order: Partial<IOrder>) => void;
};

export type ModalState = {
    fields: (fieldManager: EditFieldManager<IOrder>) => JSX.Element;
    open: boolean;
    title: string;
    onSubmit: (order: Partial<IOrder>) => void;
    confirmText?: string | React.ReactNode;

};
export const OrderActionsModal: React.FC<OrderActionsModalProps> = ({
                                                                        title,
                                                                        fields,
                                                                        open,
                                                                        confirmText,
                                                                        onClose,
                                                                        onSubmit,
                                                                    }) => {
    const translate = useTranslate();

    const {
        register,
        //handleSubmit,
        formState: {errors},
        control,
        getValues,
        reset,
    } = useForm<IOrder, HttpError, IOrder>({});

    const fieldManager = new EditFieldManager(
        control,
        register,
        undefined,
        errors
    );

    const handleSubmit = () => {
        onSubmit(getValues());
        onClose();
        reset();
    };
    const handleClose = () => {
        onClose();
        reset();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{sx: {minWidth: 320}}}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    autoComplete="off"
                    sx={{display: "flex", flexDirection: "column"}}
                >
                    {fields(fieldManager)}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="error">
                    {translate("buttons.cancel", "Cancel")}
                </Button>
                <Button onClick={handleSubmit} color="success" variant="contained">
                    <Check/>
                    <Typography variant="button">
                        {confirmText || translate("orders.actions.confirm", "Confirm orders")}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export const OrderActions: React.FC<OrderActionsProps> = ({
                                                              status,
                                                              disabled,
                                                              selectedIds,
                                                              buttonsBefore
                                                          }) => {
    const translate = useTranslate();
    const [modalState, setModalState] = useState<ModalState>({
        open: false,
        title: "",
        fields: (fieldManager) => <></>,
        onSubmit: (order) => {
        },
    });
    const [showConfirm, setShowConfirm] = useState(false);

    const [showMenu, setShowMenu] = useState(false)
    const menuAnchorRef = React.useRef<HTMLDivElement>(null);

    const {mutate, isLoading} = useUpdateMany<IOrder>();
    const {mutate: deleteMany} = useDeleteMany<IOrder>();

    const selectDriver = (driverId: string) => {
        if (!driverId) {
            return;
        }
        mutate(
            {
                resource: "orders",
                meta: {method: "put"},
                values: {
                    status: status,
                    driver_id: driverId,
                },
                ids: selectedIds || [],
            },
            {
                onError: (error, variables, context) => {
                    //notify("custom.orders_confirmed_error", "warning")
                    // An error occurred!
                },
                onSuccess: (data, variables, context) => {
                    // Let's celebrate!
                },
            }
        );
    };
    const unassignDriver = () => {
        mutate(
            {
                resource: "orders",
                meta: {method: "put"},
                values: {
                    unassign_driver: true,
                },
                ids: selectedIds || [],
            },
            {
                onError: (error, variables, context) => {
                    //notify("custom.orders_confirmed_error", "warning")
                    // An error occurred!
                },
                onSuccess: (data, variables, context) => {
                    // Let's celebrate!
                },
            }
        );
    };
    const confirmOrders = (checkedShipox?: boolean, checkedFatture?: boolean) => {
        mutate(
            {
                resource: "orders",
                meta: {method: "put"},
                values: {
                    status: "confirmed",
                    update_shipox: checkedShipox || false,
                    update_fatture: checkedFatture || false,
                },
                ids: selectedIds || [],
            },
            {
                onError: (error, variables, context) => {
                    //notify("custom.orders_confirmed_error", "warning")
                    // An error occurred!
                },
                onSuccess: (data, variables, context) => {
                    // Let's celebrate!
                },
            }
        );
    };
    const deliverOrders = () => {
        mutate(
            {
                resource: "orders",
                meta: {method: "put"},
                values: {
                    status: "delivered",
                },
                ids: selectedIds || [],
            },
            {
                onError: (error, variables, context) => {
                    //notify("custom.orders_confirmed_error", "warning")
                    // An error occurred!
                },
                onSuccess: (data, variables, context) => {
                    // Let's celebrate!
                },
            }
        );
    };
    const deleteOrders = (confirm: boolean) => {
        setShowConfirm(false);
        if (!confirm) {
            return;
        }
        deleteMany(
            {
                resource: "orders",
                ids: selectedIds || [],
            },
            {
                onError: (error, variables, context) => {
                    //notify("custom.orders_confirmed_error", "warning")
                    // An error occurred!
                },
                onSuccess: (data, variables, context) => {
                    // Let's celebrate!
                },
            }
        );
    };

    const resetModalState = () => {
        setModalState({
            open: false,
            title: "",
            fields: (fieldManager) => <></>,
            onSubmit: (order) => {
            },
        });
    };

    const orderConfirmButton = (
        <Button
            variant="contained"
            color="success"
            disabled={disabled || isLoading}
            onClick={() => {
                setModalState({
                    open: true,
                    title: translate("orders.actions.confirm", "Confirm orders"),
                    fields: (fieldManager) => {
                        return (
                            <>
                                {fieldManager.switchField(
                                    "update_shipox",
                                    translate("orders.fields.update_shipox", "Update Shipox")
                                )}
                                {fieldManager.switchField(
                                    "update_fatture",
                                    translate("orders.fields.update_fatture", "Update fatture"),
                                    true
                                )}
                            </>
                        );
                    },
                    onSubmit: (order) => {
                        confirmOrders(order.update_shipox, order.update_fatture);
                    },
                });
            }}
            startIcon={<Check/>}
        >
            {translate("orders.actions.confirm", "Confirm orders")}
        </Button>
    );

    const orderDeleteButton = (
        <Button
            variant="contained"
            color="error"
            disabled={disabled || isLoading}
            onClick={() => {
                setShowConfirm(true);
            }}
            startIcon={<DeleteForever/>}
        >
            {translate("orders.actions.delete", "Delete orders")}
        </Button>
    );

    const orderAssignButton = (
        <>
            <ButtonGroup variant="contained" ref={menuAnchorRef}>
                <Button
                    color="success"
                    variant="contained"
                    disabled={disabled || isLoading}
                    onClick={() => {
                        setModalState({
                            open: true,
                            title: translate("orders.actions.assign_driver", "Assign driver"),
                            fields: (fieldManager) => {

                                return (
                                    <>
                                        {fieldManager.autocompleteField<IUser>(
                                            "driver_id",
                                            translate("orders.fields.driver", "Driver"),
                                            "users",
                                            "id",
                                            "name",
                                            [
                                                {
                                                    field: "driver_role",
                                                    operator: "startswith",
                                                    value: "Driver",
                                                },
                                                {
                                                    field: "name",
                                                    // @ts-ignore
                                                    operator: "isNullOrNot",
                                                    value: "notNull",
                                                },
                                            ]
                                        )}
                                    </>
                                );
                            },
                            onSubmit: (order) => {
                                if (order.driver_id) {
                                    selectDriver(order.driver_id);
                                } else {
                                    unassignDriver()
                                }
                            },
                        });
                    }}
                    startIcon={<LocalShippingOutlined/>}
                >
                    {translate("orders.actions.assign", "Assign to driver")}
                </Button>
                <Button size="small" disabled={disabled || isLoading} color="success" onClick={() => {
                    setShowMenu((prevOpen) => !prevOpen)
                }}>
                    <ArrowDropDown/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={showMenu}
                anchorEl={menuAnchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper sx={{zIndex: 1000}}>
                            <ClickAwayListener onClickAway={() => {
                            }}>
                                <MenuList id="split-button-menu" sx={{minWidth: '200px', zIndex: 1000}} autoFocusItem>
                                    <MenuItem sx={{zIndex: 1000}} disabled={disabled || isLoading} onClick={() => {
                                        setShowMenu(false);
                                        unassignDriver()
                                    }}>
                                        <ListItemIcon>
                                            <Delete/>
                                        </ListItemIcon>
                                        <ListItemText color="error">Unassign driver</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );


    const orderDeliverButton = (
        <Button
            color="success"
            variant="contained"
            disabled={disabled || isLoading}
            onClick={() => {
                setModalState({
                    open: true,
                    title: translate("orders.actions.confirm_delivery", "Confirm delivery?"),
                    confirmText: translate("orders.actions.confirm", "Confirm"),
                    fields: (fieldManager) => {
                        return (
                            <>
                            </>
                        );
                    },
                    onSubmit: (order) => {
                        return deliverOrders()
                    },
                });
            }}
            startIcon={<NoCrash/>}
        >
            {translate("orders.actions.deliver", "Deliver")}
        </Button>
    );

    switch (status) {
        case "draft":
            return (
                <>
                    {buttonsBefore}
                    {orderDeleteButton}
                    {orderConfirmButton}
                    <OrderActionsModal
                        title={modalState.title}
                        fields={modalState.fields}
                        open={modalState.open}
                        onSubmit={modalState.onSubmit}
                        onClose={resetModalState}
                    />
                    <ConfirmDialog open={showConfirm} onClose={deleteOrders}/>
                </>
            );
        case "pending":
            return (
                <>
                    {buttonsBefore}
                    {orderConfirmButton}
                    {orderAssignButton}
                    <OrderActionsModal
                        title={modalState.title}
                        fields={modalState.fields}
                        open={modalState.open}
                        onSubmit={modalState.onSubmit}
                        onClose={resetModalState}
                    />
                </>
            );
        case "confirmed":
            return (
                <>
                    {buttonsBefore}
                    {orderAssignButton}
                    {orderDeliverButton}
                    <OrderActionsModal
                        title={modalState.title}
                        fields={modalState.fields}
                        open={modalState.open}
                        confirmText={modalState.confirmText}
                        onSubmit={modalState.onSubmit}
                        onClose={resetModalState}
                    />
                </>
            );
        case "cancelled":
            return (
                <>
                    {buttonsBefore}
                    {orderDeleteButton}
                    <ConfirmDialog open={showConfirm} onClose={deleteOrders}/>
                </>
            );
    }
    return <>{buttonsBefore}</>;
};
