import { HttpError } from "@refinedev/core";
import { SaveButton } from "@refinedev/mui";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { UseModalFormReturnType } from "@refinedev/react-hook-form";

import { IContact } from "interfaces";

export const EditContactModal: React.FC<
  UseModalFormReturnType<IContact, HttpError, IContact>
> = ({
  saveButtonProps,
  refineCore: { queryResult, onFinish },
  modal: { visible, close, title },
  register,
  control,
  handleSubmit,
  formState: { errors },
}) => {
  const onSubmit = (data: any) => {
    const currentData = queryResult?.data?.data;

    onFinish({
      ...currentData,
      ...data,
    });
  };
  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            margin="normal"
            fullWidth
            label="Email"
            autoFocus
            defaultValue={" "}
          />
          <TextField
            {...register("telephone")}
            error={!!errors.telephone}
            helperText={errors.telephone?.message}
            margin="normal"
            fullWidth
            label="Telephone"
            autoFocus
            defaultValue={" "}
          />
          <TextField
            {...register("electronic_post")}
            error={!!errors.electronic_post}
            helperText={errors.electronic_post?.message}
            margin="normal"
            fullWidth
            label="Electronic Post"
            autoFocus
            defaultValue={" "}
          />
          <TextField
            {...register("fax")}
            error={!!errors.fax}
            helperText={errors.fax?.message}
            margin="normal"
            fullWidth
            label="Fax"
            autoFocus
            defaultValue={" "}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <SaveButton {...saveButtonProps} onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};
